import { Text } from '@bami-libs/uikit'
import TranslatedText from 'components/TranslatedText'
import React from 'react'
import styled from 'styled-components'
import Circle from '../../assets/images/blue-loader.svg'
import { AutoColumn } from '../Column'
import { Spinner } from '../Shared'
import { ConfirmedIcon, ContentHeader, Section, Wrapper } from './helpers'

type ConfirmationPendingContentProps = { onDismiss: () => void; pendingText: string }

const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

const ConfirmationPendingContent = ({ onDismiss, pendingText }: ConfirmationPendingContentProps) => {
  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>
          <TranslatedText translationId={684}>Waiting for confirmation</TranslatedText>
        </ContentHeader>
        <ConfirmedIcon color="#4e598c">
          <CustomLightSpinner src={Circle} color="#4e598c" alt="loader" size="90px" />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify="center">
          <AutoColumn gap="12px" justify="center">
            <Text fontSize="14px" color="#4e598c">
              <strong>{pendingText}</strong>
            </Text>
          </AutoColumn>
          <Text fontSize="14px" color="#4e598c">
            <TranslatedText translationId={683}>Confirm this transaction in your wallet</TranslatedText>
          </Text>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export default ConfirmationPendingContent
