import React from 'react'
import styled from 'styled-components'
import { Doughnut } from 'react-chartjs-2'
import TranslatedText from 'components/TranslatedText'
import { formatNumber } from 'utils/format'
import CurrencyLogo from 'components/CurrencyLogo'

import binanceLogo from '../../assets/images/binance-logo.png'

const LIST_CHART_COLORS = [
  'rgba(255, 159, 64, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(255, 99, 132, 1)',
  'rgba(102, 102, 153)',
  'rgba(255, 128, 128)',
  'rgba(128, 128, 128)',
  'rgba(144, 130, 90)',
]

export const SingleDetail = styled.button`
  width: 100%;
  padding: 16px;
  border: 1px solid #003a5e;
  outline: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  background: ${(props) => (props.theme.isDark ? 'rgba(255,255,255,.5)' : 'rgba(255,255,255,1)')};
  flex: 0 0 48%;
  max-width: 48%;
  cursor: pointer;

  @media (max-width: 1024px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 !important;
    margin-bottom: 20px !important;
  }

  &:nth-child(3n + 2) {
    margin: 0 1.5% 20px 1.5%;
  }

  img {
    // width: 30px;
    height: 40px;
    width: auto;
    margin-right: 10px;
  }

  h5 {
    font-size: 1.4em;
    font-weight: 400;
    color: #4e598c;

    @media (max-width: 600px) {
      font-size: 1.2em;
    }
  }

  .price {
    margin-left: auto;
    font-size: 1.5em;
  }
`

export const SingleDetailFull = styled(SingleDetail)`
  flex: 0 0 98%;
  max-width: 98%;
  flex-direction: column;
`

export const Wrapper = styled.div`
  display: flex;
  margin-top: 1rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 500px) {
    .content-wrapper {
      flex-direction: column;
    }
  }
`

const GraphSection = ({ wallet, liquidity, balances, converter }) => {
  const options = {
    responsive: true,
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.label || ''

            if (label) {
              label += ': '
            }

            if (context.parsed !== null) {
              label += ` $${context.parsed}`
            }
            return label
          },
        },
      },
    },
  }
  const assetOptions = {
    responsive: true,
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { dataIndex, dataset } = context
            const currentData = dataset.item[dataIndex]
            return [
              currentData.currency.symbol,
              `Quantity: ${formatNumber(currentData.balance, 4)}`,
              `Price: $${currentData.showPrice}`,
              `Value: $${formatNumber(currentData.value, 4)}`,
            ]
          },
        },
      },
    },
  }
  const platformAllocationChartData = {
    labels: ['Wallet', 'Liquidity Pool'],
    datasets: [
      {
        data: [formatNumber(wallet), formatNumber(liquidity)],
        backgroundColor: LIST_CHART_COLORS,
      },
    ],
  }
  let total = 0
  const balancesData =
    balances &&
    balances.length &&
    balances
      .filter((v: any) => {
        return parseFloat(v?.toExact())
      })
      .map((v) => {
        const exact: any = v && v.toExact() ? parseFloat(v.toExact()) : 0
        const symbol = v?.currency.symbol ? v?.currency.symbol : 'USD'

        const price = formatNumber(parseFloat(converter[symbol] || 0))
        const isVNDC = symbol === 'VNDC'
        const value: any =
          parseFloat(exact) * (Number.isNaN(parseFloat(converter[symbol])) ? 0 : parseFloat(converter[symbol]))
        total += value
        return {
          currency: v.currency,
          balance: parseFloat(exact),
          price,
          showPrice: `${isVNDC ? `1 / ${formatNumber(1.0 / parseFloat(converter[symbol]))}` : price}`,
          value: parseFloat(value),
        }
      })
      .filter((o) => o.value > total * 0.01 || o.value > 1)
  const assetAllocationChartData = {
    labels: balancesData?.map((o) => o.currency.symbol),
    datasets: [
      {
        data: balancesData?.map((o) => o.value),
        item: balancesData,
        backgroundColor: LIST_CHART_COLORS,
      },
    ],
  }

  return (
    <Wrapper>
      <SingleDetail style={{ flexDirection: 'column' }}>
        <h5 className="account_header">
          <TranslatedText translationId={664}>Asset Allocation</TranslatedText>
        </h5>
        <div style={{ display: 'flex', width: '100%' }} className="content-wrapper">
          <div style={{ flex: 1 }}>
            <Doughnut redraw={false} type="pie" data={platformAllocationChartData} options={options} />
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, paddingLeft: '1rem' }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ backgroundColor: LIST_CHART_COLORS[0], width: 24, height: 24, marginRight: '0.5rem' }} />
                <div style={{ color: LIST_CHART_COLORS[0] }}>Wallet</div>
              </div>
              <div style={{ color: LIST_CHART_COLORS[0] }}>{((wallet / (wallet + liquidity)) * 100).toFixed(2)}%</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ backgroundColor: LIST_CHART_COLORS[1], width: 24, height: 24, marginRight: '0.5rem' }} />
                <div style={{ color: LIST_CHART_COLORS[1] }}>Liquidity Pools</div>
              </div>
              <div style={{ color: LIST_CHART_COLORS[1] }}>
                {((liquidity / (wallet + liquidity)) * 100).toFixed(2)}%
              </div>
            </div>
          </div>
        </div>
      </SingleDetail>
      <SingleDetail style={{ flexDirection: 'column' }}>
        <h5 className="account_header">
          <TranslatedText translationId={674}>Asset Allocation By Token</TranslatedText>
        </h5>
        <div style={{ display: 'flex', width: '100%' }} className="content-wrapper">
          <div style={{ flex: 1 }}>
            <Doughnut redraw={false} type="pie" data={assetAllocationChartData} options={assetOptions} />
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, paddingLeft: '1rem' }}
          >
            {balancesData?.map((item, index) => {
              return (
                <div
                  key={item.currency?.symbol}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '0.5rem',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {item.currency ? (
                      <CurrencyLogo currency={item.currency} size="20px" style={{ marginRight: '8px' }} />
                    ) : (
                      <img src={binanceLogo} alt="" />
                    )}
                    <div style={{ color: LIST_CHART_COLORS[index] }}>{item.currency.symbol}</div>
                  </div>
                  <div style={{ color: LIST_CHART_COLORS[index] }}>{((item.value / total) * 100).toFixed(2)}%</div>
                </div>
              )
            })}
          </div>
        </div>
      </SingleDetail>
    </Wrapper>
  )
}

export default GraphSection
