import { Pair } from '@pancakeswap-libs/sdk'
import CurrencyLogo from 'components/CurrencyLogo'
import { usePairs } from 'data/Reserves'
import { useActiveWeb3React } from 'hooks'
import React, { useMemo } from 'react'
import { useDerivedSwapInfo } from 'state/swap/hooks'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { useCurrencyBalance, useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import styled from 'styled-components'
import { formatNumber } from 'utils/format'
import binanceLogo from '../../assets/images/binance-logo.png'
import closeIcon from '../../assets/images/closeIcon.svg'
import LiquidityPoolsDetail from './LiquidityPoolsDetail'

const DetailsPopupComp = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  @media (max-width: 600px) {
    margin-top: -64px;
    height: 100vh;
  }

  .details-modal {
    border-radius: 4px;
    padding: 32px;
    height: auto;
    position: relative;
    box-sizing: border-box;
    background: #fff;

    @media (max-width: 900px) {
      width: 95%;
    }

    @media (max-width: 600px) {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 32px 16px;
    }

    h4.detail-title {
      margin: 0 0 4px;
      font-weight: 400;
      font-size: 1.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        img {
          width: 20px;
        }
      }
    }

    .detail-body {
      width: 750px;

      @media (max-width: 900px) {
        width: auto;
      }
      h5 {
        font-size: 1.25em;
        font-weight: 500;
        color: #4e598c;
        margin-top: 24px;
      }

      .detail-table {
        .details-head {
          padding: 16px 0;
        }

        .details-row {
          padding: 12px 0;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .details-row,
        .details-head {
          display: flex;
          align-items: center;

          h5.row-data {
            box-sizing: border-box;
            flex: 60 0 auto;
            min-width: 60px;
            width: 60px;
            cursor: pointer;
            margin: 0;
            // border: 1px solid #aaa;
            font-size: 1.2em;
            font-weight: 500;
            color: #002237;
            display: flex;
            align-items: center;

            @media (max-width: 900px) {
              flex: 35 0 auto;
              min-width: 35px;
              width: 35px;
            }

            @media (max-width: 600px) {
              flex: 35 0 auto;
              min-width: 35px;
              width: 35px;
            }

            img {
              width: 30px;
              margin-right: 10px;
            }

            & > div {
              margin-right: 10px;
              img {
                margin: 0;
                position: absolute;
                transform: translateY(-50%);

                &:first-child {
                  left: 0;
                  z-index: 20;
                }

                &:last-child {
                  right: 0;
                }
              }
            }

            &:nth-child(1) {
              @media (max-width: 900px) {
                font-size: 16px;
              }

              @media (max-width: 600px) {
                font-size: 12px;
              }
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              flex: 30 0 auto;
              min-width: 30px;
              width: 30px;

              @media (max-width: 900px) {
                font-size: 16px;
              }

              @media (max-width: 600px) {
                font-size: 12px;
              }
            }

            &:nth-child(4) {
              text-align: right;
              justify-content: flex-end;
            }

            &:nth-child(3) {
              @media (max-width: 600px) {
                display: none;
              }
            }
          }
        }

        .details-row {
          h5.row-data {
            font-weight: 400;
            color: #4e598c;
            display: flex;
            align-items: center;

            div.double-img_container {
              position: relative;

              img {
                width: 30px;
                height: auto;
                position: absolute;
                top: 0;

                &:first-child {
                  left: 0;
                }
                &:last-child {
                  right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`

function DetailsPopup(props) {
  const { balances, converter, detailsPop } = props
  const { account } = useActiveWeb3React()
  const trackedTokenPairs = useTrackedTokenPairs()
  const { currencies } = useDerivedSwapInfo()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currencies.INPUT ?? undefined)

  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens,
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )
  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))
  return (
    <DetailsPopupComp>
      <div className="details-modal">
        <h4 className="detail-title">
          {detailsPop}
          <button
            type="button"
            onClick={() => {
              props.setDetailsPop('')
            }}
          >
            <img src={closeIcon} alt="" />
          </button>
        </h4>
        <div className="detail-body">
          <h5>BSC</h5>
          <div className="detail-table">
            <div className="details-head">
              <h5 className="row-data">Assets</h5>
              {detailsPop !== 'Liquidity Pools' && <h5 className="row-data">Balance</h5>}
              {detailsPop === 'Wallet' ? <h5 className="row-data">Price</h5> : null}
              <h5 className="row-data">Value</h5>
            </div>
            {detailsPop === 'Wallet' &&
              balances &&
              balances.length &&
              balances
                .filter((v: any) => parseFloat(v?.toExact()))
                .map((v) => {
                  const exact: any = v && v.toExact() ? parseFloat(v.toExact()) : 0
                  const symbol = v?.currency.symbol ? v?.currency.symbol : 'USD'

                  const price = formatNumber(parseFloat(converter[symbol] || 0))
                  const isVNDC = symbol === 'VNDC'
                  const value: any =
                    parseFloat(exact) *
                    (Number.isNaN(parseFloat(converter[symbol])) ? 0 : parseFloat(converter[symbol]))

                  return (
                    <div className="details-row">
                      <h5 className="row-data">
                        {v && v.currency ? (
                          <CurrencyLogo currency={v.currency} size="30px" style={{ marginRight: '8px' }} />
                        ) : (
                          <img src={binanceLogo} alt="" />
                        )}
                        {v && v.currency.symbol}
                      </h5>
                      <h5 className="row-data">{formatNumber(parseFloat(exact), 4)}</h5>
                      <h5 className="row-data">
                        ${isVNDC ? `1 / ${formatNumber(1.0 / parseFloat(converter[symbol]))}` : price}
                      </h5>
                      <h5 className="row-data">${formatNumber(parseFloat(value))}</h5>
                    </div>
                  )
                })}
            {detailsPop === 'Liquidity Pools'
              ? allV2PairsWithLiquidity.map((v2Pair) => {
                  return <LiquidityPoolsDetail pair={v2Pair} converter={converter} />
                })
              : null}
          </div>
        </div>
      </div>
    </DetailsPopupComp>
  )
}

export default DetailsPopup
