import { Button } from '@bami-libs/uikit'
import { Token } from '@pancakeswap-libs/sdk'
import CurrencyLogo from 'components/CurrencyLogo'
import BigNumber2 from 'bignumber.js'
import { filterTokens } from 'components/SearchModal/filtering'
import { useTokenComparator } from 'components/SearchModal/sorting'
import { useToken } from 'hooks/Tokens'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAllCashCTokenBalances, useAllLendingTokenBorrowApy } from 'state/wallet/hooks'
import { isAddress } from 'utils'
import { formatNumber, sortTokens } from 'utils/format'
import useI18n from 'hooks/useI18n'
import BorrowModal from './BorrowModal'
import { Animated } from './styled'

const BorrowSection = ({ allTokens, balances, borrowBalances, liquidity, lendingPrices }) => {
  const [borrowApys] = useAllLendingTokenBorrowApy()
  const [cashCTokens] = useAllCashCTokenBalances()
  const TranslateString = useI18n()

  const [data, setData] = useState<any>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const isAddressSearch = isAddress(searchQuery)
  const searchToken = useToken(searchQuery)
  const tokenComparator = useTokenComparator(false)
  const [showMore, setShowMore] = useState(true)
  const [selectedToken, setSelectedToken] = useState<Token | null>(null)

  const totalBorrowBalances = data?.reduce((res, cur) => {
    return (
      res +
      (lendingPrices?.[cur?.address || 'BNB'] || 0) *
        parseFloat(borrowBalances[cur?.address || 'BNB']?.toSignificant() || '0')
    )
  }, 0)

  const filteredTokens: any = useMemo(() => {
    if (isAddressSearch) return searchToken ? [searchToken] : []
    return filterTokens(Object.values(allTokens), searchQuery)
  }, [isAddressSearch, searchToken, allTokens, searchQuery])

  const filteredSortedTokens: any[] = useMemo(() => {
    if (searchToken) return [searchToken]
    const sorted = filteredTokens.sort(tokenComparator)
    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0)
    if (symbolMatch.length > 1) return sorted

    return [
      ...(searchToken ? [searchToken] : []),
      // sort any exact symbol matches first
      ...sorted.filter((token) => token.symbol?.toLowerCase() === symbolMatch[0]),
      ...sorted.filter((token) => token.symbol?.toLowerCase() !== symbolMatch[0]),
    ]
  }, [filteredTokens, searchQuery, searchToken, tokenComparator])

  const borrowingData = data
    .filter((o) => {
      return !!borrowBalances?.[o?.address || 'BNB']?.greaterThan(BigInt(0))
    })
    .map((o) => {
      const tmp = borrowBalances?.[o?.address || 'BNB']
      const borrowBalance = new BigNumber2(tmp?.toFixed(tmp?.currency?.decimals) || 0)
      const price = lendingPrices?.[o?.address || 'BNB'] || 0
      const borrowLimit = borrowBalance
        .times(price)
        .div(totalBorrowBalances + liquidity)
        .times(100)

      return {
        token: o,
        apy: +(borrowApys?.[o.address] || 0),
        borrowBalance,
        borrowLimit,
      }
    })

  const handleInput = useCallback((event) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    if (input) {
      setShowMore(true)
    }
  }, [])

  useEffect(() => {
    setData(sortTokens(filteredSortedTokens))
  }, [filteredSortedTokens])

  return (
    <div className="section borrow">
      {!!selectedToken && (
        <BorrowModal
          walletBalance={balances?.[selectedToken?.address || 'BNB'] || 0}
          price={lendingPrices?.[selectedToken?.address || 'BNB'] || 0}
          totalBorrowBalances={totalBorrowBalances}
          borrowLimit={liquidity + totalBorrowBalances}
          availableLiquidity={
            cashCTokens?.[selectedToken?.address || 'BNB']
              ? // @ts-ignore
                parseFloat(cashCTokens[selectedToken?.address || 'BNB'].toSignificant())
              : 0
          }
          borrowBalance={parseFloat(
            borrowBalances[selectedToken?.address || 'BNB']?.toFixed(selectedToken?.decimals) || '0'
          )}
          // @ts-ignore
          apy={+(borrowApys?.[selectedToken?.address] || 0)}
          isOpen={!!selectedToken}
          token={selectedToken}
          onDismiss={() => setSelectedToken(null)}
        />
      )}
      <div className="section-content">
        <div className="yours-table">
          <div className="row thead">
            <div className="cell">{TranslateString(90067, 'Borrowed Asset')}</div>
            <div className="cell">{TranslateString(90068, 'APY / Accrued')}</div>
            <div className="cell">{TranslateString(90069, 'Balance')}</div>
            <div className="cell">{TranslateString(90070, 'Borrow limit')}</div>
          </div>
          {borrowingData.map((item, index) => {
            const { token } = item

            return (
              <Animated index={index} key={`c${token.symbol}`} className="row" onClick={() => setSelectedToken(token)}>
                <div className="cell">
                  <CurrencyLogo currency={item.token} size="1.5rem" />
                  <span style={{ marginLeft: '0.75rem' }}>{token.symbol}</span>
                </div>
                <div className="cell">{formatNumber(item.apy, 4)}%</div>
                <div className="cell">
                  {formatNumber(item.borrowBalance, 8)} {item.symbol}
                </div>
                <div className="cell">{formatNumber(item.borrowLimit)}%</div>
              </Animated>
            )
          })}
          {!borrowingData?.length && (
            <div className="row tfoot">
              <div className="cell text-center light">{TranslateString(90072, 'You have no borrowed assets')}</div>
            </div>
          )}
        </div>
      </div>

      <div className="info">
        <div className="title highlight">Borrow assets</div>
        <div className="description">Everything you need to know about borrowing</div>
      </div>

      <div className="section-content">
        <div className="input-wrapper">
          <input
            type="text"
            value={searchQuery}
            onChange={handleInput}
            placeholder={TranslateString(90071, 'Search Borrow Assets')}
          />
        </div>
        <div className="yours-table">
          <div className="row thead">
            <div className="cell">{TranslateString(90073, 'Asset')}</div>
            <div className="cell">{TranslateString(90074, 'Borrow APY')}</div>
            <div className="cell">{TranslateString(90075, 'Liquidity')}</div>
          </div>
          {data
            ?.filter((_, i) => {
              if (!showMore) {
                return i < 5
              }
              return true
            })
            ?.map((item, index) => {
              let balance = 0
              if (cashCTokens?.[item?.address || 'BNB']) {
                balance =
                  // @ts-ignore
                  parseFloat(cashCTokens[item?.address || 'BNB'].toSignificant())
              }

              return (
                <Animated index={index} key={item.symbol} className="row" onClick={() => setSelectedToken(item)}>
                  <div className="cell">
                    <CurrencyLogo currency={item} size="1.5rem" />
                    <span style={{ marginLeft: '0.75rem' }}>{item.symbol}</span>
                  </div>
                  <div className="cell">{formatNumber(+(borrowApys[item.address] || 0), 4)}%</div>
                  <div className="cell">
                    {formatNumber(balance || 0, 4)} {item.symbol}
                  </div>
                </Animated>
              )
            })}
          {!showMore && (
            <div className="row tfoot">
              <div className="cell light text-center">
                <Button variant="text" onClick={() => setShowMore(true)}>
                  {TranslateString(90076, 'Show More')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BorrowSection
