import React from "react"
import styled from "styled-components"

export default styled.div`
width: 100%;
height: calc(100vh - 64px);
position: absolute;
top: 0;
left: 0;
background: ${(props: any)=>props.theme.isDark?"rgba(36, 59, 85,.5)":"rgba(0, 0, 0, 0.5)"};
z-index: 12;
display: flex;
align-items: center;

div.tokens_container {
  width: 350px;
  margin-left: auto;
  margin-right: 200px;
  background: ${(props: any)=>props.theme.isDark?"#141E30":"#fff"};
  border-radius: 10px;
  padding: 20px 10px;

  @media(min-width: 2000px){
    margin-right: 25%;
  }

  @media(max-width: 750px){
    margin: 0 auto;
  }

  @media(max-width: 450px){
    width: 90%;
  }
  

  h5.card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4em;
    font-weight: 500;
    color: #4e598c;
    margin-bottom: 20px;

    @media(max-width: 360px){
      font-size: 1.2em;
    }

    button {
      border: none;
      outline: none;
      background: none;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  div.search-input{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #4E598C;

      img{
          flex: 0 0 6%;
          max-width: 6%;
      }

      input{
          flex: 0 0 92%;
          max-width: 92%;
          border: none;
          outline: none;
          background: none;
          font-size: 1.2em;

          &::placeholder{
            color: rgba(78, 89, 140,.5);
          }
      }
  }

  div.tokens{
      height: 450px;
      overflow-y: scroll;
      padding-top: 20px;
      padding-left: 10px;

      @media(max-width: 360px){
        height: 320px;
      }
  }

  button.token{
      border: none;
      outline: none;
      background: none;
      display: block;
      font-size: 1.4em;
      font-weight: 200;
      color: #4E598C;
      margin-bottom: 60px;

      @media(max-width: 360px){
        font-size: 1.2em;
        margin-bottom: 20px;
      }
  }
}
`