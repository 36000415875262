/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { fetchPrices } from './actions'

export interface PricesState {
  data: {
    [key in string]: number
  }
}

const initialState: PricesState = { data: {} }

export const pricesSlice = createSlice({
  name: 'Prices',
  initialState,
  reducers: {
    setPrices: (state, action) => {
      state.data = {
        ...action.payload,
      }
    },
  },
})

// Actions
export const { setPrices } = pricesSlice.actions

// Thunks
export const fetchPricesData = (contract) => async (dispatch) => {
  const prices = await fetchPrices(contract)

  dispatch(setPrices(prices))
}

export default pricesSlice.reducer
