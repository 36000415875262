import { CheckmarkCircleIcon, ErrorIcon, Flex, LinkExternal, Modal, Text } from '@bami-libs/uikit'
import GradientButton from 'components/Button/button'
import Loader from 'components/Loader'
import { useActiveWeb3React } from 'hooks'
import React, { useEffect, useMemo } from 'react'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import styled from 'styled-components'
import { getEtherscanLink } from 'utils'

const LinkContainer = styled.div`
svg{
  fill: #ff8c42!important;
  path{
    fill: #ff8c42!important;
  }
}
`

type RecentTransactionsModalProps = {
  onDismiss?: () => void
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const newTransactionsFirst = (a: TransactionDetails, b: TransactionDetails) => b.addedTime - a.addedTime

const getRowStatus = (sortedRecentTransaction: TransactionDetails) => {
  const { hash, receipt } = sortedRecentTransaction

  if (!hash) {
    return { icon: <Loader />, color: 'text' }
  }

  if (hash && receipt?.status === 1) {
    return { icon: <CheckmarkCircleIcon color="success" />, color: 'success' }
  }

  return { icon: <ErrorIcon color="failure" />, color: 'failure' }
}

const RecentTransactionsModal = ({ onDismiss = defaultOnDismiss }: RecentTransactionsModalProps) => {
  const { account, chainId } = useActiveWeb3React()
  const allTransactions = useAllTransactions()

  // Logic taken from Web3Status/index.tsx line 175
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])
  const hide = true

  useEffect(() => {
    (document.querySelector('body #root h2') as HTMLElement).style.color = '#4e598c'

  }, [])
  return (
    <Modal title="Recent Transactions" onDismiss={onDismiss} hideCloseButton={hide}>
      {!account && (
        <Flex justifyContent="center" flexDirection="column" alignItems="center">
          <Text color="#4e598c" mb="8px" bold>
            Please connect your wallet to view your recent transactions
          </Text>
          <GradientButton name="Close" onClick={onDismiss} />
          {/* <Button variant="tertiary" size="sm" onClick={onDismiss}>
            Close
          </Button> */}
        </Flex>
      )}
      {account && chainId && sortedRecentTransactions.length === 0 && (
        <Flex justifyContent="center" flexDirection="column" alignItems="center">
          <Text color="#4e598c" mb="8px" bold>
            No recent transactions
          </Text>
          <GradientButton onClick={onDismiss}
            name="Close"
          />
          {/* </Button> */}
        </Flex>
      )}
      {account &&
        chainId &&
        sortedRecentTransactions.map((sortedRecentTransaction) => {
          const { hash, summary } = sortedRecentTransaction
          const { icon, color } = getRowStatus(sortedRecentTransaction)

          return (
            <LinkContainer>
              <Flex key={hash} alignItems="center" justifyContent="space-between" mb="4px">
                <LinkExternal href={getEtherscanLink(chainId, hash, 'transaction')} color="#4e598c">
                  {summary ?? hash}
                </LinkExternal>
                {icon}
              </Flex>
            </LinkContainer>
          )
        })}
    </Modal>
  )
}

export default RecentTransactionsModal
