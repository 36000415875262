/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Toggle } from '@bami-libs/uikit'
import CurrencyLogo from 'components/CurrencyLogo'
import { filterTokens } from 'components/SearchModal/filtering'
import { useLendingTokenComparator } from 'components/SearchModal/sorting'
import BigNumber2 from 'bignumber.js'

import { useActiveWeb3React } from 'hooks'
import useI18n from 'hooks/useI18n'
import useToast from 'hooks/useToast'
import { useToken } from 'hooks/Tokens'
import { useLendingControllerContract } from 'hooks/useContract'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSingleCallResult } from 'state/multicall/hooks'
import { isAddress } from 'utils'
import { formatNumber, sortTokens } from 'utils/format'
import { Animated, LoadingScreen } from './styled'
import SupplyModal from './SupplyModal'

export const USDT_DECIMALS = 8

const SupplySection = ({
  allTokens,
  balances,
  supplyBalances,
  lendingPrices,
  supplyApys,
  borrowBalances,
  liquidity,
}) => {
  const TranslateString = useI18n()
  const { account } = useActiveWeb3React()
  const { toastError } = useToast()
  const lendingControllerContract = useLendingControllerContract()
  const enteredMarkets =
    useSingleCallResult(lendingControllerContract, 'getAssetsIn', [account || undefined])?.result?.[0] || []
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const isAddressSearch = isAddress(searchQuery)
  const searchToken = useToken(searchQuery)
  const tokenComparator = useLendingTokenComparator(false, balances)
  const [showMore, setShowMore] = useState(true)
  const [selectedToken, setSelectedToken] = useState(null)
  const totalBorrowBalances = data?.reduce((res, cur) => {
    return (
      res +
      (lendingPrices?.[cur?.address || 'BNB'] || 0) *
        parseFloat(borrowBalances[cur?.address || 'BNB']?.toSignificant() || '0')
    )
  }, 0)
  const supplyData = data
    .filter((o) => {
      const isBNB = !o.address
      if (isBNB) {
        // @ts-ignore
        return !!supplyBalances?.BNB?.greaterThan(BigInt(0))
      }
      return !!supplyBalances?.[o.address]?.greaterThan(BigInt(0))
    })
    .map((o) => {
      const tmp = supplyBalances?.[o?.address || 'BNB']
      const supplyBalance = new BigNumber2(tmp?.toFixed(tmp?.currency?.decimals) || 0)
      const apy = +(supplyApys?.[o.address || 'BNB'] || 0)

      return {
        token: o,
        apy,
        supplyBalance,
      }
    })
  const filteredTokens: any = useMemo(() => {
    if (isAddressSearch) return searchToken ? [searchToken] : []
    return filterTokens(Object.values(allTokens), searchQuery)
  }, [isAddressSearch, searchToken, allTokens, searchQuery])

  const filteredSortedTokens: any[] = useMemo(() => {
    if (searchToken) return [searchToken]
    const sorted = filteredTokens.sort(tokenComparator)
    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0)
    if (symbolMatch.length > 1) return sorted

    return [
      ...(searchToken ? [searchToken] : []),
      // sort any exact symbol matches first
      ...sorted.filter((token) => token.symbol?.toLowerCase() === symbolMatch[0]),
      ...sorted.filter((token) => token.symbol?.toLowerCase() !== symbolMatch[0]),
    ]
  }, [filteredTokens, searchQuery, searchToken, tokenComparator])

  const handleInput = useCallback((event) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    if (input) {
      setShowMore(true)
    }
  }, [])

  const handleToggle = useCallback(
    async (event, cTokenAddress) => {
      try {
        setLoading(true)
        event.stopPropagation()
        if (event.target.checked) {
          // enter market
          const tx = await lendingControllerContract?.enterMarkets([cTokenAddress], {
            gasLimit: 200000,
          })
          const res = await tx.wait()
          console.log('Event Status:', res.events?.[0]?.event)
        } else {
          const tx = await lendingControllerContract?.exitMarket(cTokenAddress, {
            gasLimit: 200000,
          })
          const res = await tx.wait()
          console.log('Event Status:', res.events?.[0]?.event, res)
        }

        setLoading(false)
      } catch (err) {
        console.log('Error: ', err)
        setLoading(false)
      }
    },
    [lendingControllerContract]
  )

  useEffect(() => {
    setData(sortTokens(filteredSortedTokens))
  }, [filteredSortedTokens])

  return (
    <div className="section supply">
      {!!selectedToken && (
        <SupplyModal
          // @ts-ignore
          price={lendingPrices?.[selectedToken?.address || 'BNB'] || 0}
          // @ts-ignore
          enteredMarket={enteredMarkets.includes(selectedToken?.tokenInfo?.cToken?.address)}
          // @ts-ignore
          apy={+(!selectedToken?.address ? supplyApys.BNB : supplyApys[selectedToken.address] || 0)}
          totalBorrowBalances={totalBorrowBalances}
          isOpen={!!selectedToken}
          token={selectedToken}
          onDismiss={() => setSelectedToken(null)}
          borrowLimit={liquidity + totalBorrowBalances}
          // @ts-ignore
          tokenBalance={selectedToken?.address ? balances[selectedToken?.address] : balances.BNB}
        />
      )}
      {!!loading && <LoadingScreen />}

      <div className="section-content">
        <div className="yours-table">
          <div className="row thead">
            <div className="cell">{TranslateString(90082, 'Supplied Asset')}</div>
            <div className="cell">{TranslateString(90083, 'APY / Earned')}</div>
            <div className="cell">{TranslateString(90084, 'Balance')}</div>
            <div className="cell">{TranslateString(90085, 'Collateral')}</div>
          </div>
          {supplyData.map((item, index) => {
            const { token } = item
            const cTokenAddress = token.tokenInfo.cToken.address
            // @ts-ignore
            const enteredMarket = enteredMarkets.includes(cTokenAddress)
            const hasBorrow = parseFloat(borrowBalances[token?.address || 'BNB']?.toSignificant() || '0') !== 0

            return (
              <Animated index={index} key={`c${token.symbol}`} className="row">
                <div className="cell" onClick={() => setSelectedToken(token)}>
                  <CurrencyLogo currency={item.token} size="1.5rem" />
                  <span style={{ marginLeft: '0.75rem' }}>{token.symbol}</span>
                </div>
                <div className="cell">{formatNumber(item.apy, 4)}%</div>
                <div className="cell">
                  {formatNumber(item.supplyBalance, 8)} {item.symbol}
                </div>
                <div className="cell">
                  <Toggle
                    checked={enteredMarket}
                    onChange={
                      hasBorrow
                        ? () => {
                            toastError('Error:', 'Cannot exit market when borrowing this token!')
                          }
                        : (e) => handleToggle(e, cTokenAddress)
                    }
                    scale="sm"
                  />
                </div>
              </Animated>
            )
          })}
          {!supplyData?.length && (
            <div className="row tfoot">
              <div className="cell text-center light">You have no supplied assets</div>
            </div>
          )}
        </div>
      </div>

      <div className="info">
        <div className="title highlight">Supply assets</div>
        <div className="description">Everything you need to know about supplying</div>
      </div>

      <div className="section-content">
        <div className="input-wrapper">
          <input
            type="text"
            value={searchQuery}
            onChange={handleInput}
            placeholder={TranslateString(90086, 'Search Supply Assets')}
          />
        </div>
        <div className="yours-table">
          <div className="row thead">
            <div className="cell">{TranslateString(90073, 'Asset')}</div>
            <div className="cell">{TranslateString(90087, 'Supply APY')}</div>
            <div className="cell">{TranslateString(90088, 'Wallet')}</div>
          </div>
          {data
            ?.filter((_, i) => {
              if (!showMore) {
                return i < 5
              }
              return true
            })
            ?.map((item, index) => {
              const isBNB = !item.address
              const balance = isBNB ? balances.BNB : balances[item.address]
              const isZeroBalance = !balance || balance?.equalTo?.(BigInt(0))
              // @ts-ignore
              const apy = isBNB ? supplyApys.BNB : supplyApys[item.address]

              return (
                <Animated index={index} key={item.symbol} className="row" onClick={() => setSelectedToken(item)}>
                  <div className="cell">
                    <CurrencyLogo currency={item} size="1.5rem" />
                    <span style={{ marginLeft: '0.75rem' }}>{item.symbol}</span>
                  </div>
                  <div className="cell">{formatNumber(+(apy || 0), 4)}%</div>
                  <div className={isZeroBalance ? 'cell blur' : 'cell'}>
                    {formatNumber(balance?.toSignificant?.() || 0)} {item.symbol}
                  </div>
                </Animated>
              )
            })}
          {!showMore && (
            <div className="row tfoot">
              <div className="cell light text-center">
                <Button variant="text" onClick={() => setShowMore(true)}>
                  Show More
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SupplySection
