import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }

    .empty-menu-link {
      flex: 1;
      opacity: 0;
      cursor: default;
      & > a {
        pointer-events: none;
      }
    }

    .dipo-img img {
      height: 24px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    #root{
      &>div:first-child{
        &>div:first-child{
          background: ${({ theme }) => (theme.isDark ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)')}!important;
        }
        &>div:last-child{
          background: ${({ theme }) => (theme.isDark ? '#141E30' : '#FFFFFF')}!important;
        }
      }
    }

    @keyframes fadeIn {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }

    @keyframes blink {
      0% {opacity: 1;}
      25% {opacity: 0;}
      50% {opacity: 1;}
      75% {opacity: 0;}
      100% {opacity: 1;}
    }

    .fade-in {
      animation: fadeIn 1s;
    }
  }
`

export default GlobalStyle
