import { LinkExternal } from '@bami-libs/uikit'
import { ChainId } from '@pancakeswap-libs/sdk'
import GradientButton from 'components/Button/button'
import React, { useContext } from 'react'
import { ArrowUpCircle } from 'react-feather'
import { ThemeContext } from 'styled-components'
import { getEtherscanLink } from '../../utils'
import { AutoColumn } from '../Column'
import { ConfirmedIcon, ContentHeader, Section, Wrapper } from './helpers'

type TransactionSubmittedContentProps = {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
}

const TransactionSubmittedContent = ({ onDismiss, chainId, hash }: TransactionSubmittedContentProps) => {
  const theme = useContext(ThemeContext)

  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>Transaction submitted</ContentHeader>
        <ConfirmedIcon color="#4e598c">
          <ArrowUpCircle color="#4e598c" strokeWidth={0.5} size={97} />
        </ConfirmedIcon>
        <AutoColumn gap="8px" justify="center">
          {chainId && hash && (
            <LinkExternal color="#4e598c" style={{ color: "#4e598c" }} href={getEtherscanLink(chainId, hash, 'transaction')}>View on bscscan</LinkExternal>
          )}
          <GradientButton name="Close" fontSize="1.1em" padding="10px 20px" style={{ marginTop: "20px" }} onClick={onDismiss} />
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export default TransactionSubmittedContent
