import styled from 'styled-components'

export const primaryColor = '#ff8c42'
export const supplyColor = '#ff8c42'
export const borrowColor = '#4e598c'
export const borrowBgColor = 'rgb(34, 30, 56)'
export const supplyHeadingColor = '#4e598c'
export const supplyBgColor = '#fff0e4'

export const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
`

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  z-index: 1;
  color: ${(props) => (props.theme.isDark ? 'white' : 'black')};

  .text-center {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .supply {
    .highlight {
      color: ${supplyColor};
    }
    .info {
      border-color: ${supplyColor};
    }
    .thead {
      background-color: ${supplyBgColor};

      .cell {
        color: ${supplyHeadingColor};
      }
    }
  }
  .borrow {
    .highlight {
      color: ${borrowColor};
    }
    .info {
      border-color: ${borrowColor};
    }
    .thead {
      background-color: ${supplyHeadingColor};

      .cell {
        color: ${supplyBgColor};
      }
    }
  }

  .section {
    flex: 1;

    &:nth-child(1) {
      padding-right: 1rem;
    }
    &:nth-child(2) {
      padding-left: 1rem;
    }
  }

  .head-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;

    & > div {
      flex: 1;
    }
    & > div:nth-child(2) {
      text-align: right;
    }

    .title {
      font-size: 0.875rem;
      font-weight: 400;
      margin-bottom: 0.5rem;
    }

    .value {
      font-size: 1.5rem;
    }
  }

  .section-content {
    margin: 1rem 0;
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    color: #333;
  }

  .yours-table {
    .row {
      display: flex;

      & > div {
        flex: 1;
      }

      &:nth-child(odd):not(.thead) {
        background-color: #edebec;
        border-radius: 0.25rem;
      }

      &:nth-child(even):not(.thead) {
        color: ${(props) => (props.theme.isDark ? '#ccc' : '#333')};
      }

      &:hover:not(.thead):not(.tfoot) {
        border-radius: 0.25rem;
        transition: 0.3s all;
        background-color: #aaa;
        cursor: pointer;
      }
    }
    .thead {
      border-radius: 2rem;

      .cell {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 0.75rem;
      }
    }
    .tfoot {
      background-color: transparent !important;

      .cell {
        button {
          color: ${supplyHeadingColor} !important;
        }
      }
    }
    .cell {
      padding: 0 1rem;
      min-height: 40px;
      display: flex;
      align-items: center;

      &:not(:first-child) {
        justify-content: flex-end;
        text-align: right;
      }
    }

    .blur {
      opacity: 0.2;
    }
  }

  .info {
    border-left: 2px solid;
    padding-left: 2rem;
    margin: 3rem 0 1rem;

    .title {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0.25rem;
    }
    .description {
      font-size: 0.75rem;
    }
  }

  .input-wrapper {
    margin-bottom: 2rem;

    input {
      border-radius: 2rem;
      border: none;
      width: 100%;
      height: 40px;
      padding: 0 1rem;

      &:focus {
        outline: none;
      }
    }
  }
  .light {
    font-weight: 500 !important;
  }

  @media (max-width: 800px) {
    flex-direction: column;

    .section:nth-child(1) {
      padding-right: 0;
    }

    .section:nth-child(2) {
      padding-left: 0;
    }

    .section-content {
      padding: 1rem 0;
    }

    .yours-table .cell {
      font-weight: 600;
    }
  }
`

export const Animated = styled.div`
  opacity: 0;
  animation: fadeIn 1s 1 forwards;
  animation-delay: ${(props: { index: number }) => 10 * (props.index + 1)}ms;
`

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  .primary {
    &.selected {
      border-color: ${supplyColor};
      color: ${supplyColor};
    }
  }
  .secondary {
    &.selected {
      border-color: ${borrowColor};
      color: ${borrowColor};
    }
  }

  button {
    border-bottom: 2px solid;
    flex: 1;
    border-radius: 0;
    color: #999;
    border-color: #999;
  }

  .currency {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: -2rem;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);

    .symbol {
      width: 100%;
      text-align: center;
      padding: 0.5rem 0;
      margin-top: -0.5rem;
      border-radius: 0.25rem;
      &.primary {
        background-color: ${supplyColor};
      }
      &.secondary {
        background-color: ${borrowColor};
      }
    }
  }
`
export const ModalBody = styled.div`
  padding: 2rem;
  color: ${(props) => (props.theme.isDark ? 'white' : 'black')};

  .top-balance {
    display: flex;

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .max-btn {
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
    color: #fff;
    border-radius: 0.5rem;
    padding: 0.1rem 0.5rem;
    font-size: 0.8rem;
    font-weight: 400;
    border: 1px solid;
    margin-top: 0.25rem;
  }

  .primary {
    .max-btn {
      color: ${supplyColor};
      border-color: ${supplyColor};
    }
    .highlight {
      color: ${supplyColor};
    }

    button:not(.max-btn) {
      background-color: ${supplyColor};
    }
  }

  .secondary {
    .max-btn {
      color: ${borrowColor};
      border-color: ${borrowColor};
    }
    .highlight {
      color: ${borrowColor};
    }

    button:not(.max-btn) {
      background-color: ${borrowColor};
    }
  }

  .label {
    text-transform: uppercase;
    color: #999;
    font-size: 0.75rem;
    font-weight: 600;
  }

  .title {
    color: ${supplyHeadingColor};
    font-size: 0.875rem;
    font-weight: 500;
  }

  .highlight {
    font-size: 0.875rem;
  }

  .input-wrapper {
    margin-bottom: 2rem;
    margin-top: 1rem;

    input {
      border-radius: 2rem;
      border: 1px solid black;
      width: 100%;
      height: 40px;
      padding: 0 1rem;
      margin-right: 0.5rem;

      &:focus {
        outline: none;
      }
    }
  }

  button {
    flex: 1;
  }
`

export const BamiRewardContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  border-bottom: 1px solid #c3c3c3;
  padding-bottom: 2rem;
  z-index: 1;
  color: ${(props) => (props.theme.isDark ? 'white' : 'black')};

  h4 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 0.25rem;
      width: 7rem;
    }
  }

  .content {
    width: 100%;
    display: flex;
    & > div {
      flex: 1;
      display: flex;
      justify-content: space-between;
      padding: 1rem;
    }

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  .supply {
    color: #4e598c;
    background-color: #fff0e4;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;

    @media (max-width: 800px) {
      border-radius: 2rem;
    }
  }

  .borrow {
    color: #fff0e4;
    background-color: #4e598c;
    border-radius: 2rem;

    @media (max-width: 800px) {
      border-radius: 2rem;
    }
  }

  .bottom {
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    button {
      cursor: pointer;
      border: none;
      outline: none;
      background linear-gradient(180deg, #F7B267 0%, #F79D65 24.7%, #F4845F 51%, #F27059 74.5%, #F25C54 100%);
      color: #fff;
      border-radius: 16px;
      padding: 10px 30px;
      font-size: .8em;
      font-weight: 400;
      margin-left: 10px;

      &[disabled] {
        cursor: not-allowed;
        background: lightgray;
      }
    }
  }
`

export const GeneralSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${(props) => (props.theme.isDark ? 'white' : 'black')};

  .title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
  .content {
    font-size: 1.5rem;
  }
  .value {
    margin-top: 0.25rem;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .supply {
    color: ${primaryColor};
  }
  .borrow {
    color: ${borrowColor};
  }

  .section {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .circle-wrapper {
    width: 10rem;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid ${primaryColor};
    border-radius: 50%;
    flex-direction: column;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    position: relative;
    padding-left: 12rem;
    min-height: 10rem;
    align-items: flex-start;

    .section {
      align-items: flex-start;
      justify-content: center;
    }

    .circle-wrapper {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`

export const BorrowLimitWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 2rem 0;
  width: 100%;
  padding: 0 1rem;
  color: ${(props) => (props.theme.isDark ? 'white' : 'black')};

  .progress {
    flex: 1;
  }

  .title {
    font-size: 1rem;
    font-weight: 600;
    color: ${borrowColor};
  }

  .value {
    margin: 0 1rem 0 0.5rem;
    margin-bottom: -2px;
    font-size: 1.5rem;
  }
`
