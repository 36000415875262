import { Menu as UikitMenu } from '@bami-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useAuth from 'hooks/useAuth'
import useTheme from 'hooks/useTheme'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { usePrices } from 'state/price/hooks'
import links from './config'

const Menu: React.FC = (props) => {
  const { account } = useWeb3React()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const prices = usePrices()
  const location = useLocation()
  const { login, logout } = useAuth()

  if ((document.querySelector('#root>div:first-child>div:last-child') as HTMLDivElement) && isDark) {
    ;(document.querySelector('#root>div:first-child>div:last-child') as HTMLDivElement).style.backgroundColor =
      '#141E30'
  }
  useEffect(() => {
    if (location.pathname === '/home-connected') {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:first-child'
      ) as HTMLDivElement).style.background = 'rgba(255, 140, 66,.1)'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:first-child'
      ) as HTMLDivElement).style.boxShadow = 'inset 4px 0px 0px rgba(255, 140, 66,1)'
    } else {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:first-child'
      ) as HTMLDivElement).style.background = 'none'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:first-child'
      ) as HTMLDivElement).style.boxShadow = 'none'
    }
    if (location.pathname === '/swapnew') {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(2)'
      ) as HTMLDivElement).style.background = 'rgba(255, 140, 66,.1)'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(2)'
      ) as HTMLDivElement).style.boxShadow = 'inset 4px 0px 0px rgba(255, 140, 66,1)'
    } else {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(2)'
      ) as HTMLDivElement).style.background = 'none'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(2)'
      ) as HTMLDivElement).style.boxShadow = 'none'
    }
    if (location.pathname === '/pool-new') {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(3)'
      ) as HTMLDivElement).style.background = 'rgba(255, 140, 66,.1)'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(3)'
      ) as HTMLDivElement).style.boxShadow = 'inset 4px 0px 0px rgba(255, 140, 66,1)'
    } else {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(3)'
      ) as HTMLDivElement).style.background = 'none'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(3)'
      ) as HTMLDivElement).style.boxShadow = 'none'
    }
    if (location.pathname === '/bridge') {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(4)'
      ) as HTMLDivElement).style.background = 'rgba(255, 140, 66,.1)'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(4)'
      ) as HTMLDivElement).style.boxShadow = 'inset 4px 0px 0px rgba(255, 140, 66,1)'
    } else {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(4)'
      ) as HTMLDivElement).style.background = 'none'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(4)'
      ) as HTMLDivElement).style.boxShadow = 'none'
    }
    if (location.pathname === '/lending') {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(5)'
      ) as HTMLDivElement).style.background = 'rgba(255, 140, 66,.1)'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(5)'
      ) as HTMLDivElement).style.boxShadow = 'inset 4px 0px 0px rgba(255, 140, 66,1)'
    } else {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(5)'
      ) as HTMLDivElement).style.background = 'none'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(5)'
      ) as HTMLDivElement).style.boxShadow = 'none'
    }
    if (location.pathname === '/dipo') {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(9)'
      ) as HTMLDivElement).style.background = 'rgba(255, 140, 66,.1)'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(9)'
      ) as HTMLDivElement).style.boxShadow = 'inset 4px 0px 0px rgba(255, 140, 66,1)'
    } else {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(9)'
      ) as HTMLDivElement).style.background = 'none'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(9)'
      ) as HTMLDivElement).style.boxShadow = 'none'
    }
  }, [location.pathname])

  useEffect(() => {
    if (prices.BAMI) {
      const bamiPrice = parseFloat(prices.BAMI?.toString() || '0').toFixed(4)
      document.title = `Bami - $${bamiPrice}`
    }
  }, [prices])

  return (
    <UikitMenu
      links={links}
      account={account as string}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={parseFloat(prices.BAMI?.toString() || '0')}
      {...props}
    />
  )
}

export default Menu
