import React from 'react'
import styled from 'styled-components'

import frameImg from '../../assets/svg/frame.svg'

interface SponsorProps {
  src: string
}

const Wrapper = styled.div`
  width: 25%;
  padding: 1.25rem;

  @media (max-width: 600px) {
    padding: 0.5rem;
  }
`
const Container = styled.div`
  background: #fff8ee;
  box-shadow: 0 0 0 3px #dfc19a, 3px 3px 2px 2px #394c38;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImgWrapper = styled.div`
  width: 100%;
  padding: 10px;
`

type FrameType = {
  src: string
}
const Frame = styled.div<FrameType>`
  border-radius: 2px;
  position: absolute;
  border: none;
  box-shadow: none;
  left: 0;
  top: 0;
  background: url(${(props) => props.src}) no-repeat center center;
  width: 100%;
  height: 100%;
  background-size: cover;
`

const Sponsor = ({ src }: SponsorProps) => {
  return (
    <Wrapper>
      <Container>
        <ImgWrapper>
          <img alt="sponsor" src={src} />
        </ImgWrapper>
        <Frame src={frameImg} />
      </Container>
    </Wrapper>
  )
}

export default Sponsor
