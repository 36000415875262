import BigNumber from 'bignumber.js'
import React, { useState } from 'react'
import { BigNumber as BigNumberEther } from 'ethers'
import { Progress } from '@bami-libs/uikit'

import { useActiveWeb3React } from 'hooks'
import { formatNumber } from 'utils/format'
import { useLendingControllerContract } from 'hooks/useContract'
import logo from 'assets/images/logo.svg'
import { useSingleCallResult } from 'state/multicall/hooks'
import { useAllLendingTokens } from 'hooks/Tokens'
import {
  useAllLendingCTokenBalances,
  useAllLendingCTokenBorrowBalances,
  useAllLendingTokenBalances,
  useAllLendingTokenSupplyApy,
  useLendingPrices,
} from 'state/wallet/hooks'
import useI18n from 'hooks/useI18n'

import BorrowSection from './BorrowSection'
import { BamiRewardContainer, BorrowLimitWrapper, GeneralSection, PageWrapper } from './styled'
import SupplySection from './SupplySection'

const LendingPage = () => {
  const TranslateString = useI18n()
  const { account } = useActiveWeb3React()
  const [loading, setLoading] = useState(false)
  const lendingControllerContract = useLendingControllerContract()
  // const supplyReward =
  //   useSingleCallResult(lendingControllerContract, 'getSupplierBami', [account || undefined])?.result?.[0] || 0
  const supplyReward = 0
  // const borrowReward =
  //   useSingleCallResult(lendingControllerContract, 'getBorrowerBami', [account || undefined])?.result?.[0] || 0
  const borrowReward = 0
  // const bamiAccrued =
  //   useSingleCallResult(lendingControllerContract, 'BamiAccrued', [account || undefined])?.result?.[0] || 0
  const bamiAccrued = 0
  const bamiReward = new BigNumber(supplyReward.toString())
    .plus(new BigNumber(borrowReward.toString()))
    .plus(new BigNumber(bamiAccrued.toString()))
    .div(new BigNumber(10).pow(18))
  const allTokens = useAllLendingTokens()
  const balances = useAllLendingTokenBalances()
  const supplyBalances = useAllLendingCTokenBalances()
  const [lendingPrices] = useLendingPrices()
  const [supplyApys] = useAllLendingTokenSupplyApy()
  // const supplyApys = {}
  const [borrowBalances] = useAllLendingCTokenBorrowBalances()
  let liquidity = useSingleCallResult(lendingControllerContract, 'getAccountLiquidity', [account || undefined])
    ?.result?.[1]
  liquidity = parseFloat(
    liquidity ? new BigNumber(BigNumberEther.from(liquidity).toString()).div(new BigNumber(10).pow(18)).toString() : '0'
  )

  let netApy = 0.0
  const totalSupplyBalances = Object.values(allTokens)?.reduce((res, cur) => {
    const supplyBalance = +(supplyBalances?.[cur?.address || 'BNB']?.toSignificant() || 0)
    const apy = +(supplyApys?.[cur.address || 'BNB'] || 0)
    netApy += apy * supplyBalance * lendingPrices?.[cur?.address || 'BNB']

    return (
      res +
      (lendingPrices?.[cur?.address || 'BNB'] || 0) *
        parseFloat(supplyBalances[cur?.address || 'BNB']?.toSignificant() || '0')
    )
  }, 0)
  const apyValue = netApy / 100.0
  netApy /= totalSupplyBalances
  const totalBorrowBalances = Object.values(allTokens)?.reduce((res, cur) => {
    return (
      res +
      (lendingPrices?.[cur?.address || 'BNB'] || 0) *
        parseFloat(borrowBalances[cur?.address || 'BNB']?.toFixed(cur.decimals) || '0')
    )
  }, 0)

  const handleClaim = async () => {
    try {
      setLoading(true)
      const tx = await lendingControllerContract?.['claimBami(address)'](account)
      const res = await tx.wait()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('err', error)
    }
  }

  return (
    <>
      {!!account && (
        <BamiRewardContainer>
          <h4>
            <img src={logo} alt="bami logo" /> Reward
          </h4>
          <div className="content">
            <div className="borrow">
              <div className="title">Total Reward</div>
              <div className="value">{formatNumber(bamiReward.toString(), 4)} BAMI</div>
            </div>
          </div>
          <div className="bottom">
            <button type="button" disabled>
              {loading ? 'Loading...' : TranslateString(90077, 'CLAIM ALL')}
            </button>
          </div>
        </BamiRewardContainer>
      )}
      <GeneralSection>
        <div className="section">
          <div className="title supply">{TranslateString(90078, 'Supply Balance')}</div>
          <div className="content">${formatNumber(totalSupplyBalances)}</div>
        </div>
        <div className="circle-wrapper">
          <div className="title supply">{TranslateString(90079, 'Net APY')}</div>
          <div className="content">{formatNumber(netApy, 4)}%</div>
          <div className="value">${formatNumber(apyValue, 2)}</div>
        </div>
        <div className="section">
          <div className="title borrow">{TranslateString(90080, 'Borrow Balance')}</div>
          <div className="content">${formatNumber(totalBorrowBalances)}</div>
        </div>
      </GeneralSection>
      <BorrowLimitWrapper>
        <div className="title">{TranslateString(90081, 'Borrow Limit')}</div>
        <div className="value">{formatNumber((totalBorrowBalances / (totalBorrowBalances + liquidity)) * 100)}%</div>
        <div className="progress">
          <Progress variant="round" primaryStep={(totalBorrowBalances / (totalBorrowBalances + liquidity)) * 100} />
        </div>
      </BorrowLimitWrapper>

      <PageWrapper>
        <SupplySection
          allTokens={allTokens}
          balances={balances}
          supplyBalances={supplyBalances}
          lendingPrices={lendingPrices}
          supplyApys={supplyApys}
          borrowBalances={borrowBalances}
          liquidity={liquidity}
        />
        <BorrowSection
          lendingPrices={lendingPrices}
          allTokens={allTokens}
          balances={balances}
          borrowBalances={borrowBalances}
          liquidity={liquidity}
        />
      </PageWrapper>
    </>
  )
}

export default LendingPage
