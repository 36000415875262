import { ButtonProps, useWalletModal } from '@bami-libs/uikit'
import GradientButton from 'components/Button/button'
import useAuth from 'hooks/useAuth'
import useI18n from 'hooks/useI18n'
import React from 'react'

const UnlockButton: React.FC<ButtonProps> = (props) => {
  const TranslateString = useI18n()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)

  return (
    <GradientButton name={TranslateString(94, 'Connect Wallet')} onClick={onPresentConnectModal} {...props} />
  )
}

export default UnlockButton