import styled from "styled-components";

const SettingsPopup = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props: any) => props.theme.isDark ? "rgba(36, 59, 85,.5)" : "rgba(255, 140, 66, 0.3)"};
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;

  div.settings_container {
    width: 700px;
    background: ${(props: any) => props.theme.isDark ? "#141E30" : "#fff"};
    border-radius: 10px;

    @media(max-width: 720px){
      width: 97%;
    }

    h5.settings_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 20px;
      border-bottom: 2px solid rgba(78, 89, 140, 0.1);
      font-size: 1.4em;
      font-weight: 500;
      color: #4e598c;

      @media(max-width: 450px){
        font-size: 1.2em;
      }

      button {
        border: none;
        outline: none;
        background: none;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    div.settings-body {
      padding: 30px 20px 14px 20px;

      h5.body_header {
        font-size: 1.6em;
        font-weight: 500;
        color: #4e598c;
        margin-bottom: 20px;

        @media(max-width: 450px){
          font-size: 1.3em;
        }
      }

      div.percentage-inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;

        .activePercent {
            color:white;
          background: #ff8c42;
        }
        button,input {
          flex: 0 0 13%;
          max-width: 13%;
          font-size: 1.4em;
          padding: 8px 20px;
          color: #ff8c42;
          font-weight: 100;
          border: 1px solid #4e598c;
          background: none;
          outline: none;
          border-radius: 10px;

          @media(max-width: 450px){
            font-size: 1.1em;

            flex: 0 0 18%;
            max-width: 18%;
            padding: 6px 12px;
          }

          &:nth-child(4) {
            flex: 0 0 47%;
            max-width: 47%;

            @media(max-width: 450px){
              flex: 0 0 40%;
              max-width: 40%;
            }
          }
        }
      }

      div.min-input {
        display: flex;
        align-items: center;

        input {
          flex: 0 0 22%;
          max-width: 22%;
          font-size: 1.4em;
          padding: 8px 20px;
          color: #ff8c42;
          font-weight: 100;
          border: 1px solid #4e598c;
          background: none;
          outline: none;
          border-radius: 10px;

          @media(max-width: 450px){
            font-size: 1.1em;

            flex: 0 0 45%;
            max-width: 45%;
            padding: 8px 12px;
          }
        }

        h5 {
          font-size: 1.2em;
          font-weight: 500;
          color: #4e598c;
          margin-left: 10px;
        }
      }
    }
  }
`

export default SettingsPopup