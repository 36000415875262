import React, { useEffect, useState } from 'react'
import { Button, Flex, useWalletModal } from '@bami-libs/uikit'
import { MaxUint256 } from '@ethersproject/constants'
import BigNumber from 'bignumber.js'
import { BigNumber as BigNumberEther } from 'ethers'

import CurrencyLogo from 'components/CurrencyLogo'
import Modal from 'components/Modal'
import { useActiveWeb3React } from 'hooks'
import { useLendingContract, useLendingControllerContract, useTokenContract } from 'hooks/useContract'
import { useSingleCallResult } from 'state/multicall/hooks'
import { useCTokenBalance } from 'state/wallet/hooks'
import LENDINGCBEP20 from 'constants/abis/cBep20.json'
import LENDINGCBNB from 'constants/abis/cBNB.json'
import { formatNumber } from 'utils/format'
import useAuth from 'hooks/useAuth'
import { TokenAmount } from '@pancakeswap-libs/sdk'
import { LENDING_FEE_PERCENT } from 'constants/index'
import useI18n from 'hooks/useI18n'

import { ModalBody, ModalHeader } from './styled'

const SupplyModal = ({
  isOpen,
  onDismiss,
  token,
  apy,
  enteredMarket = false,
  borrowLimit,
  tokenBalance,
  price = 0,
  totalBorrowBalances,
}) => {
  const TranslateString = useI18n()
  let err
  const { login, logout } = useAuth()
  const { account } = useActiveWeb3React()
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState<'supply' | 'withdraw'>('supply')
  const { cToken } = token.tokenInfo
  const isBNB = !token.address
  const { address: cTokenAddress } = cToken
  const [amount, setAmount] = useState<number | string>()
  const cTokenContract = useLendingContract(cTokenAddress, LENDINGCBEP20)
  const cBNBContract = useLendingContract(cTokenAddress, LENDINGCBNB)
  const cTokenBalance = useCTokenBalance(account ?? undefined, {
    ...token,
    symbol: `c${token.symbol}`,
    address: cTokenAddress,
  })
  const [approved, setApproved] = useState<boolean | undefined>(isBNB)
  const tokenContract = useTokenContract(token.address, true)
  const { onPresentConnectModal } = useWalletModal(login, logout)
  const accountAllowance = useSingleCallResult(tokenContract, 'allowance', [account, cTokenAddress]).result
  const lendingControllerContract = useLendingControllerContract()
  const collateralRes = useSingleCallResult(lendingControllerContract, 'markets', [cTokenAddress]).result
  const collateralFactor: BigNumberEther = collateralRes?.['1']
  const collateralFactorNumber = collateralFactor?.div(BigNumberEther.from(10).pow(16)).toNumber() / 100.0
  const currentBorrowLimitPercent = (totalBorrowBalances / borrowLimit) * 100
  const nextBorrowLimitPercent = amount
    ? tab === 'supply'
      ? (totalBorrowBalances / (borrowLimit + +amount * price * collateralFactorNumber)) * 100
      : (totalBorrowBalances / (borrowLimit - +amount * price * collateralFactorNumber)) * 100
    : currentBorrowLimitPercent
  const nextBorrowLimit =
    amount && enteredMarket
      ? tab === 'supply'
        ? borrowLimit + +amount * price * collateralFactorNumber
        : borrowLimit - +amount * price * collateralFactorNumber
      : borrowLimit

  const balance =
    tab === 'supply'
      ? formatNumber(tokenBalance?.toSignificant() || 0, 18)
      : formatNumber(cTokenBalance?.toSignificant() || 0, 18)

  useEffect(() => {
    if (accountAllowance && !accountAllowance[0]?.isZero()) {
      setApproved(true)
    }
  }, [accountAllowance])

  if (tab === 'supply') {
    if (amount && parseFloat((tokenBalance as TokenAmount)?.toSignificant() || '0') < +amount) {
      err = TranslateString(90102, 'Insufficient Amount')
    }
  } else if (tab === 'withdraw') {
    if (amount && parseFloat((cTokenBalance as TokenAmount).toSignificant()) < +amount) {
      err = TranslateString(90102, 'Insufficient Amount')
    } else if (amount && enteredMarket && borrowLimit <= 0) {
      err = TranslateString(90103, 'Insufficient Collateral')
    }
  }

  const handleApprove = async () => {
    try {
      setLoading(true)
      const tx = await tokenContract?.approve(cTokenAddress, MaxUint256, {
        gasLimit: 100000,
      })
      await tx.wait()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleSupply = async () => {
    if (!amount) {
      return
    }
    try {
      setLoading(true)
      const input = new BigNumber(10)
        .pow(token.tokenInfo.decimals)
        .multipliedBy(+amount)
        .toFixed(0)

      const tx = isBNB
        ? await cBNBContract?.mint({
            value: input,
            from: account,
          })
        : await cTokenContract?.mint(input)
      await tx.wait()
      setLoading(false)
      setAmount('')
    } catch (error) {
      console.log('err', error)
      setLoading(false)
    }
  }

  const handleWithdraw = async () => {
    if (!amount) {
      return
    }
    try {
      setLoading(true)
      const input = new BigNumber(10)
        .pow(token.tokenInfo.decimals)
        .multipliedBy(+amount)
        .toFixed(0)
      const tx = await cTokenContract?.redeemUnderlying(input)
      const res = await tx.wait()
      console.log('Event Status:', res.events[0].event)
      setLoading(false)
      setAmount('')
    } catch (error) {
      setLoading(false)
      console.log('err', error)
    }
  }

  const generateSubmitButton = () => {
    if (loading) {
      return <Button disabled>Loading...</Button>
    }

    if (!account) {
      return <Button onClick={onPresentConnectModal}>{TranslateString(94, 'Connect Wallet')}</Button>
    }

    if (tab === 'supply') {
      return approved ? <Button disabled>{TranslateString(90091, 'Supply')}</Button> : <Button disabled>Approve</Button>
    }

    return (
      <Button disabled={!amount || err} onClick={handleWithdraw}>
        {TranslateString(90090, 'Withdraw')}
      </Button>
    )
  }
  const handleMax = () => {
    setAmount(tab === 'supply' ? tokenBalance?.toSignificant() || 0 : cTokenBalance?.toSignificant() || 0)
  }

  useEffect(() => {
    setAmount('')
  }, [tab])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={80}>
      <div style={{ width: '100%' }}>
        <ModalHeader>
          <Button
            onClick={() => setTab('supply')}
            variant="text"
            className={tab === 'supply' ? 'primary selected' : 'primary'}
          >
            {TranslateString(90089, 'Supply')}
          </Button>
          <div className="currency">
            <CurrencyLogo currency={token} size="4rem" />
            <div className={tab === 'supply' ? 'symbol primary' : 'symbol secondary'}>{token.symbol}</div>
          </div>
          <Button
            onClick={() => setTab('withdraw')}
            variant="text"
            className={tab === 'withdraw' ? 'secondary selected' : 'secondary'}
          >
            {TranslateString(90090, 'Withdraw')}
          </Button>
        </ModalHeader>
        <ModalBody>
          <div className={tab === 'supply' ? 'primary' : 'secondary'}>
            <Flex justifyContent="space-between" alignItems="flex-end">
              <div className="label">
                {tab === 'supply' ? TranslateString(90097, 'Supply Amount') : TranslateString(90098, 'Withdraw Amount')}
              </div>
              <div className="top-balance">
                <span style={{ marginRight: '0.25rem' }} className="title">
                  {tab === 'supply'
                    ? TranslateString(90099, 'Wallet Balance:')
                    : TranslateString(90100, 'Supply Balance:')}
                </span>
                <span className="highlight">
                  {balance} {token.symbol}
                </span>
              </div>
            </Flex>

            {tab === 'withdraw' && (
              <Flex style={{ marginTop: '2rem' }} alignItems="flex-end">
                <div className="label" style={{ marginRight: '0.25rem' }}>
                  {TranslateString(90101, 'Fee:')}
                </div>
                <div className="title" style={{ fontSize: '0.625rem' }}>
                  {LENDING_FEE_PERCENT * 100}%{' '}
                  {amount && `(≈${formatNumber(+(amount || 0) * LENDING_FEE_PERCENT, 8)} ${token.symbol})`}
                </div>
              </Flex>
            )}
            <Flex className="input-wrapper" alignItems="center">
              <input
                value={amount}
                onChange={(e) => setAmount(parseFloat(e.target.value))}
                type="number"
                placeholder="0.00"
              />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                {token.symbol}
                <button type="button" className="max-btn" onClick={handleMax}>
                  MAX
                </button>
              </div>
            </Flex>
            {!!err && <div style={{ marginBottom: '2rem', textAlign: 'center', color: 'red' }}>{err}</div>}

            <div style={{ marginBottom: '2rem' }}>
              <div style={{ marginBottom: '0.5rem' }} className="label">
                {TranslateString(90092, 'Supply Stats')}
              </div>
              <Flex style={{ marginBottom: '0.25rem' }} justifyContent="space-between" alignItems="flex-end">
                <div className="title">{TranslateString(90087, 'Supply APY')}</div>
                <div className="title">{formatNumber(apy, 4)}%</div>
              </Flex>
              <Flex style={{ marginBottom: '0.25rem' }} justifyContent="space-between" alignItems="flex-end">
                <div className="title">{TranslateString(90084, 'Supply Balance')}</div>
                <div className="title">
                  {formatNumber(cTokenBalance?.toSignificant() || 0, 18)} {token.symbol}
                </div>
              </Flex>
            </div>

            {tab === 'supply' && (
              <div style={{ marginBottom: '2rem' }}>
                <div style={{ marginBottom: '0.5rem' }} className="label">
                  {TranslateString(90092, 'COLLATERAL')}
                </div>
                <Flex style={{ marginBottom: '0.25rem' }} justifyContent="space-between" alignItems="flex-end">
                  <div className="title">{TranslateString(90093, 'Collateral Factor')}</div>
                  <div className="title">
                    {formatNumber(collateralFactor?.div(BigNumberEther.from(10).pow(16)).toNumber() || 0)}%
                  </div>
                </Flex>
                <Flex style={{ marginBottom: '0.25rem' }} justifyContent="space-between" alignItems="flex-end">
                  <div className="title">{TranslateString(90094, 'Used as Collateral')}</div>
                  <div className="title">{enteredMarket ? 'Yes' : 'No'}</div>
                </Flex>
              </div>
            )}

            <div style={{ marginBottom: '2rem' }}>
              <div style={{ marginBottom: '0.5rem' }} className="label">
                {TranslateString(90081, 'BORROW LIMIT').toUpperCase()}
              </div>
              <Flex style={{ marginBottom: '0.25rem' }} justifyContent="space-between" alignItems="flex-end">
                <div className="title">{TranslateString(90095, 'Your Borrow Limit')}</div>
                <div className="title">
                  ${formatNumber(borrowLimit)} -{'>'} ${formatNumber(nextBorrowLimit)}
                </div>
              </Flex>
              <Flex style={{ marginBottom: '0.25rem' }} justifyContent="space-between" alignItems="flex-end">
                <div className="title">{TranslateString(90096, 'Borrow Limit Used')}</div>
                <div className="title">
                  {formatNumber(currentBorrowLimitPercent)}% -{'>'} {formatNumber(nextBorrowLimitPercent)}%
                </div>
              </Flex>
            </div>

            <Flex justifyContent="center" alignItems="center">
              {generateSubmitButton()}
            </Flex>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default SupplyModal
