import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  border: none;
  outline: none;
  background: ${(props: any) =>
    props.disabled
      ? 'rgba(112, 112, 112, 0.3)'
      : 'linear-gradient(180deg, #f7b267 0%, #f79d65 24.7%, #f4845f 51%, #f27059 74.5%, #f25c54 100%)'};
  color: #fff;
  border-radius: ${(props: any) => (props.borderRadius ? props.borderRadius : '5px')};
  padding: ${(props: any) => (props.padding ? props.padding : '10px 14px')};
  font-size: ${(props: any) => (props.fontSize ? props.fontSize : '.8em')};
  width: ${(props: any) => (props.width ? props.width : 'auto')};
  font-weight: 400;
  margin: ${(props: any) => (props.margin ? props.margin : '0')};
  margin-right: ${(props: any) => (props.marginRight ? props.marginRight : '24px')};
  cursor: ${(props: any) => (props.disabled ? 'not-allowed' : 'pointer')};
`

function GradientButton(props) {
  const { name } = props

  return <Button {...props}>{name}</Button>
}

export default GradientButton
