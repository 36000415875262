/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-empty */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-const */
/* eslint-disable no-throw-literal */
/* eslint-disable spaced-comment */
/* eslint-disable prefer-template */
/* eslint-disable no-restricted-syntax */
// @ts-nocheck
import { ethers } from 'ethers'
import * as ethcall from 'ethcall'
import axios from 'axios'
import flatMap from 'lodash.flatmap'

import { ERC20_ABI, BAMI_CHEF_ABI, UNI_ABI, VALUE_LP_ABI } from 'constants/abis'

let walletProvider

const bscTokens = [
  { id: 'wbnb', symbol: 'wbnb', contract: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c' },
  { id: 'binance-usd', symbol: 'busd', contract: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56' },
  { id: 'pancakeswap-token', symbol: 'CAKE', contract: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82' },
  { id: 'beefy-finance', symbol: 'BIFI', contract: '0xca3f508b8e4dd382ee878a314789373d80a5190a' },
  { id: 'bdollar-share', symbol: 'sBDO', contract: '0x0d9319565be7f53cefe84ad201be3f40feae2740' },
  { id: 'belugaswap', symbol: 'BELUGA', contract: '0x181de8c57c4f25eba9fd27757bbd11cc66a55d31' },
  { id: 'chainlink', symbol: 'LINK', contract: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd' },
  { id: 'bscex', symbol: 'BSCX', contract: '0x5ac52ee5b2a633895292ff6d8a89bb9190451587' },
  { id: 'binance-eth', symbol: 'BETH', contract: '0x250632378e573c6be1ac2f97fcdf00515d0aa91b' },
  { id: 'tether', symbol: 'USDT', contract: '0x55d398326f99059fF775485246999027B3197955' },
  { id: 'bitcoin-bep2', symbol: 'BTCB', contract: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c' },
  { id: 'ethereum', symbol: 'ETH', contract: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8' },
  { id: 'bakerytoken', symbol: 'BAKE', contract: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5' },
  { id: 'goose-finance', symbol: 'EGG', contract: '0xf952fc3ca7325cc27d15885d37117676d25bfda6' },
  { id: 'dai', symbol: 'DAI', contract: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3' },
  { id: 'auto', symbol: 'AUTO', contract: '0xa184088a740c695e156f91f5cc086a06bb78b827' },
  { id: 'wault-finance', symbol: 'WAULT', contract: '0x6ff2d9e5891a7a7c554b80e0d1b791483c78bce9' },
  { id: 'swipe', symbol: 'SXP', contract: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A' },
  { id: 'vai', symbol: 'VAI', contract: '0x4bd17003473389a42daf6a0a729f6fdb328bbbd7' },
  { id: 'venus', symbol: 'XVS', contract: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63' },
  { id: 'terrausd', symbol: 'UST', contract: '0x23396cf899ca06c4472205fc903bdb4de249d6fc' },
  { id: 'cardano', symbol: 'ADA', contract: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47' },
  { id: 'bearn-fi', symbol: 'BFI', contract: '0x81859801b01764d4f0fa5e64729f5a6c3b91435b' },
  { id: 'polkadot', symbol: 'DOT', contract: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402' },
  { id: 'vbswap', symbol: 'VBSWAP', contract: '0x4f0ed527e8a95ecaa132af214dfd41f30b361600' },
  { id: 'bdollar', symbol: 'BDO', contract: '0x190b589cf9fb8ddeabbfeae36a813ffb2a702454' },
  { id: 'julswap', symbol: 'JULD', contract: '0x5a41f637c3f7553dba6ddc2d3ca92641096577ea' },
  { id: 'the-famous-token', symbol: 'TFT', contract: '0xA9d3fa202b4915c3eca496b0e7dB41567cFA031C' },
  { id: 'shield-protocol', symbol: 'SHIELD', contract: '0x60b3bc37593853c04410c4f07fe4d6748245bf77' },
  { id: 'lead-token', symbol: 'LEAD', contract: '0x2ed9e96EDd11A1fF5163599A66fb6f1C77FA9C66' },
  { id: 'sparkpoint', symbol: 'SRK', contract: '0x3B1eC92288D78D421f97562f8D479e6fF7350a16' },
  { id: 'curate', symbol: 'XCUR', contract: '0x708C671Aa997da536869B50B6C67FA0C32Ce80B2' },
  { id: 'uniswap', symbol: 'UNI', contract: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1' },
  { id: 'tsuki-dao', symbol: 'TSUKI', contract: '0x3fd9e7041c45622e8026199a46f763c9807f66f3' },
  { id: 'panda-yield', symbol: 'BBOO', contract: '0xd909840613fcb0fadc6ee7e5ecf30cdef4281a68' },
  { id: 'cryptex', symbol: 'CRX', contract: '0x97a30C692eCe9C317235d48287d23d358170FC40' },
  { id: 'polis', symbol: 'POLIS', contract: '0xb5bea8a26d587cf665f2d78f077cca3c7f6341bd' },
  { id: 'tether', symbol: 'USDT', contract: '0x049d68029688eAbF473097a2fC38ef61633A3C7A' },
  { id: 'swirl-cash', symbol: 'SWIRL', contract: '0x52d86850bc8207b520340b7e39cdaf22561b9e56' },
  { id: 'squirrel-finance', symbol: 'NUTS', contract: '0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556' },
  { id: 'usd-coin', symbol: 'USDC', contract: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d' },
  { id: 'iron-stablecoin', symbol: 'IRON', contract: '0x7b65b489fe53fce1f6548db886c08ad73111ddd8' },
  { id: 'midas-dollar', symbol: 'MDO', contract: '0x35e869b7456462b81cdb5e6e42434bd27f3f788c' },
  { id: 'slime-finance', symbol: 'SLME', contract: '0x4fcfa6cc8914ab455b5b33df916d90bfe70b6ab1' },
  { id: 'bolt-true-dollar', symbol: 'BTD', contract: '0xd1102332a213e21faf78b69c03572031f3552c33' },
  { id: 'mdex', symbol: 'MDX', contract: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739' },
  { id: 'ice-token', symbol: 'ICE', contract: '0xf16e81dce15b08f326220742020379b855b87df9' },
  { id: 'alpaca-finance', symbol: 'ALPACA', contract: '0x8f0528ce5ef7b51152a59745befdd91d97091d2f' },
  { id: 'blue-planetfinance', symbol: 'AQUA', contract: '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991' },
  { id: 'dogecoin', symbol: 'DOGE', contract: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43' },
  { id: 'degen', symbol: 'DGNZ', contract: '0xb68a67048596502A8B88f1C10ABFF4fA99dfEc71' },
  { id: 'degencomp', symbol: 'aDGNZ', contract: '0xe8B9b396c59A6BC136cF1f05C4D1A68A0F7C2Dd7' },
]

const bscNetwork = {
  chainId: '0x38',
  chainName: 'Binance Smart Chain Mainnet',
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: [
    'https://bsc-dataseed1.binance.org',
    'https://bsc-dataseed2.binance.org',
    'https://bsc-dataseed3.binance.org',
    'https://bsc-dataseed4.binance.org',
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed2.defibit.io',
    'https://bsc-dataseed3.defibit.io',
    'https://bsc-dataseed4.defibit.io',
    'https://bsc-dataseed1.ninicoin.io',
    'https://bsc-dataseed2.ninicoin.io',
    'https://bsc-dataseed3.ninicoin.io',
    'https://bsc-dataseed4.ninicoin.io',
    'wss://bsc-ws-node.nariox.org',
  ],
  blockExplorerUrls: ['https://bscscan.com'],
}

const chunk = (arr, n) => (arr.length ? [arr.slice(0, n), ...chunk(arr.slice(n), n)] : [])

function formatMoney(amount, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
    let j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    )
  } catch (e) {
    console.log(e)
  }
}

const displayPrice = (price) => {
  const priceDecimals = price == 0 ? 2 : price < 0.0001 ? 10 : price < 0.01 ? 6 : 2
  return priceDecimals == 2 ? formatMoney(price) : price.toFixed(priceDecimals)
}

const lookUpPrices = async (id_array) => {
  const prices = {}
  for (const id_chunk of chunk(id_array, 50)) {
    const ids = id_chunk.join('%2C')
    const res = await axios({
      url: 'https://api.coingecko.com/api/v3/simple/price?ids=' + ids + '&vs_currencies=usd',
      type: 'GET',
    })
    for (const [key, v] of Object.entries(res.data)) {
      if (v.usd) prices[key] = v
    }
  }
  return prices
}

async function getBscPrices() {
  const idPrices = await lookUpPrices(bscTokens.map((x) => x.id))
  const prices = {}
  for (const bt of bscTokens) if (idPrices[bt.id]) prices[bt.contract] = idPrices[bt.id]
  return prices
}

const connectWallet = async function (callback) {
  try {
    walletProvider = await window.web3Modal?.connect()

    walletProvider.on('accountsChanged', (accounts) => {
      if (accounts === undefined || accounts.length === 0) {
        window.web3Modal?.clearCachedProvider()
      }
      window.location.reload()
    })

    walletProvider.on('chainChanged', (networkId) => {
      window.location.reload()
    })

    let targetNetwork = bscNetwork
    let provider = new ethers.providers.Web3Provider(walletProvider)
    let connectedNetwork = await provider.getNetwork()
    let targetNetworkId = parseInt(targetNetwork.chainId, 16)

    if (connectedNetwork.chainId === targetNetworkId) {
      callback()
    } else {
      // console.log(
      //   `You are connected to ${networkNameFromId(connectedNetwork.chainId)}, please switch to ${
      //     targetNetwork.chainName
      //   } network`
      // )
      // if (window.ethereum && targetNetwork.chainId !== '0x1') {
      //   console.log('')
      //   console.log('[SWITCH NETWORK]', () => switchNetwork(targetNetwork), 'connect_wallet_button')
      // }
      // hideLoading()
    }
  } catch (e) {
    console.log('edsadsadasdassa', e)
  }
}

const init_wallet = async (callback) => {
  let targetNetwork = bscNetwork

  if (walletProvider) {
    let provider = new ethers.providers.Web3Provider(walletProvider)
    let connectedNetwork = await provider.getNetwork()
    let targetNetworkId = parseInt(targetNetwork.chainId, 16)

    if (connectedNetwork.chainId === targetNetworkId) {
      // console.log('[CHANGE WALLET]', changeWallet, 'connect_wallet_button')
      return callback()
    }
  } else {
    console.log('[CONNECT WALLET]', () => connectWallet(callback), 'connect_wallet_button')
    // hideLoading()
  }
}

const init_ethers = async () => {
  const App = {}

  let isMetaMaskInstalled = true

  try {
    // Modern dapp browsers...
    if (walletProvider) {
      App.web3Provider = walletProvider
      App.provider = new ethers.providers.Web3Provider(walletProvider)
      try {
        // Request account access
        const accounts = await walletProvider.request({ method: 'eth_requestAccounts' })
        App.YOUR_ADDRESS = accounts[0]
      } catch (error) {
        console.log('err', error)
        // User denied account access...
        console.error('User denied account access')
      }
    }
    // If no injected web3 instance is detected, fall back to backup node
    else {
      App.provider = new ethers.providers.JsonRpcProvider(atob(window.ETHEREUM_NODE_URL))
      isMetaMaskInstalled = false
      console.log(
        "You don't have MetaMask installed! Falling back to backup node...\n (will likely to fail. Please install MetaMask extension).\n"
      )
      sleep(10)
    }
    App.ethcallProvider = new ethcall.Provider()
    await App.ethcallProvider.init(App.provider)

    //resolve ENS domain if possible
    if (typeof addr !== 'undefined') {
      if (addr.includes('.eth')) {
        addr = await App.provider.resolveName(addr)
        if (addr == null) {
          console.log('Could not initialize your ENS domain.\n')
        }
      }
      App.YOUR_ADDRESS = addr
    }

    // Could not load URL parameter
    if (!App.YOUR_ADDRESS) {
      if (!isMetaMaskInstalled) {
        if (localStorage.hasOwnProperty('addr')) {
          App.YOUR_ADDRESS = localStorage.getItem('addr')
        } else {
          App.YOUR_ADDRESS = window.prompt('Enter your eth address.')
        }
      } else {
        let accounts = await App.provider.listAccounts()
        App.YOUR_ADDRESS = accounts[0]
      }
    }
  } catch (e) {
    console.log(e)
  }

  if (!App.YOUR_ADDRESS || !ethers.utils.isAddress(App.YOUR_ADDRESS)) {
    throw 'Could not initialize your address. Make sure your address is checksum valid.'
  }

  localStorage.setItem('addr', App.YOUR_ADDRESS)

  return App
}

async function getBep20(App, token, address, stakingAddress) {
  if (address == '0x0000000000000000000000000000000000000000') {
    return {
      address,
      name: 'Binance',
      symbol: 'BNB',
      totalSupply: 1e8,
      decimals: 18,
      staked: 0,
      unstaked: 0,
      contract: null,
      tokens: [address],
    }
  }
  const decimals = await token.decimals()
  return {
    address,
    name: await token.name(),
    symbol: await token.symbol(),
    totalSupply: await token.totalSupply(),
    decimals,
    staked: (await token.balanceOf(stakingAddress)) / 10 ** decimals,
    unstaked: (await token.balanceOf(App.YOUR_ADDRESS)) / 10 ** decimals,
    contract: token,
    tokens: [address],
  }
}

async function getBscUniPool(App, pool, poolAddress, stakingAddress) {
  let q0
  let q1
  const reserves = await pool.getReserves()
  q0 = reserves._reserve0
  q1 = reserves._reserve1
  const decimals = await pool.decimals()
  const token0 = await pool.token0()
  const token1 = await pool.token1()
  return {
    symbol: await pool.symbol(),
    name: await pool.name(),
    address: poolAddress,
    token0,
    q0,
    token1,
    q1,
    totalSupply: (await pool.totalSupply()) / 10 ** decimals,
    stakingAddress,
    staked: (await pool.balanceOf(stakingAddress)) / 10 ** decimals,
    decimals,
    unstaked: (await pool.balanceOf(App.YOUR_ADDRESS)) / 10 ** decimals,
    contract: pool,
    tokens: [token0, token1],
    is1inch: false,
  }
}

// eslint-disable-next-line consistent-return
async function getBscToken(App, tokenAddress, stakingAddress, isLpToken = false) {
  // eslint-disable-next-line eqeqeq
  if (tokenAddress == '0x0000000000000000000000000000000000000000') {
    return getBep20(App, null, tokenAddress, '')
  }
  // const type = window.localStorage.getItem(tokenAddress)
  // if (type) return getBscStoredToken(App, tokenAddress, stakingAddress, type)
  if (!isLpToken || tokenAddress === '0x8249BC1dEA00660d2d38dF126b53C6c9A733e942') {
    const erc20 = new ethers.Contract(tokenAddress, ERC20_ABI, App.provider)
    const erc20tok = await getBep20(App, erc20, tokenAddress, stakingAddress)
    window.localStorage.setItem(tokenAddress, 'erc20')
    return erc20tok
  }
  // try {
  //   const vpool = new ethers.Contract(tokenAddress, VALUE_LP_ABI, App.provider)
  //   const tokenWeights = await vpool.getTokenWeights()
  //   const valuePool = await getValuePool(App, vpool, tokenAddress, stakingAddress, tokenWeights)
  //   // window.localStorage.setItem(tokenAddress, 'value')
  //   return valuePool
  // } catch (err) {}
  try {
    const pool = new ethers.Contract(tokenAddress, UNI_ABI, App.provider)
    const uniPool = await getBscUniPool(App, pool, tokenAddress, stakingAddress)
    // window.localStorage.setItem(tokenAddress, 'uniswap')
    return uniPool
  } catch (err) {}
  try {
    const _3pool = new ethers.Contract(tokenAddress, BSC_3POOL_ABI, App.provider)
    const swap = await _3pool.swap()
    const res = await getBscSwapPool(App, _3pool, tokenAddress, stakingAddress, swap)
    // window.localStorage.setItem(tokenAddress, 'swap')
    return res
  } catch (err) {}
  try {
    const VAULT = new ethers.Contract(tokenAddress, BSC_VAULT_ABI, App.provider)
    const vault = await getBscVault(App, VAULT, tokenAddress, stakingAddress)
    // window.localStorage.setItem(tokenAddress, 'bscVault')
    return vault
  } catch (err) {}
  try {
    const crv = new ethers.Contract(tokenAddress, CURVE_ABI, App.provider)
    const minter = await crv.minter()
    const res = await getBscCurveToken(App, crv, tokenAddress, stakingAddress, minter)
    // window.localStorage.setItem(tokenAddress, 'bscCurve')
    return res
  } catch (err) {
    //console.log(err)
  }
  try {
    const erc20 = new ethers.Contract(tokenAddress, ERC20_ABI, App.provider)
    const erc20tok = await getBep20(App, erc20, tokenAddress, stakingAddress)
    window.localStorage.setItem(tokenAddress, 'erc20')
    return erc20tok
  } catch (err) {
    console.log(`Couldn't match ${tokenAddress} to any known token type.`)
  }
}

async function getBscPoolInfo(App, chefContract, chefAddress, poolIndex, pendingRewardsFunction) {
  const poolInfo = await chefContract.poolInfo(poolIndex)
  if (poolInfo.allocPoint == 0 || poolIndex == 105) {
    return {
      address: poolInfo.lpToken ?? poolInfo.token,
      allocPoints: poolInfo.allocPoint ?? 1,
      poolToken: null,
      userStaked: 0,
      pendingRewardTokens: 0,
      stakedToken: null,
      userLPStaked: 0,
      lastRewardBlock: poolInfo.lastRewardBlock,
    }
  }
  const poolToken = await getBscToken(App, poolInfo.lpToken ?? poolInfo.token, chefAddress, !!poolInfo.lpToken)
  const userInfo = await chefContract.userInfo(poolIndex, App.YOUR_ADDRESS)
  const pendingRewardTokens = await chefContract.callStatic[pendingRewardsFunction](poolIndex, App.YOUR_ADDRESS)
  const staked = userInfo.amount / 10 ** poolToken.decimals
  return {
    address: poolInfo.lpToken ?? poolInfo.token,
    allocPoints: poolInfo.allocPoint ?? 1,
    poolToken,
    userStaked: staked,
    pendingRewardTokens: pendingRewardTokens / 10 ** 18,
    depositFee: (poolInfo.depositFeeBP ?? 0) / 100,
    withdrawFee: (poolInfo.withdrawFeeBP ?? 0) / 100,
  }
}

function getParameterCaseInsensitive(object, key) {
  return object[Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase())]
}

function getErc20Prices(prices, pool, chain = 'eth') {
  let price = getParameterCaseInsensitive(prices, pool.address)?.usd
  let tvl = (pool.totalSupply * price) / 10 ** pool.decimals
  let staked_tvl = pool.staked * price
  let poolUrl
  switch (chain) {
    case 'eth':
      poolUrl = `https://etherscan.io/token/${pool.address}`
      break
    case 'bsc':
      poolUrl = `https://bscscan.com/token/${pool.address}`
      break
    case 'heco':
      poolUrl = `https://hecoinfo.com//token/${pool.address}`
      break
    case 'matic':
      poolUrl = `https://explorer-mainnet.maticvigil.com/address/${pool.address}`
      break
    case 'avax':
      poolUrl = `https://cchain.explorer.avax.network/address/${pool.address}`
      break
    case 'fantom':
      poolUrl = `https://ftmscan.com/token/${pool.address}`
      break
  }
  const name = `<a href='${poolUrl}' target='_blank'>${pool.symbol}</a>`
  return {
    staked_tvl,
    price,
    stakeTokenTicker: pool.symbol,
    print_price() {
      console.log(`${name} Price: $${displayPrice(price)} Market Cap: $${formatMoney(tvl)}`)
      console.log(`Staked: ${pool.staked.toFixed(4)} ${pool.symbol} ($${formatMoney(staked_tvl)})`)
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    print_contained_price() {},
  }
}

function getUniPrices(tokens, prices, pool) {
  let t0 = getParameterCaseInsensitive(tokens, pool.token0)
  let p0 = getParameterCaseInsensitive(prices, pool.token0)?.usd
  let t1 = getParameterCaseInsensitive(tokens, pool.token1)
  let p1 = getParameterCaseInsensitive(prices, pool.token1)?.usd
  if (p0 == null && p1 == null) {
    console.log(`Missing prices for tokens ${pool.token0} and ${pool.token1}.`)
    return undefined
  }
  if (t0?.decimals == null) {
    console.log(`Missing information for token ${pool.token0}.`)
    return undefined
  }
  if (t1?.decimals == null) {
    console.log(`Missing information for token ${pool.token1}.`)
    return undefined
  }
  let q0 = pool.q0 / 10 ** t0.decimals
  let q1 = pool.q1 / 10 ** t1.decimals
  if (p0 == null) {
    p0 = (q1 * p1) / q0
    prices[pool.token0] = { usd: p0 }
  }
  if (p1 == null) {
    p1 = (q0 * p0) / q1
    prices[pool.token1] = { usd: p1 }
  }
  let tvl = q0 * p0 + q1 * p1
  let price = tvl / pool.totalSupply
  prices[pool.address] = { usd: price }
  let staked_tvl = pool.staked * price
  let stakeTokenTicker = `[${t0.symbol}]-[${t1.symbol}]`
  if (pool.is1inch) stakeTokenTicker += ' 1INCH LP'
  else if (pool.symbol.includes('LSLP')) stakeTokenTicker += ' LSLP'
  else if (pool.symbol.includes('SLP')) stakeTokenTicker += ' SLP'
  else if (pool.symbol.includes('Cake')) stakeTokenTicker += ''
  else if (pool.name.includes('Value LP')) stakeTokenTicker += ' Value LP'
  else if (pool.symbol.includes('PGL')) stakeTokenTicker += ' PGL'
  else if (pool.symbol.includes('CS-LP')) stakeTokenTicker += ' CSS LP'
  else if (pool.symbol.includes('DFYN')) stakeTokenTicker += ' DFYN LP'
  else if (pool.symbol.includes('SPIRIT')) stakeTokenTicker += ' SPIRIT LP'
  else if (pool.symbol.includes('spLP')) stakeTokenTicker += ' SPOOKY LP'
  else if (pool.symbol.includes('Lv1')) stakeTokenTicker += ' STEAK LP'
  else if (pool.symbol.includes('PLP')) stakeTokenTicker += ' Pure Swap LP'
  else stakeTokenTicker += ' Uni LP'
  return {
    t0,
    p0,
    q0,
    t1,
    p1,
    q1,
    price,
    tvl,
    staked_tvl,
    stakeTokenTicker,
    print_price(chain = 'eth', decimals, customURLs) {
      const t0address = t0.symbol == 'ETH' ? 'ETH' : t0.address
      const t1address = t1.symbol == 'ETH' ? 'ETH' : t1.address
      if (customURLs) {
        const poolUrl = `${customURLs.info}/${pool.address}`
        const helperUrls = [
          `${customURLs.add}/${t0address}/${t1address}`,
          `${customURLs.remove}/${t0address}/${t1address}`,
          `${customURLs.swap}?inputCurrency=${t0address}&outputCurrency=${t1address}`,
        ]
        const helperHrefs =
          helperUrls.length == 0
            ? ''
            : ` <a href='${helperUrls[0]}' target='_blank'>[+]</a> <a href='${helperUrls[1]}' target='_blank'>[-]</a> <a href='${helperUrls[2]}' target='_blank'>[<=>]</a>`
        console.log(
          `<a href='${poolUrl}' target='_blank'>${stakeTokenTicker}</a>${helperHrefs} Price: $${formatMoney(
            price
          )} TVL: $${formatMoney(tvl)}`
        )
        console.log(`${t0.symbol} Price: $${displayPrice(p0)}`)
        console.log(`${t1.symbol} Price: $${displayPrice(p1)}`)
        console.log(`Staked: ${pool.staked.toFixed(decimals ?? 4)} ${pool.symbol} ($${formatMoney(staked_tvl)})`)
      } else {
        const poolUrl = pool.is1inch
          ? 'https://1inch.exchange/#/dao/pools'
          : pool.symbol.includes('LSLP')
          ? `https://info.linkswap.app/pair/${pool.address}`
          : pool.symbol.includes('SLP')
          ? `http://analytics.sushi.com/pairs/${pool.address}`
          : pool.symbol.includes('Cake')
          ? `https://pancakeswap.info/pair/${pool.address}`
          : pool.symbol.includes('PGL')
          ? `https://info.pangolin.exchange/#/pair/${pool.address}`
          : pool.symbol.includes('CS-LP')
          ? `https://app.coinswap.space/#/`
          : pool.name.includes('Value LP')
          ? `https://info.vswap.fi/pool/${pool.address}`
          : pool.symbol.includes('SPIRIT')
          ? `https://swap.spiritswap.finance/#/swap`
          : pool.symbol.includes('spLP')
          ? `https://info.spookyswap.finance/pair/${pool.address}`
          : pool.symbol.includes('Lv1')
          ? `https://info.steakhouse.finance/pair/${pool.address}`
          : pool.symbol.includes('PLP')
          ? `https://exchange.pureswap.finance/#/swap`
          : chain == 'matic'
          ? `https://info.quickswap.exchange/pair/${pool.address}`
          : `http://uniswap.info/pair/${pool.address}`
        const helperUrls = pool.is1inch
          ? []
          : pool.symbol.includes('LSLP')
          ? [
              `https://linkswap.app/#/add/${t0address}/${t1address}`,
              `https://linkswap.app/#/remove/${t0address}/${t1address}`,
              `https://linkswap.app/#/swap?inputCurrency=${t0address}&outputCurrency=${t1address}`,
            ]
          : pool.symbol.includes('Cake')
          ? [
              `https://exchange.pancakeswap.finance/#/add/${t0address}/${t1address}`,
              `https://exchange.pancakeswap.finance/#/remove/${t0address}/${t1address}`,
              `https://exchange.pancakeswap.finance/#/swap?inputCurrency=${t0address}&outputCurrency=${t1address}`,
            ]
          : pool.symbol.includes('Lv1')
          ? [
              // adding before matic
              `https://swap.steakhouse.finance/#/add/${t0address}/${t1address}`,
              `https://swap.steakhouse.finance/#/remove/${t0address}/${t1address}`,
              `https://swap.steakhouse.finance/#/swap?inputCurrency=${t0address}&outputCurrency=${t1address}`,
            ]
          : chain == 'matic'
          ? [
              `https://quickswap.exchange/#/add/${t0address}/${t1address}`,
              `https://quickswap.exchange/#/remove/${t0address}/${t1address}`,
              `https://quickswap.exchange/#/swap?inputCurrency=${t0address}&outputCurrency=${t1address}`,
            ]
          : pool.name.includes('Value LP')
          ? [
              `https://bsc.valuedefi.io/#/add/${t0address}/${t1address}`,
              `https://bsc.valuedefi.io/#/remove/${t0address}/${t1address}`,
              `https://bsc.valuedefi.io/#/swap?inputCurrency=${t0address}&outputCurrency=${t1address}`,
            ]
          : pool.symbol.includes('PGL')
          ? [
              `https://app.pangolin.exchange/#/add/${t0address}/${t1address}`,
              `https://app.pangolin.exchange/#/remove/${t0address}/${t1address}`,
              `https://app.pangolin.exchange/#/swap?inputCurrency=${t0address}&outputCurrency=${t1address}`,
            ]
          : pool.symbol.includes('CS-LP')
          ? [
              `https://app.coinswap.space/#/add/${t0address}/${t1address}`,
              `https://app.coinswap.space/#/remove/${t0address}/${t1address}`,
              `https://app.coinswap.space/#/swap?inputCurrency=${t0address}&outputCurrency=${t1address}`,
            ]
          : pool.symbol.includes('SLP')
          ? [
              `https://app.sushi.com/add/${t0address}/${t1address}`,
              `https://app.sushi.com/remove/${t0address}/${t1address}`,
              `https://app.sushi.com/swap?inputCurrency=${t0address}&outputCurrency=${t1address}`,
            ]
          : pool.symbol.includes('SPIRIT')
          ? [
              `https://swap.spiritswap.finance/add/${t0address}/${t1address}`,
              `https://swap.spiritswap.finance/remove/${t0address}/${t1address}`,
              `https://swap.spiritswap.finance/swap?inputCurrency=${t0address}&outputCurrency=${t1address}`,
            ]
          : pool.symbol.includes('spLP')
          ? [
              `https://spookyswap.finance/add/${t0address}/${t1address}`,
              `https://spookyswap.finance/remove/${t0address}/${t1address}`,
              `https://spookyswap.finance/swap?inputCurrency=${t0address}&outputCurrency=${t1address}`,
            ]
          : pool.symbol.includes('PLP')
          ? [
              `https://exchange.pureswap.finance/#/add/${t0address}/${t1address}`,
              `https://exchange.pureswap.finance/#/remove/${t0address}/${t1address}`,
              `https://exchange.pureswap.finance/#/swap?inputCurrency=${t0address}&outputCurrency=${t1address}`,
            ]
          : t0.symbol.includes('COMFI')
          ? [
              `https://app.uniswap.org/#/add/v2/${t0address}/${t1address}`,
              `https://app.uniswap.org/#/remove/v2/${t0address}/${t1address}`,
              `https://app.uniswap.org/#/swap?inputCurrency=${t0address}&outputCurrency=${t1address}`,
            ]
          : [
              `https://app.uniswap.org/#/add/${t0address}/${t1address}`,
              `https://app.uniswap.org/#/remove/${t0address}/${t1address}`,
              `https://app.uniswap.org/#/swap?inputCurrency=${t0address}&outputCurrency=${t1address}`,
            ]
        const helperHrefs =
          helperUrls.length == 0
            ? ''
            : ` <a href='${helperUrls[0]}' target='_blank'>[+]</a> <a href='${helperUrls[1]}' target='_blank'>[-]</a> <a href='${helperUrls[2]}' target='_blank'>[<=>]</a>`
        console.log(
          `<a href='${poolUrl}' target='_blank'>${stakeTokenTicker}</a>${helperHrefs} Price: $${formatMoney(
            price
          )} TVL: $${formatMoney(tvl)}`
        )
        console.log(`${t0.symbol} Price: $${displayPrice(p0)}`)
        console.log(`${t1.symbol} Price: $${displayPrice(p1)}`)
        console.log(`Staked: ${pool.staked.toFixed(decimals ?? 4)} ${pool.symbol} ($${formatMoney(staked_tvl)})`)
      }
    },
    print_contained_price(userStaked) {
      let userPct = userStaked / pool.totalSupply
      let q0user = userPct * q0
      let q1user = userPct * q1
      console.log(`Your LP tokens comprise of ${q0user.toFixed(4)} ${t0.symbol} + ${q1user.toFixed(4)} ${t1.symbol}`)
    },
  }
}

function getPoolPrices(tokens, prices, pool, chain = 'eth') {
  if (pool.w0 != null) return getValuePrices(tokens, prices, pool)
  if (pool.poolTokens != null) return getBalancerPrices(tokens, prices, pool)
  if (pool.token0 != null) return getUniPrices(tokens, prices, pool)
  if (pool.virtualPrice != null) return getCurvePrices(prices, pool) //should work for saddle too
  if (pool.token != null) return getWrapPrices(tokens, prices, pool)
  return getErc20Prices(prices, pool, chain)
}

function printChefContractLinks(
  App,
  chefAbi,
  chefAddr,
  poolIndex,
  poolAddress,
  pendingRewardsFunction,
  rewardTokenTicker,
  stakeTokenTicker,
  unstaked,
  userStaked,
  pendingRewardTokens,
  fixedDecimals,
  claimFunction,
  rewardTokenPrice,
  chain,
  depositFee,
  withdrawFee
) {
  fixedDecimals = fixedDecimals ?? 2
  const approveAndStake = async function () {
    return chefContract_stake(chefAbi, chefAddr, poolIndex, poolAddress, App)
  }
  const unstake = async function () {
    return chefContract_unstake(chefAbi, chefAddr, poolIndex, App, pendingRewardsFunction)
  }
  const claim = async function () {
    return chefContract_claim(chefAbi, chefAddr, poolIndex, App, pendingRewardsFunction, claimFunction)
  }
  if (depositFee > 0) {
    console.log(`Stake ${unstaked.toFixed(fixedDecimals)} ${stakeTokenTicker} - Fee ${depositFee}%`, approveAndStake)
  } else {
    console.log(`Stake ${unstaked.toFixed(fixedDecimals)} ${stakeTokenTicker}`, approveAndStake)
  }
  if (withdrawFee > 0) {
    console.log(`Unstake ${userStaked.toFixed(fixedDecimals)} ${stakeTokenTicker} - Fee ${withdrawFee}%`, unstake)
  } else {
    console.log(`Unstake ${userStaked.toFixed(fixedDecimals)} ${stakeTokenTicker}`, unstake)
  }
  console.log(
    `Claim ${pendingRewardTokens.toFixed(fixedDecimals)} ${rewardTokenTicker} ($${formatMoney(
      pendingRewardTokens * rewardTokenPrice
    )})`,
    claim
  )
  console.log(`Staking or unstaking also claims rewards.`)
  console.log('')
}

function printAPR(
  rewardTokenTicker,
  rewardPrice,
  poolRewardsPerWeek,
  stakeTokenTicker,
  staked_tvl,
  userStaked,
  poolTokenPrice,
  fixedDecimals
) {
  let usdPerWeek = poolRewardsPerWeek * rewardPrice
  fixedDecimals = fixedDecimals ?? 2
  console.log(
    `${rewardTokenTicker} Per Week: ${poolRewardsPerWeek.toFixed(fixedDecimals)} ($${formatMoney(usdPerWeek)})`
  )
  let weeklyAPR = (usdPerWeek / staked_tvl) * 100
  let dailyAPR = weeklyAPR / 7
  let yearlyAPR = weeklyAPR * 52
  console.log(`APR: Day ${dailyAPR.toFixed(2)}% Week ${weeklyAPR.toFixed(2)}% Year ${yearlyAPR.toFixed(2)}%`)
  let userStakedUsd = userStaked * poolTokenPrice
  let userStakedPct = (userStakedUsd / staked_tvl) * 100
  console.log(
    `You are staking ${userStaked.toFixed(fixedDecimals)} ${stakeTokenTicker} ($${formatMoney(
      userStakedUsd
    )}), ${userStakedPct.toFixed(2)}% of the pool.`
  )
  let userWeeklyRewards = (userStakedPct * poolRewardsPerWeek) / 100
  let userDailyRewards = userWeeklyRewards / 7
  let userYearlyRewards = userWeeklyRewards * 52
  if (userStaked > 0) {
    console.log(
      `Estimated ${rewardTokenTicker} earnings:` +
        ` Day ${userDailyRewards.toFixed(fixedDecimals)} ($${formatMoney(userDailyRewards * rewardPrice)})` +
        ` Week ${userWeeklyRewards.toFixed(fixedDecimals)} ($${formatMoney(userWeeklyRewards * rewardPrice)})` +
        ` Year ${userYearlyRewards.toFixed(fixedDecimals)} ($${formatMoney(userYearlyRewards * rewardPrice)})`
    )
  }
  return {
    rewardTokenTicker,
    userYearlyRewards,
    userStakedUsd,
    totalStakedUsd: staked_tvl,
    userStakedPct,
    yearlyAPR,
    userYearlyUsd: userYearlyRewards * rewardPrice,
  }
}

function printChefPool(
  App,
  chefAbi,
  chefAddr,
  prices,
  tokens,
  poolInfo,
  poolIndex,
  poolPrices,
  totalAllocPoints,
  rewardsPerWeek,
  rewardTokenTicker,
  rewardTokenAddress,
  pendingRewardsFunction,
  fixedDecimals,
  claimFunction,
  chain = 'eth',
  depositFee = 0,
  withdrawFee = 0
) {
  fixedDecimals = fixedDecimals ?? 2
  const sp = poolInfo.stakedToken == null ? null : getPoolPrices(tokens, prices, poolInfo.stakedToken)
  let poolRewardsPerWeek = (poolInfo.allocPoints / totalAllocPoints) * rewardsPerWeek
  if (poolRewardsPerWeek == 0 && rewardsPerWeek != 0) return
  const userStaked = poolInfo.userLPStaked ?? poolInfo.userStaked
  const rewardPrice = getParameterCaseInsensitive(prices, rewardTokenAddress)?.usd
  const staked_tvl = sp?.staked_tvl ?? poolPrices.staked_tvl
  console.log(`${poolIndex} - `)
  poolPrices.print_price(chain)
  sp?.print_price(chain)
  const apr = printAPR(
    rewardTokenTicker,
    rewardPrice,
    poolRewardsPerWeek,
    poolPrices.stakeTokenTicker,
    staked_tvl,
    userStaked,
    poolPrices.price,
    fixedDecimals
  )
  if (poolInfo.userLPStaked > 0) sp?.print_contained_price(userStaked)
  if (poolInfo.userStaked > 0) poolPrices.print_contained_price(userStaked)
  printChefContractLinks(
    App,
    chefAbi,
    chefAddr,
    poolIndex,
    poolInfo.address,
    pendingRewardsFunction,
    rewardTokenTicker,
    poolPrices.stakeTokenTicker,
    poolInfo.poolToken.unstaked,
    poolInfo.userStaked,
    poolInfo.pendingRewardTokens,
    fixedDecimals,
    claimFunction,
    rewardPrice,
    chain,
    depositFee,
    withdrawFee
  )
  return { ...apr, ...poolPrices }
}

const loadBscChefContract = async (
  App,
  tokens123,
  prices,
  chef,
  chefAddress,
  chefAbi,
  rewardTokenTicker,
  rewardTokenFunction,
  rewardsPerBlockFunction,
  rewardsPerWeekFixed,
  pendingRewardsFunction,
  deathPoolIndices
) => {
  const chefContract = chef ?? new ethers.Contract(chefAddress, chefAbi, App.provider)
  const poolCount = parseInt(await chefContract.poolLength(), 10)
  const totalAllocPoints = await chefContract.totalAllocPoint()
  let tokens = {}

  const rewardTokenAddress = await chefContract.callStatic[rewardTokenFunction]()
  const rewardToken = await getBscToken(App, rewardTokenAddress, chefAddress)
  const rewardsPerWeek =
    rewardsPerWeekFixed ??
    (((await chefContract.callStatic[rewardsPerBlockFunction]()) / 10 ** rewardToken.decimals) * 604800) / 3

  const poolInfos = await Promise.all(
    [...Array(poolCount).keys()].map((x) => getBscPoolInfo(App, chefContract, chefAddress, x, pendingRewardsFunction))
  )

  const tokenAddresses = flatMap(
    poolInfos.filter((x) => x.poolToken),
    (x) => x.poolToken.tokens
  )

  await Promise.all(
    tokenAddresses.map(async (address) => {
      tokens[address] = await getBscToken(App, address, chefAddress)
    })
  )

  if (deathPoolIndices) {
    //load prices for the deathpool assets
    deathPoolIndices
      .map((i) => poolInfos[i])
      .map((poolInfo) => (poolInfo.poolToken ? getPoolPrices(tokens, prices, poolInfo.poolToken, 'bsc') : undefined))
  }

  const poolPrices = poolInfos.map((poolInfo) =>
    poolInfo.poolToken ? getPoolPrices(tokens, prices, poolInfo.poolToken, 'bsc') : undefined
  )

  console.log('Finished reading smart contracts.\n')

  let aprs = []
  for (let i = 0; i < poolCount; i++) {
    if (poolPrices[i]) {
      const apr = printChefPool(
        App,
        chefAbi,
        chefAddress,
        prices,
        tokens,
        poolInfos[i],
        i,
        poolPrices[i],
        totalAllocPoints,
        rewardsPerWeek,
        rewardTokenTicker,
        rewardTokenAddress,
        pendingRewardsFunction,
        null,
        null,
        'bsc',
        poolInfos[i].depositFee,
        poolInfos[i].withdrawFee
      )
      aprs.push(apr)
    }
  }
  let totalUserStaked = 0
  let totalStaked = 0
  let averageApr = 0
  for (const a of aprs) {
    if (!Number.isNaN(a.totalStakedUsd)) {
      totalStaked += a.totalStakedUsd
    }
    if (a.userStakedUsd > 0) {
      totalUserStaked += a.userStakedUsd
      averageApr += (a.userStakedUsd * a.yearlyAPR) / 100
    }
  }
  averageApr /= totalUserStaked
  console.log(`Total Staked: $${formatMoney(totalStaked)}`)
  if (totalUserStaked > 0) {
    console.log(
      `\nYou are staking a total of $${formatMoney(totalUserStaked)} at an average APR of ${(averageApr * 100).toFixed(
        2
      )}%`
    )
    console.log(
      `Estimated earnings:` +
        ` Day $${formatMoney((totalUserStaked * averageApr) / 365)}` +
        ` Week $${formatMoney((totalUserStaked * averageApr) / 52)}` +
        ` Year $${formatMoney(totalUserStaked * averageApr)}\n`
    )
  }
  return { prices, totalUserStaked, totalStaked, averageApr, pools: aprs }
}

const main = async () => {
  const App = await init_ethers()

  console.log(`Initialized ${App.YOUR_ADDRESS}\n`)
  console.log('Reading smart contracts...\n')

  const BAMI_CHEF_ADDR = '0x40899754FEb55e0898577b0ace055d3cD1dADe22'
  const rewardTokenTicker = 'BAMI'
  const BAMI_CHEF = new ethers.Contract(BAMI_CHEF_ADDR, BAMI_CHEF_ABI, App.provider)

  const rewardsPerWeek = (((await BAMI_CHEF.bamiPerBlock()) / 1e18) * 604800) / 3

  const tokens = {}
  const prices = await getBscPrices()

  return loadBscChefContract(
    App,
    tokens,
    prices,
    BAMI_CHEF,
    BAMI_CHEF_ADDR,
    BAMI_CHEF_ABI,
    rewardTokenTicker,
    'bami',
    null,
    rewardsPerWeek,
    'pendingBami',
    [4, 5, 6, 7, 8, 9, 10]
  )

  //   hideLoading()
}

export const consoleInit = (web3Provider) => {
  walletProvider = web3Provider
  return init_wallet(main)
}
