import React, { useEffect } from 'react'
import { useliquidity } from 'state/wallet/hooks'
import { formatNumber } from 'utils/format'

function LiquidityData({ account, allV2PairsWithLiquidity, converter, setData }) {
  const { finalLiquid } = useliquidity(account, allV2PairsWithLiquidity, converter)

  useEffect(() => {
    setData(finalLiquid)
  }, [finalLiquid, setData])

  return <>{formatNumber(finalLiquid)}</>
}

export default LiquidityData
