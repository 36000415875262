export interface BridgeChain {
  chainId: number
  name: string
  symbol: string
  address?: string
  status?: BridgeChainStatus
  logoUrl?: string
}

export interface BridgeToken {
  name: string
  symbol: string
  address: {
    [key in BridgeType]: string
  }
  decimal: {
    [key in BridgeType]: number
  }
  logo: string
  onlySupportBridges?: BridgeType[]
  minimumTransfer: number
  transferNote?: string
  transferFee?: {
    [key in BridgeType]: number // language key
  }
  resultFormula?: (amount: number) => number
}

export enum BridgeType {
  ETH_TO_BSC = 'eth_to_bsc',
  BSC_TO_ETH = 'bsc_to_eth',
  BSC_TO_KAI = 'bsc_to_kai',
  KAI_TO_BSC = 'kai_to_bsc',
}
export enum BridgeChainStatus {
  UPCOMING = 'UPCOMING',
}

export const BNB_ADDRESS = '0xB8c77482e45F1F44dE1745F52C74426C631bDD52'
// export const BAMI_IN_ERC_ADDRESS = '0x6b1a29E87FBe9Aa278C7F69b74B6430fC6232ad9'
// export const BAMI_IN_BSC_ADDRESS = '0x15d2dDca697793c250191d9218C27Df53AE63C15'
export const BSC_CONTRACT_ADDRESS = '0x00b310A01182Ed7b10572173506c2ff8B270a806'
export const ETH_CONTRACT_ADDRESS = '0x00b310A01182Ed7b10572173506c2ff8B270a806'
export const BSC_KAI_CONTRACT_ADDRESS = '0x1641980b43F176942f25806Ce610FFD81B9295e1'
export const KAI_BSC_CONTRACT_ADDRESS = '0x05bdc77Fee0C927A2d7926B6877538dC15eF1B93'

export const ETH_CHAIN = 1
export const BSC_CHAIN = 56

export const supportedChains: BridgeChain[] = [
  {
    chainId: 56,
    name: 'Binance Smart Chain',
    symbol: 'BSC',
    address: BNB_ADDRESS,
  },
  // {
  //   chainId: 1,
  //   name: 'Ethereum',
  //   symbol: 'ETH',
  //   address: '',
  // },
  {
    chainId: 1,
    name: 'KardiaChain',
    symbol: 'KAI',
    address: '0xD9Ec3ff1f8be459Bb9369b4E79e9Ebcf7141C093',
  },
  // {
  //   chainId: 1,
  //   name: 'NEAR Protocol',
  //   symbol: 'NEAR',
  //   address: '',
  //   logoUrl: '/images/coins/NEAR.png',
  //   status: BridgeChainStatus.UPCOMING,
  // },
  {
    chainId: 1,
    name: 'Polygon',
    symbol: 'MATIC',
    address: '',
    logoUrl: '/images/coins/MATIC.png',
    status: BridgeChainStatus.UPCOMING,
  },
  // {
  //   chainId: 1,
  //   name: 'Optimism',
  //   symbol: 'Optimism',
  //   address: '',
  //   logoUrl: '/images/coins/OPTIMISM.png',
  //   status: BridgeChainStatus.UPCOMING,
  // },
]

export const LTDRate = 12

export const supportedTokens: BridgeToken[] = [
  {
    name: 'Tether',
    symbol: 'USDT',
    address: {
      [BridgeType.BSC_TO_ETH]: '0x55d398326f99059ff775485246999027b3197955', // USDT in BSC
      [BridgeType.BSC_TO_KAI]: '0x55d398326f99059ff775485246999027b3197955',
      [BridgeType.ETH_TO_BSC]: '0xdac17f958d2ee523a2206206994597c13d831ec7', // USDT in Ethereum
      [BridgeType.KAI_TO_BSC]: '0x92364Ec610eFa050D296f1EEB131f2139FB8810e', // USDT in KardiaChain
    },
    decimal: {
      [BridgeType.BSC_TO_ETH]: 18, // decimal in BSC
      [BridgeType.BSC_TO_KAI]: 18,
      [BridgeType.ETH_TO_BSC]: 18, // decimal in Ethereum
      [BridgeType.KAI_TO_BSC]: 6, // decimal in KardiaChain
    },
    logo: '/images/coins/USDT.png',
    minimumTransfer: 10,
    transferFee: {
      [BridgeType.BSC_TO_ETH]: 90063,
      [BridgeType.BSC_TO_KAI]: 90063,
      [BridgeType.ETH_TO_BSC]: 90063,
      [BridgeType.KAI_TO_BSC]: 90064,
    },
    resultFormula: (amount: number) => (amount - 0.5) * 0.999,
  },
  // {
  //   name: 'LiveTrade Token',
  //   symbol: 'LTD',
  //   address: {
  //     [BridgeType.BSC_TO_ETH]: '0x0f7cd24e31293b162dcf6211c6ac5bd8efcb81f4', // LTD in BSC
  //     [BridgeType.BSC_TO_KAI]: '0x0f7cd24e31293b162dcf6211c6ac5bd8efcb81f4',
  //     [BridgeType.ETH_TO_BSC]: '0x0f7cd24e31293b162dcf6211c6ac5bd8efcb81f4', // LTD in Ethereum
  //     [BridgeType.KAI_TO_BSC]: '0xf631BdC21A77AFAc69B9B3e966E85d7fBcf00b1f', // LTD in KardiaChain
  //   },
  //   decimal: {
  //     [BridgeType.BSC_TO_ETH]: 18, // decimal in BSC
  //     [BridgeType.BSC_TO_KAI]: 18,
  //     [BridgeType.ETH_TO_BSC]: 18, // decimal in Ethereum
  //     [BridgeType.KAI_TO_BSC]: 18, // decimal in KardiaChain
  //   },
  //   logo: '/images/coins/ltd.png',
  //   onlySupportBridges: [BridgeType.BSC_TO_KAI],
  //   minimumTransfer: 100,
  //   transferNote: `${LTDRate} LTD BEP20 = 1 LTD KRC20`,
  //   resultFormula: (amount: number) => Math.ceil(amount / LTDRate - 5 / 3) * 0.999,
  // },
  {
    name: 'Bami Token',
    symbol: 'BAMI',
    address: {
      [BridgeType.BSC_TO_ETH]: '0x8249bc1dea00660d2d38df126b53c6c9a733e942', // BAMI in BSC
      [BridgeType.BSC_TO_KAI]: '0x8249bc1dea00660d2d38df126b53c6c9a733e942',
      [BridgeType.ETH_TO_BSC]: '0x8249bc1dea00660d2d38df126b53c6c9a733e942',
      [BridgeType.KAI_TO_BSC]: '0xC0884cCE945fCf7F3AE4471B5A16d83065D35c31', // BAMI in KardiaChain
    },
    decimal: {
      [BridgeType.BSC_TO_ETH]: 18, // decimal in BSC
      [BridgeType.BSC_TO_KAI]: 18,
      [BridgeType.ETH_TO_BSC]: 18, // decimal in Ethereum
      [BridgeType.KAI_TO_BSC]: 18, // decimal in KardiaChain
    },
    logo: '/images/coins/BAMI.png',
    onlySupportBridges: [BridgeType.BSC_TO_KAI],
    minimumTransfer: 1000,
    resultFormula: (amount: number) => amount,
  },
]

export type BridgeOption<T> = T & {
  label: string
  value: string
}
