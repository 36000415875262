import { MenuEntry } from '@bami-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Exchange',
    icon: 'TradeIcon',
    href: '/swapnew',
  },
  {
    label: 'Liquidity',
    icon: 'TicketIcon',
    href: '/pool-new',
  },
  {
    label: 'Bridge',
    icon: 'BridgeIcon',
    href: '/bridge',
  },
  {
    label: 'Lending',
    icon: 'BankIcon',
    href: '/lending',
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://stake.bami.money/farms',
  },
  {
    label: 'Stake',
    icon: 'PoolIcon',
    href: 'https://stake.bami.money/nests',
  },
  {
    label: 'Time Lock',
    icon: 'FarmIcon',
    href: 'https://stake.bami.money/farms-old',
  },
  {
    label: '  ',
    icon: 'PoolIcon',
    href: '/',
    calloutClass: 'empty-menu-link',
  },
  {
    label: 'Docs',
    icon: 'MoreIcon',
    href: 'https://bami.gitbook.io/bami/',
  },
  {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: 'https://twitter.com/bamidefi?lang=en',
  },
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    href: 'https://t.me/bamimoney',
  },
  {
    label: 'Blog',
    icon: 'GroupsIcon',
    href: 'https://blog.bami.money/',
  },
]

export default config
