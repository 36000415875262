/* eslint-disable import/prefer-default-export */

export const formatNumber = (raw: number | string, fraction = 2) => {
  let input
  if (typeof raw === 'string') {
    try {
      input = parseFloat(raw) || 0
    } catch (e) {
      input = 0
    }
  } else {
    input = raw
  }
  const result = new Intl.NumberFormat('en-US', { maximumFractionDigits: fraction }).format(input)
  return result === 'NaN' || !result ? 0 : result
}

export const LIST_TOKEN_PRIORITY = ['LTD', 'BTCB', 'ETH', 'BAMI', 'VIDB', 'USDT', 'USDC', 'BUSD', 'VNDC', 'OLA', 'CAKE']
export const sortTokenComparation = (x: any, y: any) => {
  const indexA = LIST_TOKEN_PRIORITY.indexOf(x.symbol) !== -1 ? LIST_TOKEN_PRIORITY.indexOf(x.symbol) : Number.MAX_VALUE
  const indexB = LIST_TOKEN_PRIORITY.indexOf(y.symbol) !== -1 ? LIST_TOKEN_PRIORITY.indexOf(y.symbol) : Number.MAX_VALUE
  return indexA - indexB
}
export const sortTokens = (tokens: any[]) => {
  const clone = [...tokens]

  clone.sort(sortTokenComparation)

  return clone
}

export const generateAPYfromRatePerBlock = (ratePerBlock) => {
  const ethMantissa = 1e18
  const blocksPerDay = 28800
  const daysPerYear = 365

  return (((ratePerBlock / ethMantissa) * blocksPerDay + 1) ** daysPerYear - 1) * 100
}
