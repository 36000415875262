/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Currency, JSBI, Pair } from '@pancakeswap-libs/sdk'
import CurrencyLogo from 'components/CurrencyLogo'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { useTotalSupply } from 'data/TotalSupply'
import { useActiveWeb3React } from 'hooks'
import React, { useState } from 'react'
import { useTokenBalance } from 'state/wallet/hooks'
import styled from 'styled-components'
import { unwrappedToken } from 'utils/wrappedCurrency'
import downIcon from '../../assets/svg/down-arrow.svg'

interface PositionCardProps {
  pair: Pair
  converter: any
  // eslint-disable-next-line react/no-unused-prop-types
}

const ExpandIcon = styled.img`
  marginleft: 8px;
  cursor: pointer;
`

const ExpandedIcon = styled.img`
  cursor: pointer;
  marginleft: 8px;
  transform: rotate(180deg);
`

function LiquidityPoolsDetail({ pair, converter }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0: Currency = unwrappedToken(pair.token0)
  const currency1: Currency = unwrappedToken(pair.token1)
  const [showMore, setShowMore] = useState(false)
  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)
  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div className="details-row" onClick={() => setShowMore(!showMore)}>
        <h5 className="row-data">
          <div style={{ width: '45px' }}>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={30} />
          </div>
          {`${currency0.symbol}/${currency1.symbol}`}
        </h5>
        <h5 className="row-data" style={{ paddingLeft: 10 }}>
          $
          {(
            (token0Deposited && currency0 && currency0.symbol
              ? parseFloat(token0Deposited.toSignificant(6)) * parseFloat(converter[currency0.symbol])
              : 0) +
            (token1Deposited && currency1.symbol
              ? parseFloat(token1Deposited.toSignificant(6)) * parseFloat(converter[currency1.symbol])
              : 0)
          ).toFixed(2)}
        </h5>
        {showMore ? (
          <ExpandedIcon style={{ width: 16, marginLeft: 8 }} src={downIcon} alt="expand" />
        ) : (
          <ExpandIcon style={{ width: 16, marginLeft: 8 }} src={downIcon} alt="expand" />
        )}
      </div>
      {showMore && (
        <>
          <div
            className="details-row fade-in"
            style={{ borderTop: '1px dashed #ddd', borderBottom: '1px dashed #ddd' }}
          >
            <h5 className="row-data">
              <div style={{ width: '45px', position: 'relative', left: 10 }}>
                <CurrencyLogo currency={currency0} size="30px" />
              </div>
              {currency0.symbol}
            </h5>
            <h5 className="row-data">
              $
              {(token0Deposited && currency0 && currency0.symbol
                ? parseFloat(token0Deposited.toSignificant(6)) * parseFloat(converter[currency0.symbol])
                : 0
              ).toFixed(2)}
            </h5>
          </div>
          <div className="details-row fade-in" style={{ borderBottom: '1px dashed #ddd' }}>
            <h5 className="row-data">
              <div style={{ width: '45px', position: 'relative', left: 10 }}>
                <CurrencyLogo currency={currency1} size="30px" />
              </div>
              {currency1.symbol}
            </h5>
            <h5 className="row-data">
              $
              {(token1Deposited && currency1 && currency1.symbol
                ? parseFloat(token1Deposited.toSignificant(6)) * parseFloat(converter[currency1.symbol])
                : 0
              ).toFixed(2)}
            </h5>
          </div>
        </>
      )}
      <div style={{ height: 25 }} />
    </>
  )
}

export default LiquidityPoolsDetail
