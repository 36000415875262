import ConnectWalletButton from 'components/ConnectWalletButtonNew'
import Sponsor from 'components/Sponsor/Sponsor'
import TranslatedText from 'components/TranslatedText'
import { useActiveWeb3React } from 'hooks'
import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import * as SponsorLogos from '../../assets/svg/logo'

const HomePage = styled.div`
  width: 100%;
  min-height: calc(100vh - 64px);
  position: relative;

  @media (max-width: 630px) {
    width: calc(100vw - 35px);
  }
`

const HomeContainer = styled.div`
  width: 971px;
  margin: 20px auto 20px auto;

  @media (max-width: 992px) {
    width: 100%;
    margin: 40px auto 20px auto;
  }
`

const HomeHeader = styled.h5`
  font-size: 1.8em;
  font-weight: 100;
  color: #4e598c;
  margin-bottom: 20px;

  & > span {
    font-size: 1.8em;
    font-weight: 900;
    color: coral;
  }

  @media (max-width: 800px) {
    margin-bottom: 40px;
  }

  @media (max-width: 690px) {
    margin-bottom: 20px;
  }

  @media (max-width: 360px) {
    font-size: 1.6em;
  }
`

const HomeHeadline = styled.h5`
  font-size: 1.2em;
  font-weight: 100;
  color: #4e598c;
  margin-bottom: 24px;

  @media (max-width: 800px) {
    margin-bottom: 40px;
  }

  @media (max-width: 690px) {
    margin-bottom: 20px;
  }
`

const DataEnterComps = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 690px) {
    flex-wrap: wrap;
  }
`

const DomainInput = styled.input`
  border: none;
  outline: none;
  width: 350px;
  padding: 8px 12px;
  border-radius: 4px;
  background: ${(props) => (props.theme.isDark ? 'rgba(78, 89, 140, .2)' : '#dbdae1')};
  font-size: 0.9em;
  margin-right: 24px;

  &::placeholder {
    color: rgba(78, 89, 140, 1);
  }

  @media (max-width: 690px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
`

const BrandsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0;
  width: 85%;

  @media (max-width: 992px) {
    width: 100%;
    margin: 50px 0;
  }

  & > img {
    width: auto;
    height: 90px;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  @media (max-width: 690px) {
    & > img {
      // width: auto;
      height: auto;
      margin-right: 20px;
      // margin-bottom: 20px;
      // cursor: pointer;
      flex: 0 0 calc(25% - 20px);
      max-width: calc(25% - 20px);
    }
  }
`

function Home() {
  const history = useHistory()
  const { account } = useActiveWeb3React()

  React.useEffect(() => {
    if (account) {
      history.push('/home-connected')
    }
  }, [history, account])

  return (
    <HomePage>
      <HomeContainer>
        <HomeHeader>
          <TranslatedText translationId={675}>Manage your</TranslatedText> <span>DeFi</span>{' '}
          <TranslatedText translationId={676}>assets and liabilities in simple Interface</TranslatedText>
        </HomeHeader>
        <HomeHeadline>
          <TranslatedText translationId={677}>The one dashboard you will ever need</TranslatedText>
        </HomeHeadline>
        <DataEnterComps>
          <ConnectWalletButton />
        </DataEnterComps>
        <BrandsContainer>
          {Object.entries(SponsorLogos).map(([key, o]) => {
            return (
              // @ts-ignore
              <Sponsor key={key} src={o} />
            )
          })}
        </BrandsContainer>
      </HomeContainer>
    </HomePage>
  )
}

export default Home
