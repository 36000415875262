import { Interface } from '@ethersproject/abi'
import LENDINGCBEP20 from 'constants/abis/cBep20.json'
import ERC20_ABI from './erc20.json'
import ERC20_BYTES32_ABI from './erc20_bytes32.json'
import LENDING_CONTROLLER_ABI from './lending-controller.json'
import ORACLE_ABI from './oracle.json'

const ERC20_INTERFACE = new Interface(ERC20_ABI)

const BEP20_CTOKEN_INTERFACE = new Interface(LENDINGCBEP20)
const LENDING_CONTROLLER_INTERFACE = new Interface(LENDING_CONTROLLER_ABI)
const ORACLE_INTERFACE = new Interface(ORACLE_ABI)

const ERC20_BYTES32_INTERFACE = new Interface(ERC20_BYTES32_ABI)

export default ERC20_INTERFACE
export {
  ERC20_ABI,
  ERC20_BYTES32_INTERFACE,
  ERC20_BYTES32_ABI,
  BEP20_CTOKEN_INTERFACE,
  LENDING_CONTROLLER_INTERFACE,
  ORACLE_INTERFACE,
}
