import React, { useEffect, useState } from 'react'
import { Button, Flex, useWalletModal } from '@bami-libs/uikit'
import { MaxUint256 } from '@ethersproject/constants'
import BigNumber from 'bignumber.js'

import CurrencyLogo from 'components/CurrencyLogo'
import Modal from 'components/Modal'
import { useActiveWeb3React } from 'hooks'
import useAuth from 'hooks/useAuth'
import { formatNumber } from 'utils/format'
import LENDINGCBEP20 from 'constants/abis/cBep20.json'
import { useLendingContract, useTokenContract } from 'hooks/useContract'
import { useSingleCallResult } from 'state/multicall/hooks'
import LENDINGCBNB from 'constants/abis/cBNB.json'
import useI18n from 'hooks/useI18n'

import { ModalBody, ModalHeader } from './styled'

const BorrowModal = ({
  isOpen,
  onDismiss,
  token,
  apy,
  borrowBalance,
  availableLiquidity,
  borrowLimit,
  totalBorrowBalances,
  walletBalance: rawWalletBalance,
  price = 0,
}) => {
  const TranslateString = useI18n()
  let err
  const { login, logout } = useAuth()
  const { account } = useActiveWeb3React()
  const { onPresentConnectModal } = useWalletModal(login, logout)
  const [loading, setLoading] = useState(false)
  const isBNB = !token.address
  const [approved, setApproved] = useState<boolean | undefined>(isBNB)
  const [tab, setTab] = useState<'borrow' | 'repay'>('borrow')
  const balance = tab === 'borrow' ? formatNumber(availableLiquidity, 18) : formatNumber(borrowBalance, 18)
  const [amount, setAmount] = useState<number | string>()
  const currentBorrowLimitPercent = (totalBorrowBalances / borrowLimit) * 100
  const nextBorrowLimitPercent = amount
    ? tab === 'borrow'
      ? ((totalBorrowBalances + +amount * price) / borrowLimit) * 100
      : ((totalBorrowBalances - +amount * price) / borrowLimit) * 100
    : currentBorrowLimitPercent
  const { cToken } = token.tokenInfo
  const tokenDecimals = token.decimals
  const { address: cTokenAddress } = cToken
  const cTokenContract = useLendingContract(cTokenAddress, LENDINGCBEP20)
  const cBNBContract = useLendingContract(cTokenAddress, LENDINGCBNB)
  const walletBalance = new BigNumber(rawWalletBalance)
  const tokenContract = useTokenContract(token.address, true)
  const accountAllowance = useSingleCallResult(tokenContract, 'allowance', [account, cTokenAddress]).result

  useEffect(() => {
    setAmount('')
  }, [tab])

  useEffect(() => {
    if (accountAllowance && !accountAllowance[0]?.isZero()) {
      setApproved(true)
    }
  }, [accountAllowance])

  if (tab === 'borrow') {
    if (nextBorrowLimitPercent > 100) {
      err = TranslateString(90103, 'Insufficient Collateral')
    } else if (amount && borrowLimit === 0) {
      err = TranslateString(90103, 'Insufficient Collateral')
    } else if (amount && +amount > availableLiquidity) {
      err = TranslateString(90104, 'Insufficient Available Liquidity')
    }
  } else if (tab === 'repay') {
    if (amount && walletBalance.isLessThan(amount)) {
      err = TranslateString(90105, 'Insufficient Balance')
    } else if (amount && borrowBalance < amount) {
      err = TranslateString(90102, "Amount musn't be greater than Borrow Balance")
    }
  }

  const handleApprove = async () => {
    try {
      setLoading(true)
      const tx = await tokenContract?.approve(cTokenAddress, MaxUint256, {
        gasLimit: 100000,
      })
      await tx.wait()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleBorrow = async () => {
    if (!amount) {
      return
    }
    try {
      setLoading(true)
      const input = new BigNumber(10)
        .pow(tokenDecimals)
        .multipliedBy(+amount)
        .toFixed(0)
      const tx = await cTokenContract?.borrow(input)
      const res = await tx.wait()
      console.log('Event Status:', res.events?.[0]?.event, input)
      setLoading(false)
      setAmount('')
    } catch (error) {
      setLoading(false)
      console.log('err', error)
    }
  }

  const handleRepay = async () => {
    if (!amount) {
      return
    }
    try {
      setLoading(true)
      let input = new BigNumber(10)
        .pow(tokenDecimals)
        .multipliedBy(+amount)
        .toFixed(0)
      const isRepayAll = amount === borrowBalance

      if (isRepayAll && !isBNB) {
        input = new BigNumber(2).pow(256).minus(1).toFixed(0)
      }

      const tx = isBNB
        ? await cBNBContract?.repayBorrow({
            value: input,
            from: account,
          })
        : await cTokenContract?.repayBorrow(input)
      await tx.wait()
      setLoading(false)
      setAmount('')
    } catch (error) {
      setLoading(false)
      console.log('err', error)
    }
  }

  const handleMax = () => {
    const maxSafePercent = 0.8
    const maxSafe = Math.min((borrowLimit * maxSafePercent - totalBorrowBalances) / (price * 1.0), availableLiquidity)
    setAmount(tab === 'borrow' ? maxSafe || 0 : borrowBalance || 0)
  }

  const generateSubmitButton = () => {
    if (loading) {
      return <Button disabled>Loading...</Button>
    }

    if (!account) {
      return <Button onClick={onPresentConnectModal}>Connect Wallet</Button>
    }

    if (tab === 'borrow') {
      return <Button disabled>{TranslateString(90106, 'Borrow')}</Button>
    }

    if (approved) {
      return (
        <Button disabled={!amount || err} onClick={handleRepay}>
          {TranslateString(90107, 'Repay')}
        </Button>
      )
    }

    return <Button onClick={handleApprove}>Approve</Button>
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={80}>
      <div style={{ width: '100%' }}>
        <ModalHeader>
          <Button
            onClick={() => setTab('borrow')}
            variant="text"
            className={tab === 'borrow' ? 'secondary selected' : 'secondary'}
          >
            {TranslateString(90106, 'Borrow')}
          </Button>
          <div className="currency">
            <CurrencyLogo currency={token} size="4rem" />
            <div className={tab === 'borrow' ? 'symbol secondary' : 'symbol primary'}>{token.symbol}</div>
          </div>
          <Button
            onClick={() => setTab('repay')}
            variant="text"
            className={tab === 'repay' ? 'primary selected' : 'primary'}
          >
            {TranslateString(90107, 'Repay')}
          </Button>
        </ModalHeader>
        <ModalBody>
          <div className={tab === 'borrow' ? 'secondary' : 'primary'}>
            <Flex justifyContent="space-between" alignItems="flex-end">
              <div className="label">
                {tab === 'borrow' ? TranslateString(90108, 'Borrow Amount') : TranslateString(90109, 'Repay Amount')}
              </div>
              <div className="top-balance">
                <span style={{ marginRight: '0.25rem' }} className="title">
                  {tab === 'borrow'
                    ? TranslateString(90110, 'Available Liquidity:')
                    : TranslateString(90111, 'Borrow Balance:')}
                </span>
                <span className="highlight">
                  {balance} {token.symbol}
                </span>
              </div>
            </Flex>

            <Flex className="input-wrapper" alignItems="center">
              <input
                disabled={loading}
                value={amount}
                type="number"
                placeholder="0.00"
                onChange={(e) => setAmount(parseFloat(e.target.value))}
              />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                {token.symbol}
                <button type="button" className="max-btn" onClick={handleMax}>
                  MAX
                </button>
              </div>
            </Flex>
            {!!err && <div style={{ marginBottom: '2rem', textAlign: 'center', color: 'red' }}>{err}</div>}

            <div style={{ marginBottom: '2rem' }}>
              <div style={{ marginBottom: '0.5rem' }} className="label">
                {TranslateString(90112, 'Borrow Stats')}
              </div>
              <Flex style={{ marginBottom: '0.25rem' }} justifyContent="space-between" alignItems="flex-end">
                <div className="title">{TranslateString(90074, 'Borrow APY')}</div>
                <div className="title">{formatNumber(apy, 4)}%</div>
              </Flex>
              <Flex style={{ marginBottom: '0.25rem' }} justifyContent="space-between" alignItems="flex-end">
                <div className="title">{TranslateString(90069, 'Borrow Balance')}</div>
                <div className="title">
                  {formatNumber(borrowBalance, 18)} {token.symbol}
                </div>
              </Flex>
            </div>

            <div style={{ marginBottom: '2rem' }}>
              <div style={{ marginBottom: '0.5rem' }} className="label">
                {TranslateString(90081, 'BORROW LIMIT')?.toUpperCase()}
              </div>
              <Flex style={{ marginBottom: '0.25rem' }} justifyContent="space-between" alignItems="flex-end">
                <div className="title">{TranslateString(90095, 'Your Borrow Limit')}</div>
                <div className="title">${formatNumber(borrowLimit)}</div>
              </Flex>
              <Flex style={{ marginBottom: '0.25rem' }} justifyContent="space-between" alignItems="flex-end">
                <div className="title">{TranslateString(90096, 'Borrow Limit Used')}</div>
                <div className="title">
                  {formatNumber(currentBorrowLimitPercent)}% -{'>'} {formatNumber(nextBorrowLimitPercent)}%
                </div>
              </Flex>
            </div>

            <Flex justifyContent="center" alignItems="center">
              {generateSubmitButton()}
            </Flex>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default BorrowModal
