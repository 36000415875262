import { Pair } from '@pancakeswap-libs/sdk'
import CurrencyLogo from 'components/CurrencyLogo'
import { usePairs } from 'data/Reserves'
import { useActiveWeb3React } from 'hooks'
import React, { useMemo, useState } from 'react'
import { useDerivedSwapInfo } from 'state/swap/hooks'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { useCurrencyBalance, useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import styled from 'styled-components'
import binanceLogo from '../../assets/images/binance-logo.png'
import closeIcon from '../../assets/images/closeIcon.svg'
import LiquidityShowDetail from './LiquidityShowDetail'

const DetailsPopupComp = styled.div`
  width: 1200px;
  margin: 0 auto;

  @media (max-width: 1300px) {
    width: 100%;
  }

  .dash-back-btn {
    border: none;
    outline: none;
    background: none;
    font-size: 1.4em;
    color: #4e598c;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .details-modal {
    width: 85%;
    border: 1px solid #dfe8f9;
    border-radius: 4px;
    height: auto;
    position: relative;
    box-sizing: border-box;
    background: #fff;

    @media (max-width: 1300px) {
      width: 100%;
    }

    @media (max-width: 600px) {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    h4.detail-title {
      margin: 0 0 4px;
      font-weight: 400;
      font-size: 1.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #dfe8f9;

      button {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        img {
          width: 20px;
        }
      }
    }

    .detail-body {
      width: 100%;

      @media (max-width: 900px) {
        width: auto;
      }
      h5 {
        font-size: 1.25em;
        font-weight: 500;
        color: #4e598c;
        margin-top: 24px;
      }

      .detail-table {
        .details-head {
          padding: 20px 10px;
          border-bottom: 1px solid #dfe8f9;
        }

        .details-row {
          padding: 25px 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .details-row,
        .details-head {
          display: flex;
          border-bottom: 1px solid #dfe8f9;
          align-items: center;

          h5.row-data {
            box-sizing: border-box;
            flex: 60 0 auto;
            min-width: 60px;
            width: 60px;
            cursor: pointer;
            margin: 0;
            // border: 1px solid #aaa;
            font-size: 1.2em;
            font-weight: 500;
            color: #002237;
            display: flex;
            align-items: center;

            img {
              width: 30px;
              margin-right: 10px;
            }

            & > div {
              margin-right: 10px;
              img {
                margin: 0;
                position: absolute;
                transform: translateY(-50%);

                &:first-child {
                  left: 0;
                  z-index: 20;
                }

                &:last-child {
                  right: 0;
                }
              }
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              flex: 30 0 auto;
              min-width: 30px;
              width: 30px;
            }

            &:nth-child(4) {
            }

            &:nth-child(3) {
              @media (max-width: 600px) {
                display: none;
              }
            }
          }
        }

        .details-row {
          &:last-child {
            border: none;
          }
          h5.row-data {
            font-weight: 400;
            color: #4e598c;
            display: flex;
            align-items: center;

            div.double-img_container {
              position: relative;

              img {
                width: 30px;
                height: auto;
                position: absolute;
                top: 0;

                &:first-child {
                  left: 0;
                }
                &:last-child {
                  right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`

function LiquidityShow(props) {
  const { balances, converter, ETHER, total, detailsPop } = props
  const { account } = useActiveWeb3React()
  const trackedTokenPairs = useTrackedTokenPairs()
  const { currencies } = useDerivedSwapInfo()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currencies.INPUT ?? undefined)

  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens,
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )
  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))
  console.log('props.state', props)
  return (
    <DetailsPopupComp>
      <button
        type="button"
        className="dash-back-btn"
        onClick={() => {
          props.setLiquidityShow(false)
        }}
      >
        <i className="fa fa-arrow-left" aria-hidden="true" />
      </button>
      <div className="details-modal">
        <div className="detail-body">
          <div className="detail-table">
            <div className="details-head">
              <h5 className="row-data">Assets</h5>
              <h5 className="row-data">Balance</h5>
              <h5 className="row-data">Pool Share</h5>
              <h5 className="row-data">Value</h5>
            </div>
            {allV2PairsWithLiquidity.map((v2Pair) => {
              return <LiquidityShowDetail pair={v2Pair} converter={converter} />
            })}
          </div>
        </div>
      </div>
    </DetailsPopupComp>
  )
}

export default LiquidityShow
