import axios from 'axios'
import CurrencyLogo from 'components/CurrencyLogo'
import TranslatedText from 'components/TranslatedText'
import { useActiveWeb3React } from 'hooks'
import { useAllTokens, useToken } from 'hooks/Tokens'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { FixedSizeList } from 'react-window'
import { usePrices } from 'state/price/hooks'
import styled, { ThemeContext } from 'styled-components'
import { isAddress } from 'utils'
import { formatNumber, sortTokens } from 'utils/format'
import { filterTokens } from '../../components/SearchModal/filtering'
import { useTokenComparator } from '../../components/SearchModal/sorting'
import useI18n from '../../hooks/useI18n'
import { AppState } from '../../state'

const SwapPage = styled.div`
  width: 100%;
  min-height: calc(100vh - 64px);
  position: relative;

  @media (max-width: 630px) {
    width: calc(100vw - 35px);
  }
`

const SwapContainer = styled.div`
  width: 850px;
  margin: 0px auto;

  @media (max-width: 1150px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    margin: 0px auto 20px auto;
  }
`

const SwapHeader = styled.h5`
  font-size: 1.6em;
  font-weight: 100;
  color: #4e598c;
  margin-bottom: 10px;
  color: #ff8c42;
`

const SwapHeadline = styled.h5`
  font-size: 1em;
  font-weight: 100;
  color: #4e598c;
  margin-bottom: 24px;
  color: rgba(78, 89, 140, 0.5);
`

const TradeInputContainer = styled.div`
    width: 600px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    background: ${(props) => (props.theme.isDark ? 'rgba(78, 89, 140,.2)' : '#dbdae1')};
    overflow: hidden;
    padding: 8px 14px;
    margin-bottom: 20px;

    input{
        flex: 0 0 83%;
        max-width: 83%;
        background: none;
        border: none;
        outline: none;
        font-size: .9em;

        @media(max-width: 620px){
            flex: 0 0 82%;
            max-width: 82%;
        }

        @media(max-width: 576px){
            flex: 0 0 calc(80% - 10px);
            max-width: calc(80% - 10px);
            font-size: .8em;
            padding-left: 10px;
        }
    }

    button{
        border: none;
        outline: none;
        background linear-gradient(180deg, #F7B267 0%, #F79D65 24.7%, #F4845F 51%, #F27059 74.5%, #F25C54 100%);
        color: #fff;
        border-radius: 16px;
        padding: 10px 30px;
        font-size: .8em;
        font-weight: 400;
        margin-left: 10px;

        @media(max-width: 576px){
            flex: 0 0 20%;
            max-width: 20%;
            font-size: .8em;
            padding: 5px 0;
        }
    }

    @media(max-width: 660px){
        width: 100%;
    }

    @media(max-width: 576px){
        padding: 3px;
    }
`

const FeaturedTokenContainer = styled.div``

const FeaturedTokenHeader = styled.h5`
  font-size: 1.2em;
  font-weight: 100;
  color: #4e598c;
  margin-bottom: 20px;
  color: #ff8c42;
`

const TokensTable = styled.div``

const TableHeader = styled.div`
  width: 100%;
  padding: 16px 5px;
  border-radius: 30px;
  background: ${(props) => (props.theme.isDark ? 'rgba(255, 140, 66,.1)' : '#fff0e4')};
  color: ${(props) => (props.theme.isDark ? '#fff' : '#4e598c')};
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .token-and-symbol {
    flex: 0 0 37%;
    max-width: 37%;
    display: flex;
    align-items: center;

    & > div:first-child {
      width: 30px;
      margin-right: 20px;
    }

    & > div:last-child {
      display: flex;
      align-items: center;
      flex: 0 0 calc(100% - 50px);
      max-width: calc(100% - 50px);

      .token {
        flex: 0 0 50%;
        max-width: 50%;
        font-size: 0.8em;
        font-weight: 100;
        display: flex;
        align-items: center;
      }

      .symbol {
        flex: 0 0 50%;
        max-width: 50%;
        font-size: 0.8em;
        font-weight: 100;
        padding-left: 20px;
      }

      @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;

        & > * {
          flex: 0 0 100%;
          max-width: 100%;
        }

        & > h5:last-child {
          display: none;
        }
      }
    }

    @media (max-width: 700px) {
      flex: 0 0 30%;
      max-width: 30%;
    }

    @media (max-width: 576px) {
      flex: 0 0 45%;
      max-width: 45%;
    }
  }

  .price-and-percent {
    flex: 0 0 22%;
    max-width: 22%;
    display: flex;
    align-items: center;

    h5 {
      flex: 0 0 50%;
      max-width: 50%;
      font-size: 0.8em;
      font-weight: 100;
    }

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;

      & > * {
        flex: 0 0 100%;
        max-width: 100%;
      }

      & > h5:first-child {
        display: none;
      }
    }

    @media (max-width: 576px) {
      flex: 1;
      max-width: none;
      align-items: flex-end;
      padding-right: 10px;
    }
  }

  & > h5 {
    flex: 0 0 11%;
    max-width: 11%;
    font-size: 0.8em;
    font-weight: 100;

    @media (max-width: 700px) {
      flex: 0 0 15%;
      max-width: 15%;
    }

    @media (max-width: 576px) {
      display: none;
    }
  }

  & > .graph_container {
    flex: 0 0 18%;
    max-width: 18%;

    @media (max-width: 800px) {
      display: none;
    }

    @media (max-width: 576px) {
      display: none;
      flex: 0 0 15%;
      max-width: 15%;
    }
  }
`

const TableRows = styled.div`
  & > button {
    width: 100%;
    padding: 10px 5px;
    border-radius: 10px;
    border: none;
    outline: none;
    color: ${(props) => (props.theme.isDark ? '#fff' : '#4e598c')};
    display: flex;
    align-items: center;

    h5 {
      text-align: left;
    }

    &:nth-child(2n + 1) {
      background: ${(props) => (props.theme.isDark ? 'rgba(255, 251, 247, .05)' : 'none')};
    }

    &:nth-child(2n + 2) {
      background: ${(props) => (props.theme.isDark ? 'rgba(78, 89, 140,.1)' : '#edebec')};
    }

    .token-and-symbol {
      flex: 0 0 37%;
      max-width: 37%;
      display: flex;
      align-items: center;

      & > img {
        width: 30px;
        height: auto;
        margin-right: 20px;
      }

      & > div {
        display: flex;
        align-items: center;
        flex: 0 0 calc(100% - 50px);
        max-width: calc(100% - 50px);

        .token {
          flex: 0 0 50%;
          max-width: 50%;
          font-size: 1em;
          font-weight: 100;
          display: flex;
          align-items: center;

          @media (max-width: 576px) {
            font-size: 0.8em;
          }
        }

        .symbol {
          flex: 0 0 50%;
          max-width: 50%;
          font-size: 1em;
          font-weight: 100;
          text-align: left;
          padding-left: 20px;

          @media (max-width: 800px) {
            padding: 0;
          }

          @media (max-width: 576px) {
            font-size: 0.8em;
          }
        }

        @media (max-width: 800px) {
          flex-direction: column;
          align-items: flex-start;

          & > h5 {
            flex: 0 0 100% !important;
            max-width: 100% !important;
          }
        }
      }

      @media (max-width: 700px) {
        flex: 0 0 30%;
        max-width: 30%;
      }

      @media (max-width: 576px) {
        flex: 0 0 45%;
        max-width: 45%;
        order: 1;
      }
    }

    .price-and-percent {
      flex: 0 0 22%;
      max-width: 22%;
      display: flex;
      align-items: center;

      h5 {
        flex: 0 0 50%;
        max-width: 50%;
        font-size: 1em;
        font-weight: 100;
        text-align: left;

        @media (max-width: 576px) {
          font-size: 0.8em;
          flex: 1;
          max-width: none;
        }
      }

      @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
        flex: 0 0 35%;
        max-width: 35%;

        & > * {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      @media (max-width: 576px) {
        flex: 1;
        order: 3;
        max-width: none;
        align-items: flex-end;
        padding-right: 10px;
      }
    }

    & > h5 {
      flex: 0 0 11%;
      max-width: 11%;
      font-size: 1em;
      font-weight: 100;

      @media (max-width: 700px) {
        flex: 0 0 15%;
        max-width: 15%;
      }

      @media (max-width: 576px) {
        display: none;
        font-size: 0.8em;
      }
    }

    & > .graph_container {
      flex: 0 0 18%;
      max-width: 18%;

      @media (max-width: 576px) {
        flex: 0 0 27%;
        max-width: 27%;
        order: 2;
        margin-left: -10px;
        margin-right: 10px;
      }
    }
  }
`

function SwapNew() {
  const history = useHistory()
  const TranslateString = useI18n()
  const [data, setData] = useState<any>([])
  const [allTokensData, setAllTokensData] = useState<any>([])
  const tokenPrices = usePrices()

  const fixedList = useRef<FixedSizeList>()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [invertSearchOrder, setInvertSearchOrder] = useState<boolean>(false)
  const allTokens = useAllTokens()

  // if they input an address, use it
  const isAddressSearch = isAddress(searchQuery)
  const searchToken = useToken(searchQuery)

  const tokenComparator = useTokenComparator(invertSearchOrder)
  const filteredTokens: any = useMemo(() => {
    if (isAddressSearch) return searchToken ? [searchToken] : []
    return filterTokens(Object.values(allTokens), searchQuery)
  }, [isAddressSearch, searchToken, allTokens, searchQuery])

  const filteredSortedTokens: any[] = useMemo(() => {
    if (searchToken) return [searchToken]
    const sorted = filteredTokens.sort(tokenComparator)
    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0)
    if (symbolMatch.length > 1) return sorted

    return [
      ...(searchToken ? [searchToken] : []),
      // sort any exact symbol matches first
      ...sorted.filter((token) => token.symbol?.toLowerCase() === symbolMatch[0]),
      ...sorted.filter((token) => token.symbol?.toLowerCase() !== symbolMatch[0]),
    ]
  }, [filteredTokens, searchQuery, searchToken, tokenComparator])

  const handleInput = useCallback((event) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    fixedList.current?.scrollTo(0)
  }, [])

  useEffect(() => {
    setData(sortTokens(filteredSortedTokens))
  }, [filteredSortedTokens])

  useEffect(() => {
    Promise.all([
      axios.get(
        'https://api.coingecko.com/api/v3/coins/markets?vs_currency=USD&order=market_cap_desc&per_page=100&page=1&sparkline=false'
      ),
      axios.get(
        'https://api.coingecko.com/api/v3/coins/markets?vs_currency=USD&order=market_cap_desc&per_page=1&page=1&sparkline=false&ids=vndc'
      ),
    ]).then((arrRes) => {
      // @ts-ignore
      const newData = arrRes.reduce((res, cur) => {
        if (cur.status === 200) {
          return [...res, ...cur.data]
        }

        return res
      }, [])
      setAllTokensData(newData)
    })
  }, [])

  return (
    <SwapPage>
      <SwapContainer>
        <SwapHeader>
          <TranslatedText translationId={152}>Select a token</TranslatedText>
        </SwapHeader>
        <SwapHeadline>
          <TranslatedText translationId={130}>
            Please connect to the appropriate Binance Smart Chain network.
          </TranslatedText>
        </SwapHeadline>
        <TradeInputContainer>
          <input
            type="text"
            placeholder={TranslateString(126, 'Search name or paste address')}
            onChange={handleInput}
          />
          <button type="button">Trade</button>
        </TradeInputContainer>
        <FeaturedTokenContainer>
          <FeaturedTokenHeader>Featured Tokens</FeaturedTokenHeader>
          <TokensTable>
            <TableHeader>
              <div className="token-and-symbol">
                <div />
                <div>
                  <h5 className="token">Token</h5>
                  <h5 className="symbol">Symbol</h5>
                </div>
              </div>
              <div className="price-and-percent">
                <h5>Price</h5>
                <h5>Last 24h</h5>
              </div>
              <h5>Volume</h5>
              <div className="graph_container" />
            </TableHeader>
            <TableRows>
              {data && data.length
                ? data.map((token) => {
                    const priceData = allTokensData.filter((val) => {
                      return val.symbol.toUpperCase() === token.symbol
                    })
                    const { name, symbol } = token
                    let price = tokenPrices[token.symbol]
                      ? `$${parseFloat(tokenPrices[token.symbol]?.toString()).toFixed(2)}`
                      : 'N/A'
                    if ((symbol === 'VNDC' || symbol === 'SFO') && price !== 'N/A') {
                      price = `$1 / ${formatNumber(1.0 / parseFloat(tokenPrices[token.symbol]?.toString()))}`
                    }
                    if (symbol === 'LTD' && price === 'N/A') {
                      price = `TBD`
                    }

                    return (
                      <button
                        type="button"
                        onClick={() => {
                          history.push(`/trade/${symbol}`)
                        }}
                      >
                        <div className="token-and-symbol">
                          <CurrencyLogo currency={token} size="24px" />

                          <div>
                            <h5 className="token">{name}</h5>
                            <h5 className="symbol">{symbol}</h5>
                          </div>
                        </div>
                        <div className="price-and-percent">
                          <h5>{price}</h5>
                          <h5>
                            {priceData.length
                              ? `${parseFloat(priceData[0].price_change_percentage_24h).toFixed(2)}%`
                              : 'N/A'}
                          </h5>
                        </div>
                        <h5>{priceData.length ? `${(priceData[0].market_cap / 10 ** 10).toFixed(2)}B` : 'N/A'}</h5>
                      </button>
                    )
                  })
                : null}
            </TableRows>
          </TokensTable>
        </FeaturedTokenContainer>
      </SwapContainer>
    </SwapPage>
  )
}

export default SwapNew
