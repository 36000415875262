import { Currency, JSBI, Pair, Percent } from '@pancakeswap-libs/sdk'
import CurrencyLogo from 'components/CurrencyLogo'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { useTotalSupply } from 'data/TotalSupply'
import { useActiveWeb3React } from 'hooks'
import useHttpLocations from 'hooks/useHttpLocations'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import styled from 'styled-components'
import { unwrappedToken } from 'utils/wrappedCurrency'

interface PositionCardProps {
  pair: Pair
  converter: any
  // eslint-disable-next-line react/no-unused-prop-types
}

function LiquidityShowDetail({ pair, converter }: PositionCardProps) {
  const { account } = useActiveWeb3React()
  const history = useHistory()

  const currency0: Currency = unwrappedToken(pair.token0)
  const currency1: Currency = unwrappedToken(pair.token1)
  const uriLocations = useHttpLocations(currency0 instanceof WrappedTokenInfo ? currency0.logoURI : undefined)
  // const uriLocations = useHttpLocations(currency0 instanceof WrappedTokenInfo ? currency0.logoURI : undefined)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]
  // console.log("currency0",token0Deposited,(token0Deposited?parseFloat(token0Deposited.toSignificant(6)):0),(token1Deposited?parseFloat(token1Deposited.toSignificant(6)):0))
  // console.log("logoURI",currency0)

  return (
    <div className="details-row">
      <h5 className="row-data">
        <div style={{ width: '45px' }}>
          <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={30} />
        </div>
        {`${currency0.symbol}/${currency1.symbol}`}
      </h5>
      <h5 className="row-data">{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}</h5>
      <h5 className="row-data">{poolTokenPercentage ? `${poolTokenPercentage.toFixed(2)}%` : '-'}</h5>
      <h5 className="row-data">
        $
        {(
          (token0Deposited && currency0 && currency0.symbol
            ? parseFloat(token0Deposited.toSignificant(6)) * parseFloat(converter[currency0.symbol])
            : 0) +
          (token1Deposited && currency1.symbol
            ? parseFloat(token1Deposited.toSignificant(6)) * parseFloat(converter[currency1.symbol])
            : 0)
        ).toFixed(2)}
      </h5>
    </div>
  )
}

export default LiquidityShowDetail
