import { Text } from "@bami-libs/uikit"
import React, { useEffect, useState } from "react"
import { useUserDeadline, useUserSlippageTolerance } from "state/user/hooks"
import closeIcon from '../../assets/images/closeIcon.svg'
// import {  } from '@bami-libs/uikit'
import SettingsPopup from "./SettingsPopup"



const predefinedValues = [
  { label: '0.1%', value: 0.1 },
  { label: '0.5%', value: 0.5 },
  { label: '1%', value: 1 }
]


const MAX_SLIPPAGE = 5000
const RISKY_SLIPPAGE_LOW = 50
const RISKY_SLIPPAGE_HIGH = 500

export default ({ settingShow, setSettingShow }) => {
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()
  const [value, setValue] = useState(userSlippageTolerance / 100)
  const [error, setError] = useState<string | null>(null)

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = evt.target
    setActive(0)
    setValue(parseFloat(inputValue))
  }

  // Updates local storage if value is valid
  useEffect(() => {
    try {
      const rawValue = value * 100
      if (!Number.isNaN(rawValue) && rawValue > 0 && rawValue < MAX_SLIPPAGE) {
        setUserslippageTolerance(rawValue)
        setError(null)
      } else {
        setError('Enter a valid slippage percentage')
      }
    } catch {
      setError('Enter a valid slippage percentage')
    }
  }, [value, setError, setUserslippageTolerance])

  // Notify user if slippage is risky
  useEffect(() => {
    if (userSlippageTolerance < RISKY_SLIPPAGE_LOW) {
      setError('Your transaction may fail')
    } else if (userSlippageTolerance > RISKY_SLIPPAGE_HIGH) {
      setError('Your transaction may be frontrun')
    }
  }, [userSlippageTolerance, setError])



  const [active, setActive] = useState(0)
  const [deadline, setDeadline] = useUserDeadline()
  const [DeadlineValue, setDeadlineValue] = useState(deadline / 60) // deadline in minutes
  const [DeadlineError, setDeadlineError] = useState<string | null>(null)

  const handleChangeDeadline = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = evt.target
    setDeadlineValue(parseInt(inputValue, 10))
  }

  // Updates local storage if value is valid
  useEffect(() => {
    try {
      const rawValue = DeadlineValue * 60
      if (!Number.isNaN(rawValue) && rawValue > 0) {
        setDeadline(rawValue)
        setDeadlineError(null)
      } else {
        setDeadlineError('Enter a valid deadline')
      }
    } catch {
      setDeadlineError('Enter a valid deadline')
    }
  }, [DeadlineValue, setDeadlineError, setDeadline])

  return (
    <>
      {
        settingShow ?
          (

            <SettingsPopup>
              <div className="settings_container">
                <h5 className="settings_header">
                  <span>Setting</span>
                  <button
                    type="button"
                    onClick={() => {
                      setSettingShow(false)
                    }}
                  >
                    <img src={closeIcon} alt="" />
                  </button>
                </h5>
                <div className="settings-body">
                  <h5 className="body_header">Slippage tolerance</h5>
                  <div className="percentage-inputs">
                    {/* <input type="text" defaultValue="0.1%" disabled />
                    <input type="text" defaultValue="0.1%" disabled /> */}
                    {predefinedValues.map(({ label, value: predefinedValue }) => {
                      const handleClick = () => {
                        setActive(predefinedValue)
                        setValue(predefinedValue)
                      }
                      return (
                        <button type="button" className={(active === predefinedValue) ? "activePercent" : ""} onClick={handleClick}>{label}</button>
                      )
                    })}
                    <input type="number" value={value} onChange={handleChange} />
                    <span>%</span>
                  </div>
                  {error && (
                    <Text mt="8px" mb="15px" fontSize="1.5em" color="failure">
                      {error}
                    </Text>
                  )}
                  <h5 className="body_header">Transaction deadline</h5>
                  <div className="min-input">
                    <input type="number" value={DeadlineValue} onChange={handleChangeDeadline} />
                    <h5>Minutes</h5>
                  </div>
                  {DeadlineError && (
                    <Text mt="5px" color="failure">
                      {DeadlineError}
                    </Text>
                  )}
                </div>
              </div>
            </SettingsPopup>
          )
          : null
      }
    </>
  )
}