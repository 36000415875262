import { Text } from '@bami-libs/uikit'
import { JSBI, Token, Trade, TradeType } from '@pancakeswap-libs/sdk'
import GradientButton from 'components/Button/button'
import { GreyCard } from 'components/Card'
// import CurrenyInpur
import { AutoColumn } from 'components/Column'
import ConnectWalletButton from 'components/ConnectWalletButtonNew'
import CurrencyLogo from 'components/CurrencyLogo'
import Loader from 'components/Loader'
import NewCurrencyInputPanel from 'components/NewCurrencyInputPanel'
import ProgressSteps from 'components/ProgressSteps'
import QuestionHelper from 'components/QuestionHelper'
import { AutoRow, RowBetween, RowFixed } from 'components/Row'
import { filterTokens } from 'components/SearchModal/filtering'
import { useTokenComparator } from 'components/SearchModal/sorting'
import SettingPopupContainer from 'components/SettingsPopup/SettingPopupContainer'
import { TYPE } from 'components/Shared'
import BetterTradeLink from 'components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from 'components/swap/confirmPriceImpactWithoutFee'
import FormattedPriceImpact from 'components/swap/FormattedPriceImpact'
import { BottomGrouping, StyledBalanceMaxMini, SwapCallbackError } from 'components/swap/styleds'
import ConfirmSwapModal from 'components/swapNew/ConfirmSwapModal'
import SyrupWarningModal from 'components/SyrupWarningModal'
import TokenListContainer from 'components/TokenListContainer'
import TokenWarningModal from 'components/TokenWarningModal'
import TranslatedText from 'components/TranslatedText'
import { BETTER_TRADE_LINK_THRESHOLD } from 'constants/index'
import { isTradeBetter } from 'data/V1'
import { useActiveWeb3React } from 'hooks'
import { useAllTokens, useCurrency, useToken } from 'hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from 'hooks/useApproveCallback'
import { useSwapCallback } from 'hooks/useSwapCallback'
import useToggledVersion, { Version } from 'hooks/useToggledVersion'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Repeat } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Field } from 'state/swap/actions'
import { useDefaultsFromURLSearch, useDerivedSwapInfo, useSwapActionHandlers, useSwapState } from 'state/swap/hooks'
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from 'state/user/hooks'
import { useCurrencyBalance } from 'state/wallet/hooks'
import styled from 'styled-components'
import { isAddress } from 'utils'
import { formatNumber } from 'utils/format'
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity,
} from 'utils/prices'
import rot from '../../assets/images/rot.svg'
import tog from '../../assets/images/tog.svg'
import downIcon from '../../assets/svg/down-arrow.svg'

const TradeComp = styled.div`
  width: 100%;
  min-height: calc(100vh - 64px);
  // position: relative;

  @media (max-width: 630px) {
    width: calc(100vw - 35px);
  }
`

const TradeContainer = styled.div`
  width: 1000px;
  margin: 20px auto 20px auto;
  display: flex;

  @media (max-width: 1250px) {
    width: 100%;
    margin: 40px auto 20px auto;
  }

  @media (max-width: 1150px) {
    flex-direction: column;
  }

  @media (max-width: 800px) {
    margin: 0px auto 20px auto;
  }
`

const TradeDetailsContainer = styled.div`
  .trade-wrapper {
    display: flex;
  }

  .trade-item-wrapper {
    flex: 1;
  }

  .trade-header,
  .trade-price {
    font-size: 2em;
    font-weight: 600;
    color: #4e598c;
    margin-bottom: 24px;

    span {
      font-weight: 200;
    }
  }

  & > div {
    width: 100% !important;
    // height: 250px!important;

    svg {
      display: flex;
      margin-left: -30px;
    }
  }

  .filter-details {
    font-size: 1.2em;
    font-weight: 200;
    color: #4e598c;
    margin-bottom: 0px;
    span {
      color: #5dd170;
    }
  }

  .trade-chart_container {
    // max-height: 300px;
    flex: 1;
  }

  .trade-chart-wrapper {
    display: flex;
  }

  .filter-btns_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    button {
      border: none;
      outline: none;
      background: none;
      font-size: 1.2em;
      color: #4e598c;
      margin-left: 10px;
      cursor: pointer;
    }

    @media (max-width: 650px) {
      justify-content: space-between;
      margin-bottom: 20px;

      button:last-child {
        display: inline-block;
      }
    }
  }

  .about_container {
    width: 70%;
    .about-header {
      font-size: 1.6em;
      font-weight: 600;
      color: #4e598c;
      margin-bottom: 24px;
    }

    .about-para {
      font-size: 1em;
      font-weight: 100;
      color: #4e598c;
    }

    @media (max-width: 650px) {
      width: 100%;
    }
  }

  @media (max-width: 650px) {
    flex: 0 0 100%;
    max-width: 100%;
    width: auto;
  }
`
const TradeCardContainer = styled.div<{ showPopup: boolean }>`
  margin: 0 auto;
  width: 400px;
  max-width: 50%;

  @media (max-width: 1150px) {
    flex: 0 0 auto;
    max-width: 100%;
    margin: 40px 0;
  }
`

const TradeCard = styled.div`
  width: 100%;
  filter: drop-shadow(0px 3px 10px rgba(0, 58, 94, 0.302));
  box-shadow: 0px 1px 10px rgba(0, 58, 94, 0.302);
  border-radius: 8px;
  background: ${(props: any) => (props.theme.isDark ? 'linear-gradient(180deg, #141E30 0%, #243B55 100%)' : '#fff')};

  & > div.trade-card-header {
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid rgba(112, 112, 112, 0.2);

    & > button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
    }

    button.toggler {
      font-size: 1.3em;
      font-weight: 200;
      color: #4e598c;
      cursor: pointer;
      opacity: 0.5;
      border: none;
      outline: none;
      background: none;
    }

    button.active-toggler {
      opacity: 1;
      cursor: default;
    }
  }

  & > div.trade-card-body {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .up-wrapper {
      padding: 12px;
      position: relative;
    }

    .down-wrapper {
      background-color: rgba(255, 140, 66, 0.1);
      padding: 12px;
      border-radius: 4px;
      padding-top: 32px;
    }

    .max-button {
      position: absolute;
      top: 20px;
      right: 8px;
      text-decoration: underline;
      border: none;
      background-color: transparent;
      cursor: pointer;
      font-size: 16px;
      color: grey;
      &:hover {
        color: rgb(255, 140, 66);
        transition: 0.3s all;
      }
      &:focus {
        outline: 0;
      }
    }

    h5.you-pay-header,
    h5.you-recieve-header {
      font-size: 1.3em;
      font-weight: 200;
      color: #4e598c;
      cursor: pointer;
      margin-bottom: 10px;
    }

    button.choose-token {
      border: none;
      outline: none;
      background: none;
      width: 45%;
      font-size: 1.3em;
      font-weight: 400;
      color: #4e598c;
      cursor: pointer;
      margin-bottom: 24px;
      text-align: left;

      img {
        width: 30px;
        height: auto;
      }
    }

    .rot_container {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      border: none;
      outline: none;
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      position: relative;
      top: 20px;
      left: 20px;
    }

    .you-recieve_container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button.choose-eth {
        border: none;
        outline: none;
        background: none;
        width: 45%;
        font-size: 1.6em;
        font-weight: 400;
        color: #4e598c;
        cursor: pointer;
        text-align: left;
      }

      input {
        width: 55%;
        border: 1px solid #4e598c;
        background: #fff;
        padding: 10px 6px;
        border-radius: 5px;
        font-size: 1.4em;
        color: #4e598c;
        margin: auto 0;
      }
    }
  }
`
const { main: Main } = TYPE

function TradePage(props) {
  // const [onPresentSettings] = useModal(<SettingPopupContainer settingShow={settingShow} setSettingShow={setSettingShow} />)
  const [showPopup, setShowPopup] = useState(false)
  const [showTokens, setShowTokens] = useState(false)
  const [settingShow, setSettingShow] = useState(false)
  const [market, setMarket] = useState(true)
  const [limit, setLimit] = useState(false)
  const [chartParam, setChartParam] = useState<any>('')
  const [chartParam2, setChartParam2] = useState<any>('')
  const loadedUrlParams = useDefaultsFromURLSearch()
  const { t } = useTranslation()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const [isSyrup, setIsSyrup] = useState<boolean>(false)
  const [syrupTransactionType, setSyrupTransactionType] = useState<string>('')
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  const handleConfirmSyrupWarning = useCallback(() => {
    setIsSyrup(false)
    setSyrupTransactionType('')
  }, [])

  const { account } = useActiveWeb3React()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [deadline] = useUserDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const { v1Trade, v2Trade, parsedAmount, currencies, inputError: swapInputError } = useDerivedSwapInfo()
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  //   const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion()
  const trade = showWrap
    ? undefined
    : {
        [Version.v1]: v1Trade,
        [Version.v2]: v2Trade,
      }[toggledVersion]

  const betterTradeLinkVersion: Version | undefined =
    toggledVersion === Version.v2 && isTradeBetter(v2Trade, v1Trade, BETTER_TRADE_LINK_THRESHOLD)
      ? Version.v1
      : toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade)
      ? Version.v2
      : undefined

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value.replaceAll(',', ''))
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value.replaceAll(',', ''))
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  )

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState((prevState) => ({ ...prevState, attemptingTxn: true, swapErrorMessage: undefined, txHash: undefined }))
    swapCallback()
      .then((hash) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: undefined,
          txHash: hash,
        }))
      })
      .catch((error) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: error.message,
          txHash: undefined,
        }))
      })
  }, [priceImpactWithoutFee, swapCallback, setSwapState])

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, showConfirm: false }))

    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [onUserInput, txHash, setSwapState])

  const handleAcceptChanges = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, tradeToConfirm: trade }))
  }, [trade])

  // This will check to see if the user has selected Syrup to either buy or sell.
  // If so, they will be alerted with a warning message.
  const checkForSyrup = useCallback(
    (selected: string, purchaseType: string) => {
      if (selected === 'syrup') {
        setIsSyrup(true)
        setSyrupTransactionType(purchaseType)
      }
    },
    [setIsSyrup, setSyrupTransactionType]
  )

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
      if (inputCurrency.symbol.toLowerCase() === 'syrup') {
        checkForSyrup(inputCurrency.symbol.toLowerCase(), 'Selling')
      }
    },
    [onCurrencySelection, setApprovalSubmitted, checkForSyrup]
  )

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)

      if (outputCurrency.symbol.toLowerCase() === 'syrup') {
        checkForSyrup(outputCurrency.symbol.toLowerCase(), 'Buying')
      }
    },
    [onCurrencySelection, checkForSyrup]
  )

  useEffect(() => {
    if (settingShow) {
      ;(document.querySelector('body #root>div') as HTMLDivElement).style.overflowX = 'visible'
    }
  }, [settingShow])
  const [selectedPopup, setSelectPopup] = useState<string>('')

  const handleDismissSearch = useCallback(() => {
    setShowTokens(false)
    setSelectPopup('')
  }, [])

  const handleClickChangeList = useCallback(() => {
    // setListView(true)
  }, [])
  const currency = currencies[Field.INPUT]
  const otherCurrency = currencies[Field.OUTPUT]
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const selectedOtherCurrencyBalance = useCurrencyBalance(account ?? undefined, otherCurrency ?? undefined)

  const [showInverted1, setShowInverted1] = useState<boolean>(false)
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    allowedSlippage,
    trade,
  ])
  const { realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])

  useEffect(() => {
    const {
      match: {
        params: { tokenAddress },
      },
    } = props

    setChartParam(tokenAddress)
  }, [props])

  const allTokens = useAllTokens()

  const [searchQuery, setSearchQuery] = useState<string>('')

  // if they input an address, use it
  const isAddressSearch = isAddress(searchQuery)
  const searchToken = useToken(searchQuery)

  const tokenComparator = useTokenComparator(false)

  const filteredTokens: Token[] = useMemo(() => {
    if (isAddressSearch) return searchToken ? [searchToken] : []
    return filterTokens(Object.values(allTokens), searchQuery)
  }, [isAddressSearch, searchToken, allTokens, searchQuery])

  const filteredSortedTokens: Token[] = useMemo(() => {
    if (searchToken) return [searchToken]
    const sorted = filteredTokens.sort(tokenComparator)
    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0)
    if (symbolMatch.length > 1) return sorted

    return [
      ...(searchToken ? [searchToken] : []),
      // sort any exact symbol matches first
      ...sorted.filter((token) => token.symbol?.toLowerCase() === symbolMatch[0]),
      ...sorted.filter((token) => token.symbol?.toLowerCase() !== symbolMatch[0]),
    ]
  }, [filteredTokens, searchQuery, searchToken, tokenComparator])

  const {
    match: {
      params: { tokenAddress },
    },
  } = props

  useEffect(() => {
    if (filteredSortedTokens && filteredSortedTokens.length && !otherCurrency) {
      handleOutputSelect(filteredSortedTokens.filter((v) => v.symbol === tokenAddress)[0])
    }
  }, [filteredSortedTokens, tokenAddress, handleOutputSelect, otherCurrency])

  useEffect(() => {
    if (otherCurrency) {
      setChartParam(otherCurrency.symbol)
    }
  }, [otherCurrency])
  useEffect(() => {
    if (currency) {
      setChartParam2(currency.symbol)
    }
  }, [currency])
  const otherCurrencySymbol =
    otherCurrency && otherCurrency.symbol && otherCurrency.symbol.length > 20
      ? `${otherCurrency.symbol.slice(0, 4)}...${otherCurrency.symbol.slice(
          otherCurrency.symbol.length - 5,
          otherCurrency.symbol.length
        )}`
      : otherCurrency?.symbol

  const currencySymbol =
    currency && currency.symbol && currency.symbol.length > 20
      ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)}`
      : currency?.symbol

  return (
    <TradeComp>
      <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      <SyrupWarningModal
        isOpen={isSyrup}
        transactionType={syrupTransactionType}
        onConfirm={handleConfirmSyrupWarning}
      />
      <ConfirmSwapModal
        isOpen={showConfirm}
        trade={trade}
        originalTrade={tradeToConfirm}
        onAcceptChanges={handleAcceptChanges}
        attemptingTxn={attemptingTxn}
        txHash={txHash}
        recipient={recipient}
        allowedSlippage={allowedSlippage}
        onConfirm={handleSwap}
        swapErrorMessage={swapErrorMessage}
        onDismiss={handleConfirmDismiss}
      />
      <TradeContainer>
        <TradeDetailsContainer />
        <TradeCardContainer
          onClick={() => {
            setShowPopup(false)
          }}
          showPopup={showPopup}
        >
          <TradeCard
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {!showTokens ? (
              <>
                <div className="trade-card-header">
                  <button
                    type="button"
                    className={`toggler ${market ? 'active-toggler' : ''}`}
                    onClick={(e) => {
                      e.preventDefault()
                      setMarket(true)
                      setLimit(false)
                    }}
                  >
                    Exchange
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setSettingShow(true)
                    }}
                  >
                    <img src={tog} alt="" />
                  </button>
                </div>
                <div className="trade-card-body">
                  <div className="up-wrapper">
                    <h5 className="you-pay-header">You Pay</h5>
                    <div className="you-recieve_container">
                      <button
                        type="button"
                        className="choose-token"
                        style={{ marginBottom: 0 }}
                        onClick={() => {
                          setShowTokens(true)
                          setSelectPopup('INPUT')
                        }}
                      >
                        {/* Choose Token */}
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {currency ? (
                            <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                          ) : null}
                          <div style={{ verticalAlign: 'center' }}>
                            {currencySymbol || <TranslatedText translationId={82}>Choose Token</TranslatedText>}
                          </div>
                          <img style={{ width: 16, marginLeft: 8 }} src={downIcon} alt="down-arrow" />
                        </span>
                      </button>
                      <button
                        className="max-button"
                        type="button"
                        onClick={() => {
                          handleTypeInput(
                            !!currency && selectedCurrencyBalance ? selectedCurrencyBalance?.toSignificant(6) : ''
                          )
                        }}
                      >
                        Max
                      </button>
                      <input
                        type="text"
                        placeholder="0.00"
                        value={formattedAmounts[Field.INPUT]}
                        onChange={(e) => handleTypeInput(e.target.value ? e.target.value : '')}
                      />
                    </div>
                    <span>
                      {account && (
                        <Text fontSize="14px" style={{ display: 'inline', cursor: 'pointer' }}>
                          {!!currency && selectedCurrencyBalance
                            ? `Balance: ${formatNumber(selectedCurrencyBalance?.toSignificant(6), 6)}`
                            : ''}
                        </Text>
                      )}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="rot_container"
                    onClick={() => {
                      setApprovalSubmitted(false) // reset 2 step UI for approvals
                      onSwitchTokens()
                    }}
                  >
                    <img className="rot" src={rot} alt="" />
                  </button>
                  <div className="down-wrapper">
                    <h5 className="you-pay-header">You Receive</h5>
                    <div className="you-recieve_container">
                      <button
                        type="button"
                        className="choose-token"
                        style={{ marginBottom: 0 }}
                        onClick={() => {
                          setShowTokens(true)
                          setSelectPopup('OUTPUT')
                        }}
                      >
                        {/* Choose Token */}
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {otherCurrency ? (
                            <CurrencyLogo currency={otherCurrency} size="24px" style={{ marginRight: '8px' }} />
                          ) : null}
                          {otherCurrencySymbol || <TranslatedText translationId={82}>Choose Token</TranslatedText>}
                          <img style={{ width: 16, marginLeft: 8 }} src={downIcon} alt="down-arrow" />
                        </span>
                      </button>
                      <input
                        type="text"
                        placeholder="0.00"
                        onChange={(e) => handleTypeOutput(e.target.value ? e.target.value : '')}
                        value={formattedAmounts[Field.OUTPUT]}
                      />
                    </div>
                    <span>
                      {account && (
                        <Text fontSize="14px" style={{ display: 'inline', cursor: 'pointer' }}>
                          {!!otherCurrency && selectedOtherCurrencyBalance
                            ? `Balance: ${formatNumber(selectedOtherCurrencyBalance.toSignificant(6), 6)}`
                            : ''}
                        </Text>
                      )}
                    </span>
                    <BottomGrouping style={{ width: '100%' }}>
                      {!account ? (
                        <ConnectWalletButton />
                      ) : showWrap ? (
                        <GradientButton
                          disabled={Boolean(wrapInputError)}
                          onClick={onWrap}
                          // fullWidth
                          width="100%"
                          fontSize="1.6em"
                          name={
                            wrapInputError ??
                            (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)
                          }
                        />
                      ) : noRoute && userHasSpecifiedInputOutput ? (
                        <GreyCard style={{ textAlign: 'center' }}>
                          <Main mb="4px">Insufficient liquidity for this trade.</Main>
                        </GreyCard>
                      ) : showApproveFlow ? (
                        <RowBetween>
                          <GradientButton
                            width="100%"
                            fontSize="1.6em"
                            onClick={approveCallback}
                            disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                            // style={{ width: '48%' }}
                            variant={approval === ApprovalState.APPROVED ? 'success' : 'primary'}
                            name={
                              approval === ApprovalState.PENDING ? (
                                <AutoRow gap="6px" justify="center">
                                  Approving <Loader stroke="white" />
                                </AutoRow>
                              ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                                'Approved'
                              ) : (
                                `Approve ${currencies[Field.INPUT]?.symbol}`
                              )
                            }
                          />

                          <GradientButton
                            width="100%"
                            fontSize="1.6em"
                            onClick={() => {
                              if (isExpertMode) {
                                handleSwap()
                              } else {
                                setSwapState({
                                  tradeToConfirm: trade,
                                  attemptingTxn: false,
                                  swapErrorMessage: undefined,
                                  showConfirm: true,
                                  txHash: undefined,
                                })
                              }
                            }}
                            id="swap-button"
                            disabled={
                              !isValid ||
                              approval !== ApprovalState.APPROVED ||
                              (priceImpactSeverity > 3 && !isExpertMode)
                            }
                            variant={isValid && priceImpactSeverity > 2 ? 'danger' : 'primary'}
                            name={
                              priceImpactSeverity > 3 && !isExpertMode
                                ? `Price Impact High`
                                : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`
                            }
                          />
                        </RowBetween>
                      ) : (
                        <GradientButton
                          width="100%"
                          fontSize="1.6em"
                          onClick={() => {
                            if (isExpertMode) {
                              handleSwap()
                            } else {
                              setSwapState({
                                tradeToConfirm: trade,
                                attemptingTxn: false,
                                swapErrorMessage: undefined,
                                showConfirm: true,
                                txHash: undefined,
                              })
                            }
                          }}
                          id="swap-button"
                          disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                          variant={isValid && priceImpactSeverity > 2 && !swapCallbackError ? 'danger' : 'primary'}
                          // fullWidth
                          name={
                            swapInputError ||
                            (priceImpactSeverity > 3 && !isExpertMode
                              ? `Price Impact Too High`
                              : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`)
                          }
                        />
                      )}
                      {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
                      {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                      {betterTradeLinkVersion && <BetterTradeLink version={betterTradeLinkVersion} />}
                    </BottomGrouping>
                  </div>

                  {trade ? (
                    <AutoColumn style={{ width: '100%', marginTop: '15px' }} gap="0px">
                      <RowBetween align="center">
                        <Text fontSize="14px" color="#4e598c">
                          Price
                        </Text>
                        <Text
                          fontSize="14px"
                          color="#4e598c"
                          style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            textAlign: 'right',
                            paddingLeft: '8px',
                            fontWeight: 500,
                          }}
                        >
                          {formatExecutionPrice(trade, showInverted1)}
                          <StyledBalanceMaxMini onClick={() => setShowInverted1(!showInverted1)}>
                            <Repeat size={14} />
                          </StyledBalanceMaxMini>
                        </Text>
                      </RowBetween>

                      <RowBetween>
                        <RowFixed>
                          <Text fontSize="14px" color="#4e598c">
                            {trade.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sold'}
                          </Text>
                          <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
                        </RowFixed>
                        <RowFixed>
                          <Text fontSize="14px" color="#4e598c">
                            {trade.tradeType === TradeType.EXACT_INPUT
                              ? formatNumber(slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) || 0, 4) ?? '-'
                              : formatNumber(slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) || 0, 4) ?? '-'}
                          </Text>
                          <Text fontSize="14px" marginLeft="4px" color="#4e598c">
                            {trade.tradeType === TradeType.EXACT_INPUT
                              ? trade.outputAmount.currency.symbol
                              : trade.inputAmount.currency.symbol}
                          </Text>
                        </RowFixed>
                      </RowBetween>
                      <RowBetween>
                        <RowFixed>
                          <Text fontSize="14px" color="#4e598c">
                            Price Impact
                          </Text>
                          <QuestionHelper text="The difference between the market price and your price due to trade size." />
                        </RowFixed>
                        <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
                      </RowBetween>
                      <RowBetween>
                        <RowFixed>
                          <Text fontSize="14px" color="#4e598c">
                            Liquidity Provider Fee
                          </Text>
                          <QuestionHelper text="For each trade a 0.2% fee is paid. 0.17% goes to liquidity providers and 0.03% goes to the PancakeSwap treasury." />
                        </RowFixed>
                        <Text fontSize="14px" color="#4e598c">
                          {realizedLPFee
                            ? `${realizedLPFee?.toSignificant(6)} ${trade.inputAmount.currency.symbol}`
                            : '-'}
                        </Text>
                      </RowBetween>
                    </AutoColumn>
                  ) : null}
                </div>
              </>
            ) : (
              <TokenListContainer
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                {/* <CurrencySearchList
                /> */}
                {selectedPopup === 'INPUT' ? (
                  <NewCurrencyInputPanel
                    isOpen={showTokens}
                    onDismiss={handleDismissSearch}
                    onCurrencySelect={handleInputSelect}
                    onChangeList={handleClickChangeList}
                    selectedCurrency={currencies[Field.INPUT]}
                    otherSelectedCurrency={currencies[Field.OUTPUT]}
                    showCommonBases={false}
                  />
                ) : (
                  <NewCurrencyInputPanel
                    isOpen={showTokens}
                    onDismiss={handleDismissSearch}
                    onCurrencySelect={handleOutputSelect}
                    onChangeList={handleClickChangeList}
                    selectedCurrency={currencies[Field.OUTPUT]}
                    otherSelectedCurrency={currencies[Field.INPUT]}
                    showCommonBases={false}
                  />
                )}
              </TokenListContainer>
            )}
          </TradeCard>
        </TradeCardContainer>
      </TradeContainer>
      <SettingPopupContainer settingShow={settingShow} setSettingShow={setSettingShow} />
    </TradeComp>
  )
}

export default TradePage
