// import React, { useEffect, useState } from 'react'
import { Text, Text as UIKitText } from '@bami-libs/uikit'
import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, currencyEquals, ETHER, TokenAmount, WETH } from '@pancakeswap-libs/sdk'
import GradientButton from 'components/Button/button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import ConnectWalletButton from 'components/ConnectWalletButtonNew'
import CurrencyLogo from 'components/CurrencyLogo'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import NewCurrencyInputPanel from 'components/NewCurrencyInputPanel'
import Row, { RowBetween, RowFlat } from 'components/Row'
import { TYPE } from 'components/Shared'
import TokenListContainer from 'components/TokenListPopup'
import { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { PairState } from 'data/Reserves'
import { useActiveWeb3React } from 'hooks'
import { useCurrency } from 'hooks/Tokens'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Field } from 'state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from 'state/mint/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useIsExpertMode, useUserDeadline, useUserSlippageTolerance } from 'state/user/hooks'
import { useCurrencyBalance } from 'state/wallet/hooks'
import styled from 'styled-components'
import { calculateGasMargin, calculateSlippageAmount, getRouterContract } from 'utils'
import { currencyId } from 'utils/currencyId'
import { formatNumber } from 'utils/format'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import angleDown2 from '../../assets/images/angleDown2.png'
import plusCircle from '../../assets/images/plusCircle.svg'
import { ONE_BIPS, ROUTER_ADDRESS } from '../../constants'
import { Dots } from '../Pool/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'

const { italic: Italic } = TYPE

const LiquidityPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LiquidityCard = styled.div`
  width: 500px;
  border-radius: 10px;
  background: ${(props: any) => (props.theme.isDark ? '#141E30' : '#fff')};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  z-index: 11;

  @media (max-width: 450px) {
    width: 100%;
  }
`

const LiquidityBody = styled.div`
  padding: 20px 30px;
  text-align: center;

  @media (max-width: 600px) {
    padding: 20px 10px;
  }

  h5.body_header {
    font-size: 1.6em;
    font-weight: 500;
    color: #4e598c;
    margin-bottom: 50px;

    @media (max-width: 360px) {
      font-size: 1.4em;
    }
  }

  h5.headline {
    font-size: 1em;
    font-weight: 200;
    color: rgba(78, 89, 140, 0.5);
    margin-bottom: 50px;

    @media (max-width: 360px) {
      font-size: 0.9em;
    }
  }

  h5.para {
    font-size: 1em;
    font-weight: 200;
    color: #4e598c;

    @media (max-width: 360px) {
      font-size: 0.9em;
    }
  }

  h5.add-liquidity_header {
    font-size: 1.6em;
    font-weight: 500;
    color: #ff8c42;
    margin-bottom: 24px;
    text-align: center;

    @media (max-width: 360px) {
      font-size: 1.4em;
    }
  }

  .first-input {
    & > div {
      button {
        color: #ff8c42 !important;
      }
    }
  }

  .input-select {
    width: 100%;
    padding: 12px 6px;
    border: 1px solid #4e598c;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      border: none;
      outline: none;
      background: none;
      font-size: 1.2em;
      color: #ff8c42;
      width: 50%;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;

      & > div:first-child {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: right;
      }

      & > button {
        border: none;
        outline: none;
        background: none;
        font-size: 1em;
        color: #4e598c;
        display: flex;
        align-items: center;
        cursor: pointer;

        @media (max-width: 360px) {
          font-size: 0.8em;
        }

        h5 {
          font-weight: 100;
          white-space: nowrap;

          @media (max-width: 360px) {
            & > img {
              width: 10px;
            }
          }
        }
      }
    }
  }

  img.plus-circle {
    margin: 20px auto;
  }

  h5.pool-share_header {
    font-size: 1.1em;
    font-weight: 200;
    color: #4e598c;
    margin: 20px 0;
    text-align: left;

    @media (max-width: 360px) {
      font-size: 0.9em;
    }
  }

  .values-data {
    width: 100%;
    padding: 12px 6px;
    border: 1px solid #4e598c;
    border-radius: 10px;
    display: flex;
    alugn-items: center;
    justify-content: space-between;

    & > div {
      flex: 0 0 33%;
      max-width: 33%;
      text-align: center;

      h5.value {
        font-size: 0.8em;
        font-weight: 200;
        color: #4e598c;

        @media (max-width: 360px) {
          font-size: 0.6em;
        }
      }

      h5.heading {
        font-size: 1em;
        font-weight: 200;
        color: rgba(78, 89, 140, 0.5);

        @media (max-width: 360px) {
          font-size: 0.8em;
        }
      }
    }
  }
`

const MaxButton = styled.div`
  border: 0;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  margin-right: 16px;
  color: rgb(31, 199, 212) !important;
  background-color: transparent;
`

function Liquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const [settings, setSettings] = useState(false)
  const [tokenShow, setTokenShow] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)

  useEffect(() => {
    if (settings) {
      ;(document.querySelector('body #root>div:last-child') as HTMLDivElement).style.overflowX = 'visible'
    }
  }, [settings])
  useEffect(() => {
    if (tokenShow) {
      ;(document.querySelector('body #root>div:last-child') as HTMLDivElement).style.overflowX = 'visible'
    }
  }, [tokenShow])

  const { account, chainId, library } = useActiveWeb3React()
  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  const expertMode = useIsExpertMode()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pairState,
    parsedAmounts,
    price,
    noLiquidity,
    poolTokenPercentage,
    error,
    liquidityMinted,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const [deadline] = useUserDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  // get the max amounts user can add

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], ROUTER_ADDRESS)
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], ROUTER_ADDRESS)

  const addTransaction = useTransactionAdder()
  const [selectedPopup, setSelectPopup] = useState<string>('')

  function onAdd() {
    if (!chainId || !library || !account) return
    const router = getRouterContract(chainId, library, account)

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB) {
      return
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0],
    }

    const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline

    let estimate
    let method: (...args: any) => Promise<TransactionResponse>
    let args: Array<string | string[] | number>
    let value: BigNumber | null
    if (currencyA === ETHER || currencyB === ETHER) {
      const tokenBIsETH = currencyB === ETHER
      estimate = router.estimateGas.addLiquidityETH
      method = router.addLiquidityETH
      args = [
        wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ?? '', // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        account,
        deadlineFromNow,
      ]
      value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString())
    } else {
      estimate = router.estimateGas.addLiquidity
      method = router.addLiquidity
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? '',
        wrappedCurrency(currencyB, chainId)?.address ?? '',
        parsedAmountA.raw.toString(),
        parsedAmountB.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadlineFromNow,
      ]
      value = null
    }

    setAttemptingTxn(true)
    estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then((response) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencies[Field.CURRENCY_A]?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencies[Field.CURRENCY_B]?.symbol}`,
          })

          setTxHash(response.hash)
          setShowConfirm(false)
        })
      )
      .catch((e) => {
        setAttemptingTxn(false)
        setShowConfirm(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (e?.code !== 4001) {
          console.error(e)
        }
      })
  }

  const handleCurrencyASelect = useCallback(
    (currA: Currency) => {
      const newCurrencyIdA = currencyId(currA)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/liquidity/${currencyIdB}/${currencyIdA}`)
      } else {
        history.push(`/liquidity/${newCurrencyIdA}/${currencyIdB}`)
      }
    },
    [currencyIdB, history, currencyIdA]
  )
  const handleCurrencyBSelect = useCallback(
    (currB: Currency) => {
      const newCurrencyIdB = currencyId(currB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/liquidity/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/liquidity/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/liquidity/${currencyIdA || 'ETH'}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, history, currencyIdB]
  )

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn gap="20px">
        <LightCard mt="20px" borderRadius="20px">
          <RowFlat>
            <UIKitText fontSize="48px" mr="8px">
              {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol}`}
            </UIKitText>
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={30}
            />
          </RowFlat>
        </LightCard>
      </AutoColumn>
    ) : (
      <AutoColumn gap="20px">
        <RowFlat style={{ marginTop: '20px' }}>
          <UIKitText fontSize="48px" mr="8px">
            {liquidityMinted?.toSignificant(6)}
          </UIKitText>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </RowFlat>
        <Row>
          <UIKitText fontSize="24px">
            {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol} Pool Tokens`}
          </UIKitText>
        </Row>
        <Italic fontSize={12} textAlign="left" padding="8px 0 0 0 ">
          {`Output is estimated. If the price changes by more than ${
            allowedSlippage / 100
          }% your transaction will revert.`}
        </Italic>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
        isLoading={attemptingTxn}
      />
    )
  }

  const handleDismissSearch = useCallback(() => {
    // setModalOpen(false)
    // setShowTokens(false)
    setTokenShow(false)
    setSelectPopup('')
  }, [])

  const handleClickChangeList = useCallback(() => {
    // setListView(true)
  }, [])
  const selectedCurrencyBalanceA = useCurrencyBalance(account ?? undefined, currencies[Field.CURRENCY_A] ?? undefined)
  const selectedCurrencyBalanceB = useCurrencyBalance(account ?? undefined, currencies[Field.CURRENCY_B] ?? undefined)

  if (showConfirm) {
    return (
      <LiquidityPage>
        <LiquidityCard>
          <ConfirmationModalContent
            title="Confirm Add Liquidity"
            onDismiss={() => setShowConfirm(false)}
            topContent={modalHeader}
            bottomContent={modalBottom}
          />
        </LiquidityCard>
      </LiquidityPage>
    )
  }

  return (
    <LiquidityPage>
      {tokenShow ? (
        <TokenListContainer>
          {selectedPopup === 'A' ? (
            <NewCurrencyInputPanel
              isOpen={tokenShow}
              onDismiss={handleDismissSearch}
              onCurrencySelect={handleCurrencyASelect}
              onChangeList={handleClickChangeList}
              selectedCurrency={currencies[Field.CURRENCY_A]}
              showCommonBases={false}
            />
          ) : (
            <NewCurrencyInputPanel
              isOpen={tokenShow}
              onDismiss={handleDismissSearch}
              onCurrencySelect={handleCurrencyBSelect}
              onChangeList={handleClickChangeList}
              selectedCurrency={currencies[Field.CURRENCY_B]}
              showCommonBases={false}
            />
          )}
        </TokenListContainer>
      ) : null}
      <LiquidityCard>
        <LiquidityBody>
          <h5 className="add-liquidity_header">Add Liquidity</h5>
          <div className="input-select first-input">
            <input
              type="text"
              placeholder="0.0"
              onChange={(e) => onFieldAInput(e.target.value ? e.target.value : '')}
              value={formattedAmounts[Field.CURRENCY_A]}
            />
            <div className="bnb-select">
              {account && (
                <Text fontSize="14px" style={{ display: 'inline', cursor: 'pointer' }}>
                  {!!currencies[Field.CURRENCY_A] && selectedCurrencyBalanceA
                    ? `Balance: ${formatNumber(selectedCurrencyBalanceA?.toSignificant(6), 10)}`
                    : ' -'}
                </Text>
              )}
              {currencies[Field.CURRENCY_A] ? (
                <>
                  <MaxButton onClick={() => onFieldAInput(selectedCurrencyBalanceA?.toSignificant(18) || '0')}>
                    Max
                  </MaxButton>
                  <CurrencyLogo currency={currencies[Field.CURRENCY_A]} size="20px" style={{ marginRight: '0px' }} />
                  <button
                    type="button"
                    onClick={() => {
                      setTokenShow(true)
                      setSelectPopup('A')
                    }}
                  >
                    {currencies[Field.CURRENCY_A]?.symbol}
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    setTokenShow(true)
                    setSelectPopup('A')
                  }}
                >
                  <h5 className="choose-token">
                    Choose token <img src={angleDown2} alt="" />
                  </h5>
                </button>
              )}
            </div>
          </div>
          <img src={plusCircle} alt="" className="plus-circle" />
          <div className="input-select">
            <input
              type="text"
              onChange={(e) => onFieldBInput(e.target.value ? e.target.value : '')}
              placeholder="0.0"
              value={formattedAmounts[Field.CURRENCY_B]}
            />
            <div className="bnb-select">
              {account && (
                <Text fontSize="14px" style={{ display: 'inline', cursor: 'pointer' }}>
                  {!!currencies[Field.CURRENCY_B] && selectedCurrencyBalanceB
                    ? `Balance: ${formatNumber(selectedCurrencyBalanceB?.toSignificant(6), 10)}`
                    : ' -'}
                </Text>
              )}
              {currencies[Field.CURRENCY_B] ? (
                <>
                  <MaxButton onClick={() => onFieldBInput(selectedCurrencyBalanceB?.toSignificant(18) || '0')}>
                    Max
                  </MaxButton>
                  <CurrencyLogo currency={currencies[Field.CURRENCY_B]} size="20px" style={{ marginRight: '0px' }} />
                  <button
                    type="button"
                    onClick={() => {
                      setTokenShow(true)
                      setSelectPopup('B')
                    }}
                  >
                    {currencies[Field.CURRENCY_B]?.symbol}
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    setTokenShow(true)
                    setSelectPopup('B')
                  }}
                >
                  <h5 className="choose-token">
                    Choose token <img src={angleDown2} alt="" />
                  </h5>
                </button>
              )}
            </div>
          </div>
          {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID ? (
            <>
              <h5 className="pool-share_header">PRICES AND POOL SHARE</h5>
              <div className="values-data">
                <div>
                  <h5 className="value">{price?.toSignificant(6) ?? '-'}</h5>
                  <h5 className="heading">
                    {currencies[Field.CURRENCY_B]?.symbol} per {currencies[Field.CURRENCY_A]?.symbol}
                  </h5>
                </div>
                <div>
                  <h5 className="value">{price?.invert()?.toSignificant(6) ?? '-'}</h5>
                  <h5 className="heading">
                    {currencies[Field.CURRENCY_A]?.symbol} per {currencies[Field.CURRENCY_B]?.symbol}
                  </h5>
                </div>
                <div>
                  <h5 className="value">
                    {noLiquidity && price
                      ? '100'
                      : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
                    %
                  </h5>
                  <h5 className="heading">Share of pool</h5>
                </div>
              </div>
            </>
          ) : null}
          {/* <GradientButton name="Unlock Wallet" width="100%" fontSize="1.2em" margin="20px 0 0 0" /> */}
          {!account ? (
            <ConnectWalletButton style={{ marginTop: '10px' }} />
          ) : (
            <AutoColumn gap="md">
              {(approvalA === ApprovalState.NOT_APPROVED ||
                approvalA === ApprovalState.PENDING ||
                approvalB === ApprovalState.NOT_APPROVED ||
                approvalB === ApprovalState.PENDING) &&
                isValid && (
                  <RowBetween>
                    {approvalA !== ApprovalState.APPROVED && (
                      <GradientButton
                        width="100%"
                        fontSize="1.2em"
                        margin="20px 0 0 0"
                        marginRight="0"
                        onClick={approveACallback}
                        disabled={approvalA === ApprovalState.PENDING}
                        style={{ width: approvalB !== ApprovalState.APPROVED ? '48%' : '100%' }}
                        name={
                          approvalA === ApprovalState.PENDING ? (
                            <Dots>Approving {currencies[Field.CURRENCY_A]?.symbol}</Dots>
                          ) : (
                            `Approve ${currencies[Field.CURRENCY_A]?.symbol}`
                          )
                        }
                      />
                      // </GradientButton>
                    )}
                    {approvalB !== ApprovalState.APPROVED && (
                      <GradientButton
                        width="100%"
                        fontSize="1.2em"
                        margin="20px 0 0 0"
                        marginRight="0"
                        onClick={approveBCallback}
                        disabled={approvalB === ApprovalState.PENDING}
                        style={{ width: approvalA !== ApprovalState.APPROVED ? '48%' : '100%' }}
                        name={
                          approvalB === ApprovalState.PENDING ? (
                            <Dots>Approving {currencies[Field.CURRENCY_B]?.symbol}</Dots>
                          ) : (
                            `Approve ${currencies[Field.CURRENCY_B]?.symbol}`
                          )
                        }
                      />
                    )}
                  </RowBetween>
                )}
              <GradientButton
                width="100%"
                fontSize="1.2em"
                margin="20px 0 0 0"
                onClick={() => {
                  setShowConfirm(true)
                }}
                disabled={!isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED}
                variant={
                  !isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]
                    ? 'danger'
                    : 'primary'
                }
                fullWidth
                name={error ?? 'Supply'}
              />
            </AutoColumn>
          )}
        </LiquidityBody>
      </LiquidityCard>
    </LiquidityPage>
  )
}

export default Liquidity
