import { useModal } from '@bami-libs/uikit'
import { Pair } from '@pancakeswap-libs/sdk'
import GradientButton from 'components/Button/button'
import RecentTransactionsModal from 'components/PageHeader/RecentTransactionsModal'
import FullPositionCardNew from 'components/PositionCardNew'
import SettingPopupContainer from 'components/SettingsPopup/SettingPopupContainer'
import TranslatedText from 'components/TranslatedText'
import { usePairs } from 'data/Reserves'
import { useActiveWeb3React } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import styled from 'styled-components'
import histIcon from '../../assets/images/histIcon.svg'
import settsIcon from '../../assets/images/settsIcon.svg'

const PoolPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LiquidityCard = styled.div`
  width: 400px;
  border-radius: 10px;
  background: ${(props: any) => (props.theme.isDark ? '#141E30' : '#fff')};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  z-index: 11;

  @media (max-width: 450px) {
    width: 100%;
  }
`

const LiquidityHeader = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid rgba(78, 89, 140, 0.1);

  h5.liquidity_header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 2em;
    font-weight: 500;
    color: #ff8c42;
    margin-bottom: 24px;

    span {
      margin-left: 50%;
      transform: translateX(-40%);
    }

    @media (max-width: 360px) {
      font-size: 1.4em;
    }

    button {
      border: none;
      outline: none;
      background: none;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 7%;
      margin-right: 5px;
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  h5.headline {
    font-size: 1em;
    font-weight: 100;
    color: rgba(78, 89, 140, 0.5);
    text-align: center;
    margin-bottom: 24px;

    @media (max-width: 360px) {
      font-size: 0.9em;
    }
  }

  & > button {
    margin-right: auto;
  }
`

const LiquidityBody = styled.div`
  padding: 20px 30px;
  text-align: center;

  h5.body_header {
    font-size: 1.6em;
    font-weight: 500;
    color: #4e598c;
    margin-bottom: 50px;

    @media (max-width: 360px) {
      font-size: 1.4em;
    }
  }

  h5.headline {
    font-size: 1em;
    font-weight: 200;
    color: rgba(78, 89, 140, 0.5);
    margin-bottom: 50px;

    @media (max-width: 360px) {
      font-size: 0.9em;
    }
  }

  h5.para {
    font-size: 1em;
    font-weight: 200;
    color: #4e598c;

    @media (max-width: 360px) {
      font-size: 0.9em;
    }
  }

  h5.add-liquidity_header {
    font-size: 1.6em;
    font-weight: 500;
    color: #ff8c42;
    margin-bottom: 24px;
    text-align: center;

    @media (max-width: 360px) {
      font-size: 1.4em;
    }
  }

  .input-select {
    width: 100%;
    padding: 12px 6px;
    border: 1px solid #4e598c;
    border-radius: 10px;
    display: flex;
    alugn-items: center;
    justify-content: space-between;

    input {
      border: none;
      outline: none;
      background: none;
      font-size: 1.2em;
      color: rgba(255, 140, 66, 0.5);
      width: 50%;
    }

    & > div {
      display: flex;
      align-items: center;

      select {
        border: none;
        outline: none;
        font-size: 1em;
        color: #ff8c42;
        background: none;

        @media (max-width: 360px) {
          font-size: 0.8em;
        }
      }

      & > button {
        border: none;
        outline: none;
        background: none;
        font-size: 1em;
        color: #4e598c;
        display: flex;
        align-items: center;
        cursor: pointer;

        @media (max-width: 360px) {
          font-size: 0.8em;
        }

        h5 {
          font-weight: 100;
          white-space: nowrap;

          @media (max-width: 360px) {
            & > img {
              width: 10px;
            }
          }
        }
      }
    }
  }

  img.plus-circle {
    margin: 20px auto;
  }

  h5.pool-share_header {
    font-size: 1.1em;
    font-weight: 200;
    color: #4e598c;
    margin: 20px 0;
    text-align: left;

    @media (max-width: 360px) {
      font-size: 0.9em;
    }
  }

  .values-data {
    width: 100%;
    padding: 12px 6px;
    border: 1px solid #4e598c;
    border-radius: 10px;
    display: flex;
    alugn-items: center;
    justify-content: space-between;

    & > div {
      flex: 0 0 33%;
      max-width: 33%;
      text-align: center;

      h5.value {
        font-size: 0.8em;
        font-weight: 200;
        color: #4e598c;

        @media (max-width: 360px) {
          font-size: 0.6em;
        }
      }

      h5.heading {
        font-size: 1em;
        font-weight: 200;
        color: rgba(78, 89, 140, 0.5);

        @media (max-width: 360px) {
          font-size: 0.8em;
        }
      }
    }
  }
`

const SettingsPopup = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props: any) => (props.theme.isDark ? 'rgba(36, 59, 85,.5)' : 'rgba(255, 140, 66, 0.3)')};
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;

  div.settings_container {
    width: 700px;
    background: ${(props: any) => (props.theme.isDark ? '#141E30' : '#fff')};
    border-radius: 10px;

    @media (max-width: 720px) {
      width: 97%;
    }

    h5.settings_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 20px;
      border-bottom: 2px solid rgba(78, 89, 140, 0.1);
      font-size: 1.4em;
      font-weight: 500;
      color: #4e598c;

      @media (max-width: 450px) {
        font-size: 1.2em;
      }

      button {
        border: none;
        outline: none;
        background: none;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    div.settings-body {
      padding: 30px 20px 14px 20px;

      h5.body_header {
        font-size: 1.6em;
        font-weight: 500;
        color: #4e598c;
        margin-bottom: 20px;

        @media (max-width: 450px) {
          font-size: 1.3em;
        }
      }

      div.percentage-inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        input {
          flex: 0 0 13%;
          max-width: 13%;
          font-size: 1.4em;
          padding: 8px 20px;
          color: #ff8c42;
          font-weight: 100;
          border: 1px solid #4e598c;
          background: none;
          outline: none;
          border-radius: 10px;

          @media (max-width: 450px) {
            font-size: 1.1em;

            flex: 0 0 18%;
            max-width: 18%;
            padding: 6px 12px;
          }

          &:nth-child(4) {
            flex: 0 0 47%;
            max-width: 47%;

            @media (max-width: 450px) {
              flex: 0 0 40%;
              max-width: 40%;
            }
          }
        }
      }

      div.min-input {
        display: flex;
        align-items: center;

        input {
          flex: 0 0 22%;
          max-width: 22%;
          font-size: 1.4em;
          padding: 8px 20px;
          color: #ff8c42;
          font-weight: 100;
          border: 1px solid #4e598c;
          background: none;
          outline: none;
          border-radius: 10px;

          @media (max-width: 450px) {
            font-size: 1.1em;

            flex: 0 0 45%;
            max-width: 45%;
            padding: 8px 12px;
          }
        }

        h5 {
          font-size: 1.2em;
          font-weight: 500;
          color: #4e598c;
          margin-left: 10px;
        }
      }
    }
  }
`

function PoolNew() {
  const [settings, setSettings] = useState(false)
  const history = useHistory()
  const { account } = useActiveWeb3React()
  const trackedTokenPairs = useTrackedTokenPairs()
  const [settingShow, setSettingShow] = useState(false)
  const [onPresentRecentTransactions] = useModal(<RecentTransactionsModal />)

  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens,
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )
  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  useEffect(() => {
    if (settings) {
      ;(document.querySelector('body #root>div') as HTMLDivElement).style.overflowX = 'visible'
    }
  }, [settings])

  return (
    <PoolPage>
      <LiquidityCard>
        <LiquidityHeader>
          <h5 className="liquidity_header">
            <span>Liquidity</span>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault()
                setSettingShow(true)
              }}
            >
              <img src={settsIcon} alt="" />
            </button>
            <button type="button" onClick={onPresentRecentTransactions}>
              <img src={histIcon} alt="" />
            </button>
          </h5>
          <h5 className="headline">Add liquidity to receive LP tokens</h5>
          <GradientButton
            name="Add Liquidity"
            margin="0 auto"
            fontSize="1.6em"
            borderRadius="10px"
            padding="10px 20px"
            onClick={() => {
              history.push('/liquidity')
            }}
          />
        </LiquidityHeader>
        <LiquidityBody>
          <h5 className="body_header">
            <TranslatedText translationId={685}>Your Liquidity</TranslatedText>
          </h5>
          {!account && <h5 className="headline">Connect to a wallet to view your liquidity.</h5>}
          {allV2PairsWithLiquidity.map((v2Pair) => {
            return <FullPositionCardNew key={v2Pair.liquidityToken.address} pair={v2Pair} />
          })}
        </LiquidityBody>
      </LiquidityCard>
      <SettingPopupContainer settingShow={settingShow} setSettingShow={setSettingShow} />
    </PoolPage>
  )
}

export default PoolNew
