import { Currency, CurrencyAmount, ETHER, JSBI, Token, TokenAmount } from '@pancakeswap-libs/sdk'
import { LENDING_CONTROLLER_ADDRESS } from 'constants/index'
import { useTotalSupply } from 'data/TotalSupply'
import { BigNumber } from 'ethers'
import BigNumber2 from 'bignumber.js'
import { useMemo } from 'react'
import { generateAPYfromRatePerBlock } from 'utils/format'
import { unwrappedToken } from 'utils/wrappedCurrency'
import ERC20_INTERFACE, {
  BEP20_CTOKEN_INTERFACE,
  LENDING_CONTROLLER_INTERFACE,
  ORACLE_INTERFACE,
} from '../../constants/abis/erc20'
import { useActiveWeb3React } from '../../hooks'
import { useAllLendingTokens, useAllTokens } from '../../hooks/Tokens'
import { useContract, useMulticallContract } from '../../hooks/useContract'
import { isAddress } from '../../utils'
import { useMultipleContractSingleData, useSingleCallResult, useSingleContractMultipleData } from '../multicall/hooks'

/**
 * Returns a map of the given addresses to their eventually consistent ETH balances.
 */
export function useETHBalances(uncheckedAddresses?: (string | undefined)[]): {
  [address: string]: CurrencyAmount | undefined
} {
  const multicallContract = useMulticallContract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
            .sort()
        : [],
    [uncheckedAddresses]
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses.map((address) => [address])
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount }>((memo, address, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[address] = CurrencyAmount.ether(JSBI.BigInt(value.toString()))
        return memo
      }, {}),
    [addresses, results]
  )
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[]
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false) ?? [],
    [tokens]
  )
  const validatedTokenAddresses = useMemo(() => validatedTokens.map((vt) => vt.address), [validatedTokens])

  const balances = useMultipleContractSingleData(validatedTokenAddresses, ERC20_INTERFACE, 'balanceOf', [address])

  const anyLoading: boolean = useMemo(() => balances.some((callState) => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined

              if (amount) {
                memo[token.address] = new TokenAmount(token, amount)
              }
              return memo
            }, {})
          : {},
      [address, validatedTokens, balances]
    ),
    anyLoading,
  ]
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[]
): { [tokenAddress: string]: TokenAmount | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: Token): TokenAmount | undefined {
  const tokenBalances = useTokenBalances(account, [token])
  if (!token) return undefined
  return tokenBalances[token.address]
}

export function useCTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: Token[]
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(() => tokens || [], [tokens])
  const validatedTokenAddresses = useMemo(
    // @ts-ignore
    () => validatedTokens.map((vt) => vt.tokenInfo?.cToken?.address),
    [validatedTokens]
  )

  const balances = useMultipleContractSingleData(
    validatedTokenAddresses,
    BEP20_CTOKEN_INTERFACE,
    'balanceOfUnderlying',
    [address]
  )

  const anyLoading: boolean = useMemo(() => balances.some((callState) => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined

              if (amount) {
                memo[token?.address || 'BNB'] = new TokenAmount(token, amount)
              }
              return memo
            }, {})
          : {},
      [address, validatedTokens, balances]
    ),
    anyLoading,
  ]
}

export function useCTokenBalances(
  address?: string,
  tokens?: Token[]
): { [tokenAddress: string]: TokenAmount | undefined } {
  return useCTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

// get the balance for a single ctoken/account combo
export function useCTokenBalance(account?: string, token?: Token): TokenAmount | undefined {
  const tokenBalances = useCTokenBalances(account, token ? [token] : [])
  if (!token) return undefined
  return tokenBalances[token.address]
}

export function useliquidity(account, liquidity, converter) {
  const finalLiquid = liquidity.map((pair) => {
    const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
    const totalPoolTokens = useTotalSupply(pair.liquidityToken)
    const currency0 = unwrappedToken(pair.token0)
    const currency1 = unwrappedToken(pair.token1)

    const [token0Deposited, token1Deposited] =
      !!pair &&
      !!totalPoolTokens &&
      !!userPoolBalance &&
      // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
      JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
        ? [
            pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
            pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
          ]
        : [undefined, undefined]

    return (
      (Number.isNaN(parseFloat(token0Deposited?.toSignificant(6)))
        ? 0
        : parseFloat(token0Deposited?.toSignificant(6)) * converter[currency0.symbol ? currency0.symbol : 'USD']) +
      (Number.isNaN(parseFloat(token1Deposited?.toSignificant(6)))
        ? 0
        : parseFloat(token1Deposited?.toSignificant(6)) * converter[currency1.symbol ? currency1.symbol : 'USD'])
    )
  })

  return { finalLiquid: [0].concat(finalLiquid).reduce((sum, v) => sum + v) }
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[]
): (CurrencyAmount | undefined)[] {
  const tokens = useMemo(
    () => currencies?.filter((currency): currency is Token => currency instanceof Token) ?? [],
    [currencies]
  )

  const tokenBalances = useTokenBalances(account, tokens)
  const containsETH: boolean = useMemo(() => currencies?.some((currency) => currency === ETHER) ?? false, [currencies])
  const ethBalance = useETHBalances(containsETH ? [account] : [])

  return useMemo(
    () =>
      currencies?.map((currency) => {
        if (!account || !currency) return undefined
        if (currency instanceof Token) return tokenBalances[currency.address]
        if (currency === ETHER) return ethBalance[account]
        return undefined
      }) ?? [],
    [account, currencies, ethBalance, tokenBalances]
  )
}

export function useCurrencyBalance(account?: string, currency?: Currency): CurrencyAmount | undefined {
  return useCurrencyBalances(account, [currency])[0]
}

// mimics useAllBalances
export function useAllTokenBalances(): { [tokenAddress: string]: TokenAmount | undefined } {
  const { account } = useActiveWeb3React()
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  return balances ?? {}
}

export function useAllLendingTokenBalances(): { [tokenAddress: string]: TokenAmount | undefined } {
  const { account } = useActiveWeb3React()
  const myAccount = useMemo(() => {
    return [account ?? undefined]
  }, [account])
  const allTokens = useAllLendingTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  const bnbBalances = useETHBalances(myAccount)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const result = useMemo(
    () => ({ ...balances, BNB: Object.values(bnbBalances)[0] as unknown as TokenAmount }),
    [balances, bnbBalances]
  )
  return result
}

export function useAllLendingCTokenBalances(): { [tokenAddress: string]: TokenAmount | undefined } {
  const { account } = useActiveWeb3React()
  const allTokens = useAllLendingTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useCTokenBalances(account ?? undefined, allTokensArray)

  return balances ?? {}
}

export function useAllCashCTokenBalances(): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const allTokens = useAllLendingTokens()
  const tokens = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const validatedTokens: Token[] = useMemo(() => tokens ?? [], [tokens])
  const validatedTokenAddresses = useMemo(
    // @ts-ignore
    () => validatedTokens.map((vt) => vt.tokenInfo?.cToken?.address),
    [validatedTokens]
  )

  const balances = useMultipleContractSingleData(validatedTokenAddresses, BEP20_CTOKEN_INTERFACE, 'getCash', [])
  const anyLoading: boolean = useMemo(() => balances.some((callState) => callState.loading), [balances])

  return [
    useMemo(
      () =>
        validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined
              if (amount) {
                memo[token?.address || 'BNB'] = new TokenAmount(token, amount)
              }
              return memo
            }, {})
          : {},
      [validatedTokens, balances]
    ),
    anyLoading,
  ]
}

export function useAllTotalBorrowCTokenBalances(): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const allTokens = useAllLendingTokens()
  const tokens = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const validatedTokens: Token[] = useMemo(() => tokens ?? [], [tokens])
  const validatedTokenAddresses = useMemo(
    // @ts-ignore
    () => validatedTokens.map((vt) => vt.tokenInfo?.cToken?.address),
    [validatedTokens]
  )

  const balances = useMultipleContractSingleData(
    validatedTokenAddresses,
    BEP20_CTOKEN_INTERFACE,
    'totalBorrowsCurrent',
    []
  )
  const anyLoading: boolean = useMemo(() => balances.some((callState) => callState.loading), [balances])

  return [
    useMemo(
      () =>
        validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined
              if (amount) {
                memo[token?.address || 'BNB'] = new TokenAmount(token, amount)
              }
              return memo
            }, {})
          : {},
      [validatedTokens, balances]
    ),
    anyLoading,
  ]
}

export function useAllLendingTokenSupplyApy(): [{ [tokenAddress: string]: number | undefined }, boolean] {
  const allTokens = useAllLendingTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const validatedTokenAddresses = useMemo(
    // @ts-ignore
    () => allTokensArray.map((vt) => vt.tokenInfo.cToken?.address),
    [allTokensArray]
  )
  const supplyRatePerBlock = useMultipleContractSingleData(
    validatedTokenAddresses,
    BEP20_CTOKEN_INTERFACE,
    'supplyRatePerBlock',
    []
  )
  const anyLoading: boolean = useMemo(
    () => supplyRatePerBlock.some((callState) => callState.loading),
    [supplyRatePerBlock]
  )

  return [
    useMemo(
      () =>
        allTokensArray.length > 0
          ? allTokensArray.reduce<{ [tokenAddress: string]: number | undefined }>((memo, token, i) => {
              const value = supplyRatePerBlock?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined

              if (amount) {
                memo[token?.address || 'BNB'] = generateAPYfromRatePerBlock(amount)
              }
              return memo
            }, {})
          : {},
      [allTokensArray, supplyRatePerBlock]
    ),
    anyLoading,
  ]
}

export function useAllLendingTokenBorrowApy(): [{ [tokenAddress: string]: number | undefined }, boolean] {
  const allTokens = useAllLendingTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const validatedTokenAddresses = useMemo(
    // @ts-ignore
    () => allTokensArray.map((vt) => vt.tokenInfo.cToken?.address),
    [allTokensArray]
  )
  const supplyRatePerBlock = useMultipleContractSingleData(
    validatedTokenAddresses,
    BEP20_CTOKEN_INTERFACE,
    'borrowRatePerBlock',
    []
  )
  const anyLoading: boolean = useMemo(
    () => supplyRatePerBlock.some((callState) => callState.loading),
    [supplyRatePerBlock]
  )

  return [
    useMemo(
      () =>
        allTokensArray.length > 0
          ? allTokensArray.reduce<{ [tokenAddress: string]: number | undefined }>((memo, token, i) => {
              const value = supplyRatePerBlock?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined

              if (amount) {
                memo[token.address] = generateAPYfromRatePerBlock(amount)
              }
              return memo
            }, {})
          : {},
      [allTokensArray, supplyRatePerBlock]
    ),
    anyLoading,
  ]
}

export const useLendingPrices = () => {
  const { chainId: activeChainId } = useActiveWeb3React()
  const chainId = activeChainId || process.env.REACT_APP_CHAIN_ID
  // @ts-ignore
  const lendingAddress = LENDING_CONTROLLER_ADDRESS[chainId]
  const allTokens = useAllLendingTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const validatedTokenAddresses = useMemo(
    // @ts-ignore
    () => allTokensArray.map((vt) => vt.tokenInfo.cToken?.address),
    [allTokensArray]
  )
  const lendingControllerContract = useContract(lendingAddress, LENDING_CONTROLLER_INTERFACE, false)
  const oracleAddressRes = useSingleCallResult(lendingControllerContract, 'oracle')
  const oracleAddress = oracleAddressRes.result?.[0]
  const oracleContract = useContract(oracleAddress, ORACLE_INTERFACE, false)
  const lendingPrices = useSingleContractMultipleData(
    oracleContract,
    'getUnderlyingPrice',
    validatedTokenAddresses.map((o) => [o])
  )
  const anyLoading: boolean = useMemo(() => lendingPrices.some((callState) => callState.loading), [lendingPrices])

  return [
    useMemo(
      () =>
        allTokensArray.length > 0
          ? allTokensArray.reduce<{ [tokenAddress: string]: number | undefined }>((memo, token, i) => {
              const value = lendingPrices?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined

              if (amount) {
                memo[token.address || 'BNB'] = parseFloat(
                  new BigNumber2(amount.toString())
                    // @ts-ignore
                    .div(new BigNumber2(10).pow(18 + 18 - token.tokenInfo.decimals))
                    .toString()
                )
              }
              return memo
            }, {})
          : {},
      [allTokensArray, lendingPrices]
    ),
    anyLoading,
  ]
}

export function useAllLendingCTokenBorrowBalances(): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const { account: address } = useActiveWeb3React()
  const allTokens = useAllLendingTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const validatedTokens: Token[] = useMemo(() => allTokensArray ?? [], [allTokensArray])
  const validatedTokenAddresses = useMemo(
    // @ts-ignore
    () => validatedTokens.map((vt) => vt.tokenInfo?.cToken?.address),
    [validatedTokens]
  )

  const balances = useMultipleContractSingleData(
    validatedTokenAddresses,
    BEP20_CTOKEN_INTERFACE,
    'borrowBalanceCurrent',
    [address ?? undefined]
  )

  const anyLoading: boolean = useMemo(() => balances.some((callState) => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined

              if (amount) {
                memo[token?.address || 'BNB'] = new TokenAmount(token, amount)
              }
              return memo
            }, {})
          : {},
      [address, validatedTokens, balances]
    ),
    anyLoading,
  ]
}
