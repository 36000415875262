import { Credentials } from '@crowdin/crowdin-api-client'
import LiquidityShow from 'components/DetailsPopup/liquidityShow'
import { allLanguages, EN } from 'constants/localisation/languageCodes'
import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { fetchPricesData } from 'state/price/reducer'
import { useContract } from 'hooks/useContract'
import { VALUE_LP_ABI } from 'constants/abis'

import bgImg from '../assets/images/homeBg.png'
import Menu from '../components/Menu'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'
import Home from './home/home'
import Home1 from './home1/home1'
import Liquidity from './liquidity/liquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './liquidity/redirects'
import MigrateV1 from './MigrateV1'
import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import PoolNew from './PoolNew/PoolNew'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import { RedirectPathToSwapOnly } from './Swap/redirects'
import SwapNew from './SwapNew/swap-new'
import TradePage from './Trade/trade'
import Bridge from './Bridge'
import Dipo from './Dipo'
import enLang from '../assets/lang/en.json'
import viLang from '../assets/lang/vi.json'
import NotFound from './NotFound'
import MaintenancePage from './MaintenancePage'
import LendingPage from './Lending'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  background: ${(props) =>
    props.theme.isDark ? 'linear-gradient(180deg, #141E30 0%, #243B55 100%);' : 'rgba(247, 178, 103. .05)'};

  & > div {
    & > nav {
      background: ${(props) => (props.theme.isDark ? 'linear-gradient(180deg, #141E30 0%, #243B55 100%)' : '#fff')};
      svg {
        fill: #ff8c42;
      }

      & > div:first-child {
        align-items: center;

        button:first-child {
          margin-right: 10px;
        }
        a {
          & > svg {
            width: 156px;
            height: 40px;
            image {
              width: 100%;
              height: auto;
            }

            &:first-child {
              image {
                width: 250%;
                height: 100%;
                x: -46;
              }
            }
          }
        }
      }

      & > div:last-child {
        & > div {
          button {
            background: linear-gradient(180deg, #f7b267 0%, #f79d65 24.7%, #f4845f 51%, #f27059 74.5%, #f25c54 100%);
            color: #fff;
          }
        }
      }
    }
    & > div {
      #rem-liq-box {
        & > div:first-child {
          background: ${(props) => (props.theme.isDark ? '#141E30' : '#fff')};

          div {
            color: rgb(78, 89, 140);
          }

          & > div:first-child {
            & > div:first-child {
              color: rgb(78, 89, 140);
            }
          }
        }
        & > div:last-child {
          div {
            color: rgb(78, 89, 140);
          }
          & > div {
            background: ${(props) => (props.theme.isDark ? '#141E30' : '#fff')};
          }
        }
      }

      & > div:first-child {
        background: ${(props) => (props.theme.isDark ? 'linear-gradient(180deg, #141E30 0%, #243B55 100%)' : '#fff')};

        & > div:nth-child(2) {
          background: none;
        }
        * {
          color: #ff8c42;
        }

        svg {
          fill: #ff8c42;
        }

        & > div:first-child {
          & > .ldIlNr,
          & > .ePDKyT {
            background: rgba(255, 140, 66, 0.1);
            box-shadow: inset 4px 0px 0px rgba(255, 140, 66, 1);
          }

          & > div {
            & > a {
              & > svg {
                margin-right: 14px;
              }
            }

            & > div:first-child {
              & > svg {
                margin-right: 14px;
              }
            }

            & > div:last-child {
              & > div {
                background: rgba(255, 140, 66, 0.1);
              }

              & > .jhQKuF,
              & > .bQYCHF {
                box-shadow: inset 4px 0px 0px rgba(255, 140, 66, 1);
              }
            }
          }
        }
      }

      & > div:nth-child(2) {
        & > div:first-child {
          height: auto;
        }
      }

      & > div:last-child {
        background: #ff8c42;
      }

      @media (min-width: 450px) {
        & > div:nth-child(2) {
          & > div:first-child {
            height: auto;
          }
        }
      }

      @media (min-width: 968px) {
        & > .guPHnI {
          width: 50px;
        }
      }
    }
  }

  h2 {
    color: red !important;
  }
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 16px;
  min-height: calc(100vh - 152px);
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;

  background-image: url('/static/media/bg.bfd323f2.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

const BAMIUSDTPairAddress = '0x9C5515c979e81B6d09568edE77C177D6cd094527'

export default function App() {
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])
  const { isDark } = useTheme()
  const dispatch = useDispatch()
  const apiKey = `${process.env.REACT_APP_CROWDIN_APIKEY}`
  const BamiUsdtContract = useContract(BAMIUSDTPairAddress, VALUE_LP_ABI, true)

  const credentials: Credentials = {
    token: apiKey,
  }

  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter((language) => {
      return language.code === storedLangCode
    })[0]
  }

  useEffect(() => {
    const storedLangCode = localStorage.getItem('bamiLanguage')
    if (storedLangCode) {
      const storedLang = getStoredLang(storedLangCode)
      setSelectedLanguage(storedLang)
    } else {
      setSelectedLanguage(EN)
    }
  }, [])

  const fetchTranslationsForSelectedLanguage = async () => {
    let translationApiResponse
    if (selectedLanguage.code === 'vi') {
      translationApiResponse = viLang
    } else {
      translationApiResponse = enLang
    }
    if (translationApiResponse.data.length < 1) {
      setTranslations(['error'])
    } else {
      setTranslations(translationApiResponse.data)
    }
    setTranslatedLanguage(selectedLanguage)
  }

  useEffect(() => {
    if (selectedLanguage) {
      fetchTranslationsForSelectedLanguage()
      localStorage.setItem('bamiLanguage', selectedLanguage.code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage])

  const BgImgTop = styled.img`
    position: absolute;
    top: -220px;
    left: -300px;
    // width: 450px;
    opacity: ${(props) => (props.theme.isDark ? '0' : '1')};

    @media (max-width: 1400px) {
      width: 300px;
      top: -130px;
      left: -200px;
    }

    @media (max-width: 570px) {
      width: 150px;
      top: -55px;
      left: -45px;
    }
  `

  const BgImgBottom = styled.img`
    position: fixed;
    bottom: -250px;
    right: -200px;
    // width: 450px;
    opacity: ${(props) => (props.theme.isDark ? '0' : '1')};
    margin: ${() => {
      return '0'
    }};

    @media (max-width: 1400px) {
      width: 300px;
      bottom: -130px;
      right: -120px;
    }

    @media (max-width: 570px) {
      width: 150px;
      bottom: -55px;
      right: -60px;
    }
  `
  useEffect(() => {
    if (isDark) {
      document.querySelector('svg.mobile-icon>image')?.setAttribute('href', '/images/egg/logoDark.png')
    } else {
      document.querySelector('svg.mobile-icon>image')?.setAttribute('href', '/images/egg/logo.png')
    }
  }, [isDark])

  useEffect(() => {
    dispatch(fetchPricesData(BamiUsdtContract))
  }, [dispatch, BamiUsdtContract])

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <BgImgBottom src={bgImg} />
        <AppWrapper>
          <LanguageContext.Provider
            value={{ selectedLanguage, setSelectedLanguage, translatedLanguage, setTranslatedLanguage }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              <Menu>
                <BodyWrapper>
                  <Popups />
                  <Web3ReactManager>
                    <Switch>
                      <Route exact strict path="/" component={Home} />
                      <Route exact strict path="/home-connected" component={Home1} />
                      <Route exact strict path="/liquidity-data" component={LiquidityShow} />
                      <Route exact strict path="/swapnew" component={SwapNew} />
                      <Route exact strict path="/trade/:tokenAddress" component={TradePage} />
                      <Route exact path="/pool-new" component={PoolNew} />
                      <Route exact path="/liquidity" component={Liquidity} />
                      <Route exact path="/liquidity/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                      <Route exact path="/liquidity/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                      <Route exact path="/bridge" component={MaintenancePage} />
                      <Route exact path="/lending" component={LendingPage} />

                      <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                      <Route exact strict path="/find" component={PoolFinder} />
                      <Route exact strict path="/pool" component={Pool} />
                      <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                      <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                      <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                      <Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} />
                      <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                      <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                      <Route exact strict path="/migrate/v1" component={MigrateV1} />
                      <Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} />
                      <Route component={NotFound} />
                    </Switch>
                  </Web3ReactManager>
                  <Marginer />
                </BodyWrapper>
              </Menu>
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
