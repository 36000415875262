import { createReducer } from '@reduxjs/toolkit'
import { Field, resetBridgeState, typeInput, selectCurrency } from './actions'

export interface BridgeState {
  readonly independentField: Field
  readonly [Field.CURRENCY]: {
    readonly currencyId: string | undefined
  }
  readonly typedValue: string
  readonly otherTypedValue: string // for the case when there's no liquidity
}

const initialState: BridgeState = {
  independentField: Field.CURRENCY,
  [Field.CURRENCY]: {
    currencyId: '',
  },
  typedValue: '',
  otherTypedValue: '',
}

export default createReducer<BridgeState>(initialState, (builder) =>
  builder
    .addCase(resetBridgeState, () => initialState)
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
        otherTypedValue: '',
      }
    })
    .addCase(selectCurrency, (state, { payload: { field, currencyId } }) => {
      return {
        ...state,
        [field]: { currencyId },
      }
    })
)
