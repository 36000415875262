/* eslint-disable @typescript-eslint/no-extra-semi */
import { Currency, ETHER, Pair, Token } from '@pancakeswap-libs/sdk'
import axios from 'axios'
import CurrencyLogo from 'components/CurrencyLogo'
import DetailsPopup from 'components/DetailsPopup/details-popup'
import LiquidityShow from 'components/DetailsPopup/liquidityShow'
import { filterTokens } from 'components/SearchModal/filtering'
import { useTokenComparator } from 'components/SearchModal/sorting'
import TranslatedText from 'components/TranslatedText'
import { usePairs } from 'data/Reserves'
import { useActiveWeb3React } from 'hooks'
import { useAllTokens, useToken } from 'hooks/Tokens'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { usePrices } from 'state/price/hooks'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { useCurrencyBalances, useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import styled from 'styled-components'
import { isAddress } from 'utils'
import { formatNumber } from 'utils/format'
import { consoleInit } from 'utils/tools'
import Web3 from 'web3'

import downIcon from '../../assets/svg/down-arrow.svg'
import bamiCoin from '../../assets/images/bamiCoin.svg'
import depositsIcon from '../../assets/images/depositsIcon.svg'
import walletIcon from '../../assets/images/walletIcon.svg'
import GraphSection, { SingleDetail, SingleDetailFull, Wrapper } from './GraphSection'
import LiquidityData from './LiquidityData'

const HomePage1 = styled.div`
  width: 100%;
  min-height: calc(100vh - 64px);
  position: relative;

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 630px) {
    width: calc(100vw - 35px);
  }
`

const HomeContainer = styled.div`
  width: 1200px;
  margin: 20px auto 20px auto;

  .dash-back-btn {
    border: none;
    outline: none;
    background: none;
    font-size: 1.4em;
    color: #4e598c;
    margin-top: 10px;
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    width: 100%;
    margin: 40px auto 20px auto;
  }

  @media (max-width: 800px) {
    margin: 0px auto 20px auto;
  }
`

const TotalsCompsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1250px) {
    overflow-x: scroll;
  }

  @media (max-width: 800px) {
    overflow-x: visible;
  }
`

const TotalsComp = styled.div`
flex: 0 0 28%;
max-width: 28%;
background linear-gradient(180deg, #F7B267 0%, #F79D65 24.7%, #F4845F 51%, #F27059 74.5%, #F25C54 100%);
color: #fff;
text-align: center;
padding: 20px 20px;
border-radius: 10px;

.totals{
    font-size: 1.2em;
    font-weight: 200;
    margin-bottom: 14px;
}

.price{
    font-size: 2.2em;
    font-weight: 500;
    letter-spacing: 2px;
}

@media(max-width: 1200px){
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 30px;
}

// @media(max-width: 1200px){
    display: none;
    background: none;

    &:last-child{
        display: block;
        flex: 0 0 100%;
        padding: 0;
        text-align: left;

        .totals{
            color: #4E598C;
        }

        .price{
            color: #FF8C42;
        }
    // }
}
`

const AccountCompsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 30px;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`

const AccountsComp = styled.div`
  flex: 0 0 100%;
  max-width: 100%;

  .single-details_container {
    display: flex;
    margin-bottom: 32px;
    flex-direction: column;
  }

  h5.account_header {
    font-size: 1.4em;
    font-weight: 400;
    color: #4e598c;
    margin-bottom: 20px;
  }

  // @media (max-width: 965px) {
  //   flex: 0 0 31%;
  //   max-width: 31%;
  // }

  @media (max-width: 1200px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const PoolItem = styled.div`
  width: 33.33%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem;

  @media (max-width: 700px) {
    width: 100%;
  }

  .content {
    border: 1px solid #003a5e;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }

  .token {
    font-weight: 600;
  }
  .apr {
    color: #4e598c;
    text-align: left;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .item-title {
    display: flex;

    img {
      width: 60px;
    }
  }
`

function Home1() {
  const [detailsPop, setDetailsPop] = useState('')
  const [liquidityShow, setLiquidityShow] = useState(false)
  const [total, setTotal] = useState<any>('')
  const [dashboardData, setDashboardData] = useState<any>()
  const [finalLiquid, setFinalLiquidity] = useState<any>(0)
  const [networkToggle, setNetworkToggle] = useState(false)
  const history = useHistory()
  const converter = usePrices()
  const { account, library } = useActiveWeb3React()
  const [farmShow, setFarmShow] = useState(false)

  React.useEffect(() => {
    if (!account) {
      history.push('/')
    }
  }, [history, account])
  const allTokens = useAllTokens()

  const [searchQuery, setSearchQuery] = useState<string>('')

  const fetchDashboardData = useCallback(async () => {
    if (library?.provider) {
      const data = await consoleInit(library?.provider)
      setDashboardData(data)
    }
  }, [library?.provider])

  useEffect(() => {
    fetchDashboardData()
  }, [fetchDashboardData])

  // if they input an address, use it
  const isAddressSearch = isAddress(searchQuery)
  const searchToken = useToken(searchQuery)

  const tokenComparator = useTokenComparator(false)

  const filteredTokens: Token[] = useMemo(() => {
    if (isAddressSearch) return searchToken ? [searchToken] : []
    return filterTokens(Object.values(allTokens), searchQuery)
  }, [isAddressSearch, searchToken, allTokens, searchQuery])

  const filteredSortedTokens: Token[] = useMemo(() => {
    if (searchToken) return [searchToken]
    const sorted = filteredTokens.sort(tokenComparator)
    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0)
    if (symbolMatch.length > 1) return sorted

    return [
      ...(searchToken ? [searchToken] : []),
      // sort any exact symbol matches first
      ...sorted.filter((token) => token.symbol?.toLowerCase() === symbolMatch[0]),
      ...sorted.filter((token) => token.symbol?.toLowerCase() !== symbolMatch[0]),
    ]
  }, [filteredTokens, searchQuery, searchToken, tokenComparator])
  const balances = useCurrencyBalances(account ?? undefined, [Currency.ETHER, ...filteredSortedTokens])
  const trackedTokenPairs = useTrackedTokenPairs()

  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens,
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )
  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  React.useEffect(() => {
    axios
      .get('https://api.coinbase.com/v2/exchange-rates?currency=BNB')
      .then((res) => {
        if (account) {
          const web3 = new Web3(window.web3.currentProvider)
          web3.eth
            .getAccounts((err, result) => {
              if (result.length) {
                web3.eth
                  .getBalance(result[0])
                  .then((p) => {
                    let price: any = web3.utils.fromWei(p.toString(), 'ether')
                    price = parseFloat(price) * parseFloat(res.data.data.rates.USD)

                    setTotal(price)
                  })
                  .catch((err1) => {
                    console.error(err1)
                  })
              }
            })
            .catch((err2) => {
              console.error(err2)
            })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [account, finalLiquid])

  React.useEffect(() => {
    let finalBalance = 0
    if (converter) {
      balances
        .filter((v: any) => parseFloat(v?.toExact()))
        .map((v: any, i) => {
          const exact: any = v && v.toExact() ? parseFloat(v.toExact()) : 0
          const symbol = v?.currency.symbol ? v?.currency.symbol : 'USD'
          const price: any =
            parseFloat(exact) *
            (Number.isNaN(parseFloat(converter[symbol]?.toString())) ? 0 : parseFloat(converter[symbol]?.toString()))
          finalBalance += parseFloat(price)
          return parseFloat(price).toFixed(4)
        })
      setTotal(finalBalance)
    }
  }, [balances, converter])

  useEffect(() => {
    if (
      window.innerWidth < 600 &&
      detailsPop &&
      (document.querySelector('#root>div:last-child>div:first-child>div') as HTMLDivElement)?.style
    ) {
      ;(document.querySelector('#root>div:last-child>div:first-child>div') as HTMLDivElement).style.zIndex = '30'
    } else if ((document.querySelector('#root>div:last-child>div:first-child>div') as HTMLDivElement)?.style) {
      ;(document.querySelector('#root>div:last-child>div:first-child>div') as HTMLDivElement).style.zIndex = ''
    }
    if (detailsPop) {
      ;(document.querySelector('body #root>div:last-child') as HTMLDivElement).style.overflowX = 'visible'
    }
  }, [detailsPop])

  return (
    <HomePage1>
      {detailsPop ? (
        <DetailsPopup
          detailsPop={detailsPop}
          setDetailsPop={setDetailsPop}
          balances={balances}
          converter={converter}
          total={total}
          ETHER={ETHER}
        />
      ) : null}
      {liquidityShow ? (
        <LiquidityShow
          setLiquidityShow={setLiquidityShow}
          balances={balances}
          converter={converter}
          total={total}
          ETHER={ETHER}
        />
      ) : (
        <HomeContainer>
          <TotalsCompsContainer>
            <TotalsComp>
              <h5 className="totals">
                <TranslatedText translationId={662}>Net Worth</TranslatedText>
              </h5>
              <h5 className="price">${formatNumber(total + finalLiquid)}</h5>
            </TotalsComp>
          </TotalsCompsContainer>
          {networkToggle ? (
            <button
              type="button"
              className="dash-back-btn"
              onClick={() => {
                setNetworkToggle(false)
              }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true" />
            </button>
          ) : null}

          <AccountCompsContainer>
            <AccountsComp>
              <h5 className="account_header">
                <TranslatedText translationId={660}>Account Overview</TranslatedText>
              </h5>
              <GraphSection wallet={total} liquidity={finalLiquid} balances={balances} converter={converter} />
              <Wrapper>
                <SingleDetail
                  onClick={() => {
                    setDetailsPop('Wallet')
                  }}
                >
                  <img src={walletIcon} alt="" />
                  <h5>
                    <TranslatedText translationId={666}>Wallet</TranslatedText>
                  </h5>
                  <h5 className="price">${formatNumber(total)}</h5>
                </SingleDetail>
                <SingleDetail
                  onClick={() => {
                    setDetailsPop('Liquidity Pools')
                  }}
                >
                  <img src={depositsIcon} alt="" />
                  <h5>
                    <TranslatedText translationId={668}>Liquidity Pools</TranslatedText>
                  </h5>
                  <h5 className="price">
                    $
                    {allV2PairsWithLiquidity && allV2PairsWithLiquidity.length && Object.keys(converter).length ? (
                      <LiquidityData
                        setData={setFinalLiquidity}
                        account={account}
                        allV2PairsWithLiquidity={allV2PairsWithLiquidity}
                        converter={converter}
                      />
                    ) : (
                      0
                    )}
                  </h5>
                </SingleDetail>
              </Wrapper>
            </AccountsComp>
            {!networkToggle ? (
              <AccountsComp>
                <h5 className="account_header">
                  <TranslatedText translationId={672}>Networks</TranslatedText>
                </h5>
                <div className="single-details_container">
                  <SingleDetail>
                    <CurrencyLogo currency={ETHER} size="24px" style={{ marginRight: '8px' }} />
                    <h5>BSC</h5>
                    <h5 className="price">${formatNumber(total + finalLiquid)}</h5>
                  </SingleDetail>
                </div>
              </AccountsComp>
            ) : null}
            <AccountsComp>
              <h5 className="account_header">
                <TranslatedText translationId={670}>Platforms</TranslatedText>
              </h5>
              <div className="single-details_container">
                <SingleDetailFull
                  onClick={() => {
                    setFarmShow(!farmShow)
                  }}
                >
                  <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <img src={bamiCoin} alt="" />
                    <h5>Bami</h5>
                    <h5 className="price">${formatNumber(dashboardData?.totalUserStaked, 2)}</h5>
                    <img
                      style={{ width: 16, marginLeft: 8, transform: farmShow ? 'rotate(180deg)' : 'rotate(0)' }}
                      src={downIcon}
                      alt="down-arrow"
                    />
                  </div>
                  {!!farmShow && (
                    <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                      {dashboardData?.pools?.map((o) => {
                        const farmImage = `${o.stakeTokenTicker}.svg`
                        return (
                          <PoolItem key={o.stakeTokenTicker} onClick={(e) => e?.stopPropagation()}>
                            <div className="content">
                              <div className="item-title">
                                <img src={`/images/farms/${farmImage}`} alt={o.stakeTokenTicker} />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <span className="token">{o.stakeTokenTicker}</span>
                                  <span className="apr">{formatNumber(o.yearlyAPR, 2)}%</span>
                                </div>
                              </div>
                              <div className="row">
                                <span>Your staked value:</span>
                                <span>${formatNumber(o.userStakedUsd, 2)}</span>
                              </div>
                              <div className="row">
                                <span>{`${o.rewardTokenTicker} earn per year:`}</span>
                                <span style={{ color: '#4e598c' }}>{formatNumber(o.userYearlyRewards, 2)}</span>
                              </div>
                            </div>
                          </PoolItem>
                        )
                      })}
                    </div>
                  )}
                </SingleDetailFull>
              </div>
            </AccountsComp>
          </AccountCompsContainer>
        </HomeContainer>
      )}
    </HomePage1>
  )
}

export default Home1
