import { ModalProvider } from '@bami-libs/uikit'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import React from 'react'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import axios from 'axios'
import { NetworkContextName, EthereumContextName } from './constants'
import store from './state'
import { ThemeContextProvider } from './ThemeContext'
import getLibrary from './utils/getLibrary'

const defaultQueryFn = async ({ queryKey }) => {
  const { data } = await axios.get(queryKey[0])

  return data
}

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)
const Web3EthereumNetwork = createWeb3ReactRoot(EthereumContextName)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false,
    },
  },
})

const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Web3EthereumNetwork getLibrary={getLibrary}>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <ThemeContextProvider>
                <ModalProvider>{children}</ModalProvider>
              </ThemeContextProvider>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Web3EthereumNetwork>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  )
}

export default Providers
